<template>
    <div v-if="watermarkName"
         class="watermark-map">
        <img :src="watermarkName"
             class="watermark-image"
             height="1.3em"
             width="80px"
             data-test="map-watermark-image"
             alt="map-watermark">
    </div>
</template>

<script setup lang="ts">
    import { computed } from "vue"
    import { useStore } from "vuex"

    import { mapBackdrops } from "@/consts/map"

    const store = useStore()
    const props = defineProps<{
        baseLayer?: string
    }>()
    const currentBaseLayer = computed(() => store.state.map.currentBaseLayer)
    const watermarkName = computed((): string | undefined => {
        const baseLayerValue = props.baseLayer || currentBaseLayer.value?.id
        switch(baseLayerValue) {
            case(mapBackdrops.osMap):
            case(mapBackdrops.osMapLight):
                return new URL("../../media/os-logo-maps.webp", import.meta.url).href
            default:
                return undefined
        }
    })
</script>

<style scoped lang="scss">
    @import './map-watermark.scss';
</style>
