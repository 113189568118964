<template>
    <i18n-t :keypath="model.descriptionPath"
            scope="global"
            class="base-row-item-description body-regular-small align-center gc-1"
            tag="span"
            @click.stop>
        <template v-if="model?.titleNumber"
                  #titleNumber>
            <ow-title-number-tooltip small
                                     class="body-regular-small"
                                     :title-number="model.titleNumber"
                                     @click="emit('title-number-click')" />
        </template>
        <template v-if="address"
                  #address>
            <span v-dompurify-html="address"
                  :title="address"
                  class="main-content__info--address" />
        </template>
        <template v-if="!model?.titleNumber"
                  #companyNumbers>
            <a class="main-content__info--company-number body-regular-small font-weight-bold"
               :href="getCompaniesHouseLinkFromCompanyRegistrationNumber(model?.companyNumbers?.[0])"
               target="_blank">{{ model?.companyNumbers?.[0] }}
                <ow-tooltip :position="OwTooltipPosition.Top"
                            activator="parent">
                    <span class="main-content__info--company-number">{{ $t('assetMonitoring.text.companyNumber') }}</span>
                </ow-tooltip></a>
        </template>
        <template v-if="model.boundaryDifference"
                  #size>
            <span v-dompurify-html="`${model.boundaryDifference.toFixed(1)} m<sup>2</sup>`" />
        </template>
        <template v-if="model.boundaryDifference"
                  #isLarger>
            {{
                model.isBoundaryLarger
                    ? t('assetMonitoring.subType.boundaryUpdated.larger')
                    : t('assetMonitoring.subType.boundaryUpdated.smaller')
            }}
        </template>
    </i18n-t>
</template>
<script setup lang="ts">

    import {
        nextTick,
        onMounted,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import MatterApi from '@/api/matter.api'
    import { BaseRowItemModel } from '@/components/asset-monitoring/base-row-item-model'
    import OwTitleNumberTooltip from '@/components/core/ow-title-number-tooltip.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { useAssetMonitoringStore } from '@/stores/asset-monitoring'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { getCompaniesHouseLinkFromCompanyRegistrationNumber } from '@/utils/link-utils'

    const props = defineProps<{
        model: BaseRowItemModel,
    }>()

    const emit = defineEmits<{
        (e: 'title-number-click'): void
    }>()

    const address = ref<string | null>(null)

    const { t } = useI18n()
    const store = useAssetMonitoringStore()

    onMounted(() => {
        nextTick(async () => {
            if (!props.model.titleNumber) {
                return
            }
            // get address from current matter selected titles
            address.value = store.titleNumberAddressMap[props.model.titleNumber] ?? ''
        })
    })
</script>

<style lang="scss"
       scoped>
@import './base-row-item-description';
</style>