<template>
    <ow-page-layout :header-title="$t('homepage.header')"
                    header-icon="$home"
                    page-name="homepage">
        <h2 v-dompurify-html="isFirstVisit ?
                $t('homepage.welcomeTitle', { name: userFirstName }) :
                $t('homepage.welcomeBackTitle', { name: userFirstName })"
            class="homepage__content--title" />
        <section class="homepage__action-cards">
            <h6 v-t="'homepage.subtitle'"
                class="homepage__section-subtitle" />
            <div class="homepage__action-cards--items">
                <ow-action-card :subtitle="$t('homepage.cards.titleLookup.subtitle')"
                                :svg-icon="Icons.TITLE_LOOKUP"
                                :title="$t('homepage.cards.titleLookup.title')">
                    <title-search-box data-test="homepage-title-search-box" />
                </ow-action-card>
                <ow-action-card :subtitle="$t('homepage.cards.getStarted.subtitle')"
                                :title="$t('homepage.cards.getStarted.title')"
                                icon="$add-matter">
                    <router-link :to="{ name: AppRoute.MattersCreate, hash: '#details' }"
                                 data-track="HOMEPAGE - Go to matters create">
                        <ow-button v-t="'homepage.actions.createNewMatter'"
                                   data-test="homepage-create-new-matter-button"
                                   is-primary />
                    </router-link>
                </ow-action-card>
                <ow-action-card :subtitle="hasCopilotRole ? $t('homepage.cards.copilotCtaCopilotUser.subtitle') : $t('homepage.cards.copilotCtaWitnessUser.subtitle')"
                                :title="$t('homepage.cards.copilotCtaCopilotUser.title')"
                                :banner-source="copilotBannerSrc"
                                banner>
                    <a v-if="hasCopilotRole"
                       :href="copilotUrl"
                       data-test="homepage-go-to-copilot-button"
                       target="_blank">
                        <ow-button v-t="'homepage.actions.goToCopilot'"
                                   is-secondary
                                   @click="openCopilot" />
                    </a>
                    <ow-button v-else
                               v-t="'homepage.actions.learnMore'"
                               data-test="homepage-copilot-learn-more-button"
                               is-secondary
                               @click="showCopilotVideo = true" />
                </ow-action-card>
            </div>
        </section>
        <div class="homepage__recent-matters-header">
            <h6 v-t="'homepage.recentMatters'" />
            <router-link :to="{ name: AppRoute.MattersList }"
                         data-track="HOMEPAGE - View all matters">
                <ow-button v-t="'homepage.actions.viewAll'"
                           data-test="homepage-view-all-matters-button"
                           is-secondary />
            </router-link>
        </div>
        <template v-if="delayedLoading">
            <recent-matters-list-loading-skeleton />
        </template>
        <template v-else-if="!delayedLoading && recentMatters.length > 0">
            <matters-list-item v-for="matter in recentMatters"
                               :key="matter.id"
                               :matter="matter"
                               data-track="HOMEPAGE - recent matter click" />
        </template>
        <template v-else>
            <p v-t="'matter.noRecentMatters'" />
        </template>
        <ow-video-modal v-if="showCopilotVideo"
                        :show="showCopilotVideo"
                        @cancel="showCopilotVideo = false"
                        @submit="onSupportBtnClick" />
    </ow-page-layout>
</template>

<script lang="ts">
    import {
        mapActions,
        mapState,
        useStore,
    } from 'vuex'

    import UserApi from '@/api/user.api'
    import {useCopilot} from "@/components/copilot/use-copilot"
    import OwPageLayout from '@/components/core/layout/page.vue'
    import OwActionCard from '@/components/core/ow-action-card.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwVideoModal from "@/components/core/ow-video-modal.vue"
    import RecentMattersListLoadingSkeleton
        from '@/components/loading-skeletons/recent-matters-list-loading-skeleton.vue'
    import MattersListItem from '@/components/matters-list/matters-list-item.vue'
    import TitleSearchBox from '@/components/search/title-search-box.vue'
    import { Icons } from '@/consts/icons'
    import {ApplicationRoles} from "@/enums/application-roles.enum"
    import { Route as AppRoute } from '@/enums/route.enum'
    import FeatureFlagsMixin from "@/feature-flags/feature-flags-mixin"
    import { IState } from '@/interfaces/store/state.interface'
    import CopilotIntegration from '@/media/copilot-homepage-banner.webp'
    import { MATTER_CLEAR_CURRENT_MATTER } from '@/store/modules/matter/types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'Homepage',

        components: {
            OwVideoModal,
            OwActionCard,
            OwButton,
            OwPageLayout,
            TitleSearchBox,
            MattersListItem,
            RecentMattersListLoadingSkeleton,
        },

        mixins: [
            FeatureFlagsMixin,
        ],

        setup() {
            const { copilotUrl } = useCopilot({
                clientCode: '',
                matterCode: '',
                matterId: 0,
            })

            return {
                copilotUrl,
            }
        },

        data() {
            return {
                Icons,
                recentMatters: [],
                AppRoute,
                delayedLoading: true,
                showCopilotVideo: false,
            }
        },

        computed: {
            ...mapState({
                userFirstName: (state: IState) => state.user.firstName,
                userLastSeen: (state: IState) => state.user.lastSeen,
                loadingList: (state: IState) => state.matter.loadingList,
                hasCopilotRole: (state: IState) => state.user.roles.includes(ApplicationRoles.Copilot),
            }),

            isFirstVisit(): boolean {
                return isNullOrWhitespace(this.userLastSeen)
            },

            copilotBannerSrc() {
                return CopilotIntegration
            },
        },

        watch: {
            $route(to: any) {
                if (to.name === AppRoute.Homepage) {
                    this.refreshRecentMatters()
                }
            },

            loadingList: {
                async handler(val) {
                    if (!val) {
                        await this.refreshRecentMatters()
                    }

                    this.delayedLoading = val
                },
                immediate: true,
            },
        },

        methods: {
            ...mapActions({
                clearCurrentMatter: MATTER_CLEAR_CURRENT_MATTER,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            async refreshRecentMatters() {
                await this.clearCurrentMatter()

                const response = await UserApi.getRecentlyAccessedMatters()
                if (response?.data?.length > 0) {
                    this.recentMatters = response.data
                }
            },

            async openCopilot() {
                await this.logHeapEvent({
                    type: 'Copilot CTA: Go to Copilot',
                    metadata: {
                        position: 'Homepage Action Card',
                    },
                })
            },

            async onSupportBtnClick() {
                await this.logHeapEvent({
                    type: 'HOMEPAGE - Open support for Copilot adoption',
                    metadata: {
                        position: 'Homepage Action Card',
                    },
                })
                if (window.Intercom) {
                    window.Intercom('showNewMessage')
                }
            },
        },
    }
</script>

<style lang="scss">
    @import './homepage';
</style>
