<template>
    <ow-page-layout :header-title="$t('mattersList.header')"
                    header-icon="$matters"
                    page-name="matters-list">
        <v-container class="matters-dashboard__content">
            <!-- Tabs -->
            <v-tabs v-model="activeTab"
                    align-tabs="center"
                    class="matters-dashboard__tabs"
                    slider-color="primary"
                    tag="nav"
                    :disabled="isMatterLoading"
                    color="black">
                <v-tab data-test="matters-list-filter-tab-all"
                       data-track="MAT-DASH - All Matters tab clicked">
                    All matters
                </v-tab>

                <v-tab data-test="matters-list-filter-tab-created"
                       data-track="MAT-DASH - Created by you tab clicked">
                    Created by you
                </v-tab>

                <v-tab data-test="matters-list-filter-tab-shared"
                       data-track="MAT-DASH - Shared with you tab clicked">
                    Shared with you
                </v-tab>

                <v-tab data-test="matters-list-filter-tab-client"
                       data-track="MAT-DASH - Shared with clients tab clicked">
                    Shared with clients
                </v-tab>
            </v-tabs>

            <!-- Filter and options -->
            <div v-if="!showNoMattersPrompt"
                 class="matters-list__content--options">
                <div class="matters-list__filters">
                    <!-- Search -->
                    <v-text-field v-model="searchText"
                                  class="body-copy matters-dashboard__content--options search"
                                  :clearable="true"
                                  variant="underlined"
                                  data-test="matters-list-search"
                                  data-track="MAT-DASH - Search for matter"
                                  hide-details
                                  :label="$t('matter.search')"
                                  :disabled="isMatterLoading"
                                  prepend-inner-icon="$search" />

                    <!-- Sort -->
                    <v-select v-model="selectedSortOption"
                              :items="sortOptions"
                              variant="underlined"
                              class="body-copy matters-dashboard__content--options sort"
                              data-test="matters-list-sort"
                              hide-details
                              item-title="label"
                              label="Sorting by"
                              :disabled="isMatterLoading"
                              return-object />

                    <v-select v-model="selectedActivityState"
                              :items="activityStates"
                              variant="underlined"
                              :disabled="isMatterLoading"
                              class="body-copy matters-dashboard__content--options activity-state"
                              data-test="matters-list-activity-state"
                              hide-details
                              item-title="label"
                              label="Filter by"
                              return-object />
                </div>

                <div class="matters-list__actions">
                    <ow-button data-track="MAT-DASH - Refresh list of matters"
                               large
                               is-secondary
                               is-borderless
                               :disabled="isMatterLoading"
                               @click="refresh">
                        Refresh
                    </ow-button>

                    <ow-button is-primary
                               data-test="matters-list-create-matter"
                               data-track="MAT-DASH - Create New Matter"
                               :disabled="isMatterLoading"
                               @click="onCreateNewMatterClick">
                        Create New Matter
                    </ow-button>
                </div>
            </div>

            <matter-list-container-with-pagination v-if="!showNoMattersPrompt"
                                                   :is-loading="isLoadingMatterPage"
                                                   :is-matter-loading="isMatterLoading"
                                                   :items="items"
                                                   :more-data-available="moreDataAvailable"
                                                   :is-shared-tab-active="isSharedTabActive"
                                                   @load-data="loadData"
                                                   @select-matter="selectMatter" />

            <!-- No matters in section, create prompt -->
            <div v-if="showNoMattersPrompt"
                 class="d-flex flex flex-column align-center matters-dashboard__content--none mt-12"
                 data-test="matters-list-no-matters">
                <h2 class="content__heading mb-1">
                    You haven’t created any matters yet.
                </h2>
                <p class="body-copy mb-3">
                    When you do, they will appear here.
                </p>
                <v-btn color="primary"
                       data-test="matters-list-new-create-matter"
                       data-track="MAT-DASH - Create new matter from empty list"
                       size="large"
                       @click="onCreateNewMatterClick">
                    Create new matter
                </v-btn>
            </div>
        </v-container>
    </ow-page-layout>
</template>

<script lang="ts">
    import debounce from 'lodash.debounce'
    import {
        mapActions,
        mapMutations,
    } from 'vuex'

    import MatterApi, { IMatterListRequest } from '@/api/matter.api'
    import OwPageLayout from '@/components/core/layout/page.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import MatterListContainerWithPagination from '@/components/matters-list/matter-list-container-with-pagination.vue'
    import { MatterState } from '@/consts/matter.js'
    import { TITLE_LIMIT } from '@/consts/title-limits'
    import { Route } from '@/enums/route.enum'
    import { IMatterListItem } from '@/interfaces/matter-list-item.interface'
    import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '@/store/modules/link-share-client/types'
    import { IMatterState } from '@/store/modules/matter'
    import {
        MATTER_CLEAR_CURRENT_MATTER,
        MATTER_FETCH_ADDRESSES_BY_MATTER_ID,
        MATTER_MUTATE_MATTERS,
        MATTER_UPDATE_MATTER_READ_STATUS,
    } from '@/store/modules/matter/types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { appendMatterHintText } from '@/utils/matter-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const TabsIndex = Object.freeze({
        All: 0,
        CreatedByMe: 1,
        SharedWithYou: 2,
        SharedWithClient: 3,
    })
    const TabsHash = ['', 'mine', 'shared', 'client']

    const SortFields = Object.freeze({
        lastAccessed: 'LastAccessed',
        name: 'MatterName',
        code: 'MatterCode',
        clientCode: 'ClientCode',
        type: 'MatterType',
        createdByUserName: 'OwnerName',
        titleCount: 'NumberOfTitles',
    })

    export default {
        name: 'MattersList',

        components: {
            MatterListContainerWithPagination,
            OwPageLayout,
            OwButton,
        },

        data() {
            return {
                searchText: '',
                sortOptions: [
                    {
                        label: 'Last accessed',
                        prop: SortFields.lastAccessed,
                        sortFunction: (a, b) => new Date(b.lastAccessed).valueOf() - new Date(a.lastAccessed).valueOf(),
                    },
                    {
                        label: 'Matter name',
                        prop: SortFields.name,
                        type: String,
                    },
                    {
                        label: 'Matter code',
                        prop: SortFields.code,
                        type: String,
                    },
                    {
                        label: 'Client code',
                        prop: SortFields.clientCode,
                        type: String,
                    },
                    {
                        label: 'Matter type',
                        prop: SortFields.type,
                        type: String,
                    },
                    {
                        label: 'Owner',
                        prop: SortFields.createdByUserName,
                        type: String,
                    },
                    {
                        label: 'Number of titles',
                        prop: SortFields.titleCount,
                        type: Number,
                    },
                ],
                activityStates: [
                    {
                        label: 'All',
                        prop: 'all',
                        type: String,
                    },
                    {
                        label: 'Open',
                        prop: MatterState.Open,
                        type: Number,
                    },
                    {
                        label: 'Closed',
                        prop: MatterState.Closed,
                        type: Number,
                    },
                ],
                selectedSortOption: null,
                selectedActivityState: null,
                pageSize: 100,
                currentPageIndex: 0,
                moreDataAvailable: true,
                items: [],
                debouncedSearch: debounce(async () => {
                    await this.refresh()
                }, 750),
                isLoadingMatterPage: true,
                hasLoadedSomeData: false,
                isMatterLoading: false,
            }
        },

        computed: {
            matterLoading(): boolean {
                return this.$store.state.matter.loading
            },
            matterLoadingState(): boolean {
                return this.$store.state.matter.loadingState?.loadingCurrentMatter ?? false
            },
            currentMatterId(): string {
                return this.$store.state.matter.currentMatter?.id ?? ''
            },
            showNoMattersPrompt(): boolean {
                return isNullOrEmpty(this.items) &&
                    isNullOrWhitespace(this.searchText) &&
                    (this.activeTab === TabsIndex.CreatedByMe || this.activeTab === TabsIndex.All) &&
                    !this.isLoadingMatterPage &&
                    this.hasLoadedSomeData
            },

            isSharedTabActive(): boolean {
                return Boolean(this.routeHashValue === TabsHash[TabsIndex.SharedWithYou])
            },

            routeHashValue(): string {
                const routeHashValue = this.$route.hash
                return !isNullOrWhitespace(routeHashValue) ? routeHashValue.replace('#', '') : ''
            },

            activeTab: {
                set(tabIndex: number): void {
                    if (tabIndex !== this.activeTab &&
                        this.$route.name === Route.MattersList) {
                        let selectedHashValue = TabsHash[tabIndex]
                        if (!isNullOrWhitespace(selectedHashValue)) {
                            selectedHashValue = `#${ TabsHash[tabIndex] }`
                        }
                        this.$router.replace({ hash: selectedHashValue })
                    }
                },
                get(): number {
                    return TabsHash.indexOf(this.routeHashValue)
                },
            },
        },

        watch: {
            matterLoading(newVal): void {
                if (newVal) {
                    this.isMatterLoading = newVal
                }
            },
            matterLoadingState(newVal): void {
                if (newVal) {
                    this.isMatterLoading = newVal
                }
            },
            currentMatterId(newVal): void {
                if (!newVal) {
                    this.isMatterLoading = false
                    return
                }
            },
            async selectedSortOption(newVal): Promise<void> {
                // We used to remap SortFields twice
                // E.g. 'owner' -> 'createdByUserName' -> 'OwnerName' where:
                //      'owner' is not used anywhere
                //      'createdByUserName' is sent to HEAP
                //      'OwnerName' goes into IMatterListRequest
                // It's been removed, and the line below ensures HEAP continues to receive the same old value
                const legacyProp = Object.keys(SortFields).find(key => SortFields[key] === newVal.prop)

                await this.logHeapEvent({
                    type: 'MAT-DASH - Matters sorted by',
                    metadata: {
                        sortOption: {
                            ...newVal,
                            prop: legacyProp,
                        },
                    },
                })
                await this.refresh()
            },

            async selectedActivityState(newVal): Promise<void> {
                await this.logHeapEvent({
                    type: 'MAT-DASH - Matters filtered by',
                    metadata: {
                        filterOption: newVal,
                    },
                })
                await this.refresh()
            },

            async searchText(): Promise<void> {
                this.isLoadingMatterPage = true
                await this.debouncedSearch()
            },

            async $route(route): Promise<void> {
                if (route.name === Route.MattersList) {
                    await this.refresh()
                }
            },
        },

        activated() {
            this.isMatterLoading = false
        },

        async created(): Promise<void> {
            this.selectedSortOption = this.sortOptions[0]
            this.selectedActivityState = this.activityStates[1]
            if (this.items.length === 0) {
                await this.refresh()
            }
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
                updateMatterReadStatus: MATTER_UPDATE_MATTER_READ_STATUS,
                clearCurrentMatter: MATTER_CLEAR_CURRENT_MATTER,
            }),

            ...mapMutations({
                updateMatters: MATTER_MUTATE_MATTERS,
            }),

            async selectMatter(matter: IMatterListItem): Promise<void> {
                if (!matter.isMatterRead && this.isSharedTabActive) {
                    await this.updateMatterReadStatus({
                        matterId: matter.id,
                        isMatterRead: true,
                    })
                }
            },

            onCreateNewMatterClick(): void {
                this.$router.push({
                    name: 'matters-create',
                    hash: '#details',
                })
            },

            async reloadMatterList(): Promise<void> {
                await this.clearCurrentMatter()
                this.isLoadingMatterPage = true
            },

            async loadData(reloadingMatterList: boolean): Promise<void> {
                if (reloadingMatterList) {
                    await this.reloadMatterList()
                }

                const sortAscending = this.getIsAscendingOrderForSortField(this.selectedSortOption.prop)

                const options: IMatterListRequest = {
                    pageSize: this.pageSize,
                    pageIndex: this.currentPageIndex,
                    searchText: this.searchText,
                    sortField: this.selectedSortOption.prop,
                    sortAscending,
                    onlyOpenMatters: this.selectedActivityState.prop === MatterState.Open,
                    onlyClosedMatters: this.selectedActivityState.prop === MatterState.Closed,
                    onlyMattersOwnedByUser: this.activeTab === TabsIndex.CreatedByMe,
                    onlyMattersSharedWithUser: this.activeTab === TabsIndex.SharedWithYou,
                    onlyMattersSharedExternally: this.activeTab === TabsIndex.SharedWithClient,
                }

                try {
                    const response = await MatterApi.getMatterListWithPagination(options)
                    this.currentPageIndex++

                    if (response?.data) {
                        // Append additional properties to the data for display purposes.
                        const newItems = response.data
                        newItems.forEach(x => appendMatterHintText(x))
                        this.items.push(...newItems)
                        if (newItems.length !== this.pageSize) {
                            // Rough way of finding if there is more data on the server.
                            // Might end up with an extra request that returns nothing before stopping
                            this.moreDataAvailable = false
                        }
                        this.hasLoadedSomeData = true
                        this.isLoadingMatterPage = false
                    }

                    this.updateMatters(this.items)
                } catch (err: any) {
                    throw new Error(err)
                }
            },

            async refresh(): Promise<void> {
                this.items = []
                this.currentPageIndex = 0
                this.moreDataAvailable = true
                await this.loadData(true)
            },

            getIsAscendingOrderForSortField(sortField: string): boolean {
                switch (sortField) {
                    case SortFields.lastAccessed:
                        return false
                    default:
                        return true
                }
            },
        },
    }
</script>
<style lang="scss">
    @import './matters-list';
</style>
