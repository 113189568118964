<template>
    <v-container id="organisationsPage">
        <div class="admin-organisations__table-header">
            <div class="admin-organisations__search">
                <ow-textfield v-model="searchValue"
                              :placeholder="$t('admin.organisation.label.searchOrganisations')"
                              clearable
                              data-test="admin-organisation-search"
                              class="admin-organisations__search-field">
                    <template #iconPrefix>
                        <v-icon>$search</v-icon>
                    </template>
                </ow-textfield>
            </div>
            <div class="admin-organisations__action-buttons">
                <ow-button-ds :loading="loading"
                              is-primary
                              data-test="admin-organisations-admin-add-organisation"
                              size="large"
                              @click="openOrganisationFormDialog(false)">
                    {{ $t('admin.organisation.add') }}
                    <template #iconPrefix>
                        <v-icon>$organisation</v-icon>
                    </template>
                </ow-button-ds>
                <ow-button-ds :loading="loading"
                              is-primary
                              size="large"
                              @click="getOrganisations()">
                    {{ $t('action.refresh') }}
                    <template #iconPrefix>
                        <v-icon>$refresh</v-icon>
                    </template>
                </ow-button-ds>
            </div>
        </div>
        <ow-data-grid :headers="headers"
                      :fixed-header="true"
                      :item-key="'id'"
                      :items="filteredOrganisations"
                      :items-per-page="10"
                      :allow-selection="false"
                      data-test="admin-organisations-list-table"
                      class="admin-table"
                      hide-default-footer
                      disable-pagination
                      hover
                      :sort-by="sortBy">
            <template #[`item.sections`]="{ item }">
                <span style="padding-right: 10px;">{{ item.sections.length }}</span>

                <a v-if="item.sections.length > 0"
                   style="padding-right: 5px;"
                   :data-test="`admin-organisations-view-section-${ item.name }`"
                   @click="openOrganisationSectionsListDialog(item)">View</a>
                <span v-if="item.sections.length > 0"
                      style="padding-right: 5px;">/</span>
                <a :data-test="`admin-organisations-add-section-${ item.name }`"
                   @click="onAddOrganisationSectionClick(item)">Add</a>
            </template>
            <template #[`item.users`]="{ item }">
                {{ getUserCounts(item) }}
            </template>
            <template #[`item.pricingModelId`]="{ item }">
                <td :data-test="`admin-organisations-pricing-model-${ item.name }`">
                    {{ pricingPlanNames[item.pricingModelId] }}
                </td>
            </template>
            <template #[`item.ppcStandardCharge`]="{ item }">
                {{ item.ppcStandardCharge !== null ? `£${item.ppcStandardCharge}` : '' }}
            </template>
            <template #[`item.edit`]="{ item }">
                <v-btn icon
                       :data-test="`admin-organisations-edit-org-${ item.name }`"
                       variant="text"
                       @click="openEditOrganisationDialog(item)">
                    <v-icon>$edit</v-icon>
                </v-btn>
            </template>
        </ow-data-grid>

        <ow-modal v-model="isOrganisationFormDialogOpened"
                  :title="isEditOrganisationMode ? $t('admin.organisation.edit') : $t('admin.organisation.add')"
                  :persistent="true"
                  content-class="organisation-sections-modal__organisation-form">
            <template v-if="isEditOrganisationMode"
                      #subtitleSlot>
                <seat-overview :active-witness-users="seats.activeWitnessUsers"
                               :max-witness-users="seats.maxWitnessUsers"
                               :active-copilot-users="seats.activeCopilotUsers"
                               :max-copilot-users="seats.maxCopilotUsers" />
            </template>
            <organisation-form :is-edit="isEditOrganisationMode"
                               @on-cancel-click="closeOrganisationFormDialog"
                               @after-submit="closeOrganisationFormDialog" />
        </ow-modal>

        <ow-modal v-model="isOrganisationSectionsDialogOpened"
                  :title="$t('admin.organisation.sections')"
                  :subtitle="selectedOrganisation.organisationName">
            <organisation-sections-list :sections="selectedOrganisation.sections"
                                        @on-close-click="closeOrganisationSectionsListDialog"
                                        @on-edit-click="editOrganisationSection" />
            <template #actions>
                <v-btn class="organisation-sections-list__button-close"
                       color="primary"
                       variant="text"
                       data-test="organisation-section-list-button-close"
                       @click="closeOrganisationSectionsListDialog">
                    {{ $t('action.close') }}
                </v-btn>
            </template>
        </ow-modal>

        <ow-modal v-model="isOrganisationSectionFormDialogOpened"
                  :title="isEditOrganisationSectionMode ? $t('admin.organisation.editSection') : $t('admin.organisation.addSection')"
                  :subtitle="selectedOrganisationSection.organisationName">
            <organisation-section-form :is-edit="isEditOrganisationSectionMode"
                                       @on-cancel-click="closeOrganisationSectionFormDialog"
                                       @after-submit="closeOrganisationSectionFormDialog" />
        </ow-modal>
    </v-container>
</template>
<script>
    import { ref } from 'vue'
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import OrganisationForm from '@/components/admin/organisations/organisation-form.vue'
    import OrganisationSectionForm from '@/components/admin/organisations/organisation-section-form.vue'
    import OrganisationSectionsList from '@/components/admin/organisations/organisation-sections-list.vue'
    import SeatOverview from '@/components/admin/seats/seat-overview.vue'
    import OwButtonDs from "@/components/core/ow-button-ds.vue"
    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import OwModal from "@/components/core/ow-modal.vue"
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import { pricingPlanNames } from '@/consts/pricing-plans.js'
    import {
        GET_LIST,
        LOAD_LIST,
        MUTATE_INITIALISE_SELECTED_ORGANISATION,
        MUTATE_SELECTED_ORGANISATION,
        MUTATE_SELECTED_ORGANISATION_SECTION,
    } from '@/store/modules/organisation/types'

    export default {
        name: 'AdminOrganisations',

        components: {
            OwButtonDs,
            OwModal,
            OrganisationForm,
            OrganisationSectionForm,
            OrganisationSectionsList,
            OwDataGrid,
            SeatOverview,
            OwTextfield,
        },

        data() {
            return {
                loading: false,
                isOrganisationFormDialogOpened: false,
                isOrganisationSectionFormDialogOpened: false,
                isEditOrganisationMode: false,
                isEditOrganisationSectionMode: false,
                isOrganisationSectionsDialogOpened: false,
                pricingPlanNames,
                seats: {},
                sortBy: ref([{key: 'name', order: 'asc'}]),
                searchValue: '',
            }
        },

        computed: {
            ...mapState({
                selectedOrganisation: state => state.organisation.selectedOrganisation,
                selectedOrganisationSection: state => state.organisation.selectedOrganisationSection,
            }),

            ...mapGetters('organisation', {
                organisations: GET_LIST,
            }),

            filteredOrganisations() {
                let organisations = this.organisations
                if (this.searchValue) {
                    organisations = organisations.filter((org) =>
                        org.name.toLowerCase().includes(this.searchValue.toLowerCase()),
                    )
                }

                return organisations
            },

            headers() {
                return [{
                    title: 'ID',
                    key: 'id',
                    sortable: false,
                    align: 'left',
                }, {
                    title: 'Name',
                    key: 'name',
                    sortable: false,
                    align: 'left',
                }, {
                    title: 'Sections',
                    key: 'sections',
                    sortable: false,
                    align: 'left',
                }, {
                    title: 'Users (active, inactive) / quota',
                    key: 'users',
                    align: 'left',
                    sortable: false,
                }, {
                    title: 'TS',
                    key: 'edit',
                    align: 'left',
                    sortable: false,
                }]
            },
        },

        watch: {
            isOrganisationFormDialogOpened(val) {
                if (!val) {
                    this.initialiseSelectedOrganisation()
                }
            },

            isOrganisationSectionFormDialogOpened(val) {
                if (!val) {
                    this.isEditOrganisationSectionMode = false
                }
            },
        },

        mounted() {
            this.getOrganisations()
        },

        methods: {
            getUserCounts(item) {
                const activeUsers = item.activeUsers ?? 0
                const inactive = item.inactiveUsers ?? 0
                const maxUsers = item.maxUsers ?? 0

                return `${ activeUsers + inactive } (${ activeUsers }, ${ inactive }) / ${ maxUsers }`
            },
            openOrganisationFormDialog(isEdit) {
                this.isOrganisationFormDialogOpened = true
                this.isEditOrganisationMode = Boolean(isEdit)
            },

            closeOrganisationFormDialog() {
                this.isOrganisationFormDialogOpened = false
                this.isEditOrganisationSectionMode = false
            },

            closeOrganisationSectionFormDialog() {
                this.isOrganisationSectionFormDialogOpened = false
                this.isEditOrganisationSectionMode = false
            },

            ...mapActions('organisation', {
                getOrganisations: LOAD_LIST,
            }),

            ...mapMutations('organisation', {
                updateSelectedOrganisation: MUTATE_SELECTED_ORGANISATION,
                updateSelectedOrganisationSection: MUTATE_SELECTED_ORGANISATION_SECTION,
                initialiseSelectedOrganisation: MUTATE_INITIALISE_SELECTED_ORGANISATION,
            }),

            editOrganisationSection(sections) {
                this.isOrganisationSectionsDialogOpened = false
                this.onEditOrganisationSectionClick(sections, this.selectedOrganisation)
            },

            openEditOrganisationDialog(organisation) {
                this.openOrganisationFormDialog(true)
                this.setSelectedOrganisation(organisation)
            },

            openOrganisationSectionsListDialog(organisation) {
                this.isOrganisationSectionsDialogOpened = true
                this.setSelectedOrganisation(organisation)
            },

            closeOrganisationSectionsListDialog() {
                this.isOrganisationSectionsDialogOpened = false
                this.initialiseSelectedOrganisation()
            },

            setSelectedOrganisation(organisation) {
                this.updateSelectedOrganisation({
                    id: organisation.id,
                    name: organisation.name,
                    maxUsers: organisation.maxWitnessUsers,
                    maxStandardUsers: organisation.maxStandardUsers,
                    maxPremiumUsers: organisation.maxPremiumUsers,
                    maxCopilotUsers: organisation.maxCopilotUsers,
                    activePremiumUsersCount: organisation.activePremiumUsersCount,
                    activeStandardUsersCount: organisation.activeStandardUsersCount,
                    pricingModelId: organisation.pricingModelId,
                    chargesEmails: organisation.chargesEmails,
                    address: organisation.address,
                    defaultSectionId: organisation.defaultSectionId,
                    sections: organisation.sections,
                    usesClientCodes: organisation.usesClientCodes,
                    mandatoryMatterCodes: organisation.mandatoryMatterCodes,
                    ppcStandardCharge: organisation.ppcStandardCharge,
                    pmpmMatterCharge: organisation.pmpmMatterCharge,
                    preferChargesCSVWithoutHeader: organisation.preferChargesCSVWithoutHeader,
                    preferChargesLandRegistryCSVFormat: organisation.preferChargesLandRegistryCSVFormat,
                    preferChargesUserFullNameRatherThanEmail: organisation.preferChargesUserFullNameRatherThanEmail,
                    preferChargesCSVSectionNameForOffice: organisation.preferChargesCSVSectionNameForOffice,
                    preferChargesCSVMatterCodeSeparator: organisation.preferChargesCSVMatterCodeSeparator,
                    hasTitleSummaryCharge: organisation.titleSummaryCharge != null,
                    titleSummaryCharge: organisation.titleSummaryCharge,
                    ssoOption: organisation.ssoOption,
                    ssoProviderId: organisation.ssoProviderId,
                    validEmailDomains: organisation.validEmailDomains,
                    enableFeatureHideableMatterSidePanel: organisation.enableFeatureHideableMatterSidePanel,
                    industry: organisation.industry,
                    status: organisation.status,
                    matterCodeFormat: organisation.matterCodeFormat,
                    matterCodeMask: organisation.matterCodeMask,
                    clientCodeFormat: organisation.clientCodeFormat,
                    canShareMattersWithWholeOrg: organisation.canShareMattersWithWholeOrg,
                    products: organisation.products,
                    regions: organisation.regions,
                    defaultLocale: organisation.defaultLocale,
                    grandfatheredModules: organisation.grandfatheredModules,
                })
                this.seats = {
                    activeWitnessUsers: organisation.activeWitnessUsers,
                    maxWitnessUsers: organisation.maxWitnessUsers,
                    activeCopilotUsers: organisation.activeCopilotUsers,
                    maxCopilotUsers: organisation.maxCopilotUsers,
                }
            },

            onAddOrganisationSectionClick(organisation) {
                this.updateSelectedOrganisationSection({
                    organisationName: organisation.name,
                    organisationId: organisation.id,
                    industry: organisation.industry,
                    status: organisation.status,
                    organisation,
                    name: null,
                    addressHouseNumber: null,
                    addressStreet: null,
                    addressLocality: null,
                    addressTown: null,
                    addressPostcode: null,
                })
                this.isOrganisationSectionFormDialogOpened = true
            },

            onEditOrganisationSectionClick(section, organisation) {
                this.updateSelectedOrganisationSection({
                    id: section.id,
                    organisationName: organisation.name,
                    organisationId: section.organisationId,
                    industry: organisation.industry,
                    status: organisation.status,
                    organisationDefaultSectionid: organisation.defaultSectionId,
                    name: section.name,
                    addressHouseNumber: section.addressHouseNumber,
                    addressStreet: section.addressStreet,
                    addressLocality: section.addressLocality,
                    addressTown: section.addressTown,
                    addressPostcode: section.addressPostcode,
                })
                this.isEditOrganisationSectionMode = true
                this.isOrganisationSectionFormDialogOpened = true
            },
        },
    }
</script>

<style lang="scss">
    @import './admin-organisations';
</style>
