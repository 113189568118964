// @/stores/asset-monitoring/actions.ts
import { HttpStatusCode } from 'axios'

import CompanyGroupsApi from '@/api/company-groups.api'
import CompanyNotificationsApi, { ICompanyNotificationSettings } from '@/api/company-notifications'
import MatterApi from '@/api/matter.api'
import NotificationsApi, {
    IGetNotificationResponseItem,
    IGetNotificationsRequest,
    INotificationMessageArguments,
    ITitleNotificationSettings,
    NotificationSorting,
} from '@/api/notifications.api'
import UserEmailPreferencesApi, { IUserEmailPreferences } from '@/api/user-email-preferences.api'
import { ReadStatus } from '@/components/asset-monitoring/read-status.enum'
import { checkFlag } from '@/feature-flags'
import { convertToNotificationSubType } from '@/stores/asset-monitoring/utils'
import { isNullOrEmpty } from '@/utils/array-utils'
import { format } from '@/utils/date-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

async function getMappedNotifications(matterId: number, options: IGetNotificationsRequest): Promise<{
    notifications: IGetNotificationResponseItem[],
    availableTitleNumbers: string[],
    availableCompanies: string[],
    totalNotifications: number,
}> {
    const response = await NotificationsApi.getNotificationsByMatter(matterId, options)

    let notifications: Map<string, IGetNotificationResponseItem> = new Map(response.data.userNotifications.map((notification: IGetNotificationResponseItem) => {
        return [
            notification.notificationId,
            notification,
        ]
    }))

    const metadata = response.data.metadata

    return {
        notifications: Array.from(notifications.values()),
        availableTitleNumbers: metadata?.titleNumbers?.filter(t => !isNullOrWhitespace(t)) ?? [],
        availableCompanies: metadata?.companyNumbers ?? [],
        totalNotifications: metadata.totalResults,
    }
}

export default {

    getNotificationRequestModel(): IGetNotificationsRequest {
        const {
            dateBefore,
            dateSince,
            filterText,
            pageIndex,
            pageSize,
            selectedCompanies,
            selectedNotificationTypes,
            selectedNotificationSubTypes,
            selectedPriorities,
            selectedTitles,
            sortBy,
        } = this

        const model = {
            pageIndex,
            pageSize,
            sortBy,
            dateSince,
            dateBefore,
            filters: filterText,
            types: selectedNotificationTypes,
            subTypes: selectedNotificationSubTypes,
            readStatus: this.showOnlyUnread ? ReadStatus[ReadStatus.UNREAD] : null,
            titleNumbers: selectedTitles,
            companyNumbers: selectedCompanies,
        } as IGetNotificationsRequest

        if (checkFlag('asset-monitoring-priorities')) {
            model.priorities = selectedPriorities
        }

        return model
    },

    /**
     * Retrieves paged notifications for a given matter.
     * @param {number} matterId - The ID of the matter to retrieve notifications for.
     */
    async getNotifications(matterId: number): Promise<void> {
        this.isLoading = true

        try {
            const {
                notifications,
                totalNotifications,
                availableCompanies,
                availableTitleNumbers,
            } = await getMappedNotifications(matterId, this.getNotificationRequestModel())

            this.notifications = notifications
            this.totalResults = totalNotifications
            this.availableCompanies = availableCompanies
            this.availableTitleNumbers = availableTitleNumbers
            this.lastCheckedDate = format(new Date(), 'dd-MM-yyyy HH:mm')

            await Promise.all([
                this.fetchAddressesForTitleNumbers(),
                this.fetchUnreadNotificationCounts(matterId),
            ])
        } catch (error) {
            console.error('Error retrieving notifications:', error)
        } finally {
            this.isLoading = false
        }
    },

    async fetchNotifications(matterId: number): Promise<void> {
        this.clearPendingNotifications()
        await this.fetchAllNotifications(matterId)
        await this.getNotifications(matterId)
    },

    /**
     * Retrieves all notifications for a given matter.
     * @param {number} matterId - The ID of the matter to retrieve notifications for.
     */
    async fetchAllNotifications(matterId: number): Promise<void> {
        this.isLoading = true

        try {
            const model = this.getNotificationRequestModel()
            model.pageSize = 9999999 // Get all notifications
            const { notifications } = await getMappedNotifications(matterId, model)
            this.allNotifications = notifications
        } catch (error) {
            console.error('Error retrieving notifications:', error)
        } finally {
            this.isLoading = false
        }
    },

    /**
     * Retrieves all notifications for a given matter within a time period for use on the summary tab.
     * The only filtering required is the dates and it can't change the notifications for the feed tab.
     * @param {number} matterId - The ID of the matter to retrieve notifications for.
     * @param dateSince
     * @param dateBefore
     */
    async fetchSummaryNotifications(matterId: number, dateSince: Date, dateBefore: Date): Promise<void> {
        this.isLoadingSummaryData = true

        try {
            const model = {
                pageIndex: 0,
                pageSize: 9999999, // Get all notifications  ,
                sortBy: 'DateDesc',
                dateSince,
                dateBefore,
            } as IGetNotificationsRequest

            const {
                notifications,
                availableTitleNumbers,
                availableCompanies,
                totalNotifications,
            } = await getMappedNotifications(matterId, model)
            this.summaryNotifications = notifications
            this.summaryTotalChanges = totalNotifications
            this.summaryTitles = availableTitleNumbers
            this.summaryCompanies = availableCompanies
        } catch (error) {
            console.error('Error retrieving notifications:', error)
        } finally {
            this.isLoadingSummaryData = false
        }
    },

    /**
     * Retrieves the unread notification counts for a given matter.
     * @param matterId - The ID of the matter to retrieve notifications for.
     */
    async fetchUnreadNotificationCounts(matterId: number): Promise<void> {
        try {
            const response = await NotificationsApi.getUnreadNotificationsCount(matterId)
            this.unreadTitleNotifications = response.data.unreadTitleNotifications
            this.unreadCompanyNotifications = response.data.unreadCompanyNotifications
        } catch (error) {
            console.error('Error retrieving notifications:', error)
        }
    },

    /**
     * Retrieves the addresses for the title numbers in the available title numbers list.
     */
    async fetchAddressesForTitleNumbers(): Promise<void> {
        try {
            const titleAddressesResponse = await MatterApi.getTitleAddresses(this.availableTitleNumbers)
            if (!isNullOrEmpty(titleAddressesResponse)) {
                titleAddressesResponse.forEach((response) => {
                    this.titleNumberAddressMap[response.titleNumber] = response?.addresses?.[0]?.address ?? []
                })
            }
        } catch (error) {
            console.error('Error retrieving notifications:', error)
        }
    },

    /**
     * Retrieves next notifications page for a given matter.
     * @param {number} matterId - The ID of the matter to retrieve notifications for.
     */
    async getNextNotificationsPage(matterId: number): Promise<void> {
        try {
            this.currentPageIndex++
            const {
                notifications,
                totalNotifications,
                availableCompanies,
                availableTitleNumbers,
            } = await getMappedNotifications(matterId, this.getNotificationRequestModel())

            this.notifications.push(...notifications)
            this.totalResults = totalNotifications
            this.availableCompanies = availableCompanies
            this.availableTitleNumbers = availableTitleNumbers
        } catch (error) {
            console.error('Error retrieving notifications:', error)
        }
    },

    /**
     * Updates the sort order for the notifications and retrieves the notifications for the specified matter.
     *
     * @param {string} sortBy - The sort order to be applied to the notifications.
     * @param {number} currentMatterId - The ID of the matter for which the notifications should be retrieved.
     *
     * @return {Promise<void>} - A promise that resolves once the sort order is updated and the notifications are retrieved.
     */
    async updateSortBy(sortBy: string, currentMatterId: number): Promise<void> {
        this.sortBy = sortBy
        this.currentPageIndex = 0
        await this.getNotifications(currentMatterId)
    },

    async updateSelectedCompanies(matterId: number, values: string[]): Promise<void> {
        this.selectedCompanies = values
        this.currentPageIndex = 0
        await this.getNotifications(matterId)
    },

    async updateSelectedPriorities(matterId: number, values: string[]): Promise<void> {
        this.selectedPriorities = values
        this.currentPageIndex = 0
        await this.getNotifications(matterId)
    },

    async updateSelectedTitles(matterId: number, values: string[]): Promise<void> {
        this.selectedTitles = values
        this.currentPageIndex = 0
        await this.getNotifications(matterId)
    },

    /**
     * Updates the selected notification subtypes and retrieves the notifications for that match.
     * @param matterId
     * @param subTypes
     */
    async updateSelectedNotificationSubTypes(matterId: number, subTypes: string[]): Promise<void> {
        this.selectedNotificationSubTypes = subTypes
        this.currentPageIndex = 0
        await this.getNotifications(matterId)
    },

    /**
     * Clears notification messages
     */
    clearPendingNotifications(): void {
        this.pendingNotifications = []
    },

    /**
     * Clears the selected entity values and notification subtypes.
     * Without reloading the notifications.
     */
    clear() {
        this.clearSelectedFilters()
        this.currentPageIndex = 0
        this.sortBy = NotificationSorting.dateDescending
        this.unreadTitleNotifications = 0
        this.unreadCompanyNotifications = 0
        this.clearPendingNotifications()
        this.companyGroups = []
    },

    clearSelectedFilters() {
        this.selectedTitles = []
        this.selectedNotificationSubTypes = []
        this.selectedCompanies = []
        this.selectedPriorities = []
        this.dateSince = null
        this.dateBefore = null
    },

    /**
     * Adds a received notification to the `notifications` array.
     *
     * @param notification - The notification to be added.
     */
    addReceivedNotification(notification: INotificationMessageArguments) {
        if (isNullOrEmpty(this.pendingNotifications)) {
            this.pendingNotifications = []
        }
        this.pendingNotifications.push({
            ...notification,
            type: convertToNotificationSubType(notification.type.toString()),
        })
    },

    /**
     * Marks the specified notifications as read / unread.
     *
     * @param {number} matterId - The ID of the matter for which to dismiss the notifications.
     * @param {string} notificationIds - The IDs of the notifications to dismiss.
     * @param {boolean} markAsRead - Whether to mark the notifications as read / unread.
     */
    async markAsRead(matterId: number, notificationIds: Array<string>, markAsRead = true): Promise<void> {
        try {
            const response = await NotificationsApi.markAsRead(notificationIds, markAsRead)
            if (response.status === HttpStatusCode.Ok) {
                notificationIds.forEach((notificationId: string) => {
                    const notification = this.notifications.find(n => n.notificationId === notificationId)
                    if (notification) {
                        notification.isRead = markAsRead
                    }
                })
            }
            await this.fetchUnreadNotificationCounts(matterId)
        } catch (e) {
            console.error('Error dismissing notifications:', e, notificationIds)
        }
    },

    /**
     * Checks if the specified notification is read.
     * @param notificationId - The ID of the notification to check.
     */
    isNotificationRead(notificationId: string): boolean {
        const notification: IGetNotificationResponseItem = this.notifications.find((n: IGetNotificationResponseItem) => n.notificationId === notificationId)
        return notification?.isRead
    },

    /**
     * Retrieves the title notification settings for a given matter.
     *
     * @param {number} matterId - The ID of the matter for which to retrieve the notification settings.
     * @return {Promise<void>} - A promise that resolves with no value upon successful retrieval of notification settings.
     * @throws {Error} - If an error occurs while retrieving the notification settings.
     */
    async getTitleNotificationSettings(matterId: number): Promise<void> {
        try {
            this.isLoadingSettings = true
            const response = await NotificationsApi.getTitleNotificationSettings(matterId)
            this.titleNotificationSettings = {
                ...response.data,
                isActive: Object.values(response.data).some((value: boolean) => value === true),
            }
            this.areTitleNotificationSettingsLoaded = true
        } catch (error) {
            if (error?.response?.status === HttpStatusCode.NotFound) {
                // If monitoring hasn't been setup for this matter, then get not found back from the API
                this.titleNotificationSettings = {
                    trackDaylist: false,
                    trackOcda: false,
                    trackOwnership: false,
                    trackBoundary: false,
                    trackEpc: false,
                    trackCompaniesHouse: false,
                    trackPlanningApplications: false,
                }
            } else {
                console.error('Error retrieving title notification settings:', error)
            }
        } finally {
            this.isLoadingSettings = false
        }
    },

    /**
     * Retrieves the company notification settings for a given matter.
     *
     * @param {number} matterId - The ID of the matter for which to retrieve the notification settings.
     * @return {Promise<void>} - A promise that resolves with no value upon successful retrieval of notification settings.
     * @throws {Error} - If an error occurs while retrieving the notification settings.
     */
    async getCompanyNotificationSettings(matterId: number): Promise<void> {
        try {
            this.isLoadingSettings = true
            const response = await CompanyNotificationsApi.getCompanyNotificationSettings(matterId)
            this.companyNotificationSettings = {
                ...response.data,
                isActive: Object.values(response.data).some((value: boolean) => value === true),
            }
            this.areCompanyNotificationSettingsLoaded = true
        } catch (error) {
            if (error?.response?.status === HttpStatusCode.NotFound) {
                // If monitoring hasn't been setup for this matter, then get not found back from the API
                this.companyNotificationSettings = {
                    isActive: false,
                    trackCompaniesHouseInformation: false,
                    trackCompaniesHouseInsolvency: false,
                    trackCompanyTitleOwnership: false,
                }
            } else {
                console.error('Error retrieving company notification settings:', error)
            }
        } finally {
            this.isLoadingSettings = false
        }
    },

    /**
     * Updates the title notification settings for a specific matter.
     *
     * @param {number} matterId - The ID of the matter.
     * @param {ITitleNotificationSettings} request - The notification settings to be updated.
     * @return {Promise<void>} A promise that resolves when the notification settings are updated successfully.
     */
    async updateTitleNotificationSettings(matterId: number, request: ITitleNotificationSettings): Promise<void> {
        try {
            // Doesn't return any data, so fire and forget
            this.isUpdatingSettings = true
            await NotificationsApi.updateTitleNotificationSettings(matterId, request)
            this.notificationSettings = request

            // Reload the notification settings after updating
            await this.getTitleNotificationSettings(matterId)

            // Reload notifications after updating settings
            this.reloadNotifications = true

        } catch (error) {
            console.error('Error updating title notification settings:', error)
        } finally {
            this.isUpdatingSettings = false
        }
    },

    /**
     * Updates the company notification settings for a specific matter.
     *
     * @param {number} matterId - The ID of the matter.
     * @param {ICompanyNotificationSettings} request - The notification settings to be updated.
     * @return {Promise<void>} A promise that resolves when the notification settings are updated successfully.
     */
    async updateCompanyNotificationSettings(matterId: number, request: ICompanyNotificationSettings): Promise<void> {
        try {
            // Doesn't return any data, so fire and forget
            this.isUpdatingSettings = true
            await CompanyNotificationsApi.updateCompanyNotificationSettings(matterId, request)
            this.notificationSettings = request

            // Reload the notification settings after updating
            await this.getCompanyNotificationSettings(matterId)

            // Reload notifications after updating settings
            this.reloadNotifications = true

        } catch (error) {
            console.error('Error updating company notification settings:', error)
        } finally {
            this.isUpdatingSettings = false
        }
    },

    /**
     * Updates the selected read status subtypes and retrieves the notifications for that match.
     * @param matterId
     * @param readStatus
     */
    async updateShowUnreadOnly(matterId: number, readStatus: boolean): Promise<void> {
        this.showOnlyUnread = readStatus
        this.currentPageIndex = 0
        this.clearSelectedFilters()
        await this.fetchNotifications(matterId)
        this.reloadFilters = true
    },

    /**
     * Retrieves the user's email preferences for a given matter.
     *
     * @param {number} matterId - The ID of the matter for which to retrieve the preferences.
     * @return {Promise<void>} - A promise that resolves with no value upon successful retrieval of the preferences.
     * @throws {Error} - If an error occurs while retrieving the preferences.
     */
    async fetchUserEmailPreferences(matterId: number): Promise<void> {
        try {
            this.isLoadingUserEmailPreferences = true
            const response = await UserEmailPreferencesApi.getUserEmailPreferences(matterId)
            this.userEmailPreferences = {
                ...response.data,
            }
            this.areUserEmailPreferencesLoaded = true
        } catch (error) {
            console.error('Error retrieving notification settings:', error)
        } finally {
            this.isLoadingUserEmailPreferences = false
        }
    },

    /**
     * Updates the user email preferences for a specific matter.
     *
     * @param {number} matterId - The ID of the matter.
     * @param {IUserEmailPreferences} request - The user email preferences to be updated.
     * @return {Promise<void>} A promise that resolves when the email preferences are updated successfully.
     */
    async updateUserEmailPreferences(matterId: number, request: IUserEmailPreferences): Promise<void> {
        try {
            this.isUpdatingUserEmailPreferences = true
            await UserEmailPreferencesApi.updateUserEmailPreferences(matterId, request)
            this.userEmailPreferences = request
        } catch (error) {
            console.error('Error updating user email preferences:', error)
        } finally {
            this.isUpdatingUserEmailPreferences = false
        }
    },

    /**
     * Fetches the company groups for a given matter.
     * @param matterId
     */
    async fetchCompanies(matterId: number): Promise<void> {
        this.isLoading = true
        try {
            const resp = await CompanyGroupsApi.getCompanyGroups(matterId)
            this.companyGroups = resp?.data?.companyGroups
        } catch(error) {
            if (error?.response?.status === HttpStatusCode.NotFound) {
                this.companyGroups = []
            } else {
                console.error('Error retrieving company groups:', error)
            }
            console.error('Error retrieving company groups')
        } finally {
            this.isLoading = false
        }
    },

    resetSummaryData() {
        this.summaryNotifications = []
        this.summaryTotalChanges = 0
        this.summaryTitles = []
        this.summaryCompanies = []
    },

    initialise(matterId: number) {
        this.clear()

        this.fetchAllNotifications(matterId)
        this.getNotifications(matterId)
        this.getTitleNotificationSettings(matterId)
        this.getCompanyNotificationSettings(matterId)
        this.fetchUserEmailPreferences(matterId)
        this.fetchCompanies(matterId)
    },

}
