<template>
    <base-sub-type-table v-model:update-count="updateCount"
                         :headings="headings"
                         :notification="item"
                         data-track="Planning Applications Updated Item"
                         data-test="planning-applications-updated-item" />
</template>

<script setup lang="ts">
    import {
        computed,
    } from "vue"
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotificationDetail } from "@/components/asset-monitoring/asset-monitoring-notification.interface"
    import {
        EpcDatapoint,
        PlanningApplicationsDatapoint,
    } from '@/components/asset-monitoring/datapoint-interfaces'
    import BaseSubTypeTable
        from "@/components/asset-monitoring/grid/sub-type-items/base-sub-type-table/base-sub-type-table.vue"
    import { IBaseSubTypeTableColumn } from "@/components/asset-monitoring/grid/sub-type-items/types"
    import { EnergyBand } from "@/enums/epc.enum"
    import { getThemeForEnergyBand } from "@/models/epc.model"
    import { format } from "@/utils/date-utils"

    const { t } = useI18n()
    const updateCount = defineModel<number>('update-count')

    const DECISION_LABELS: Record<number, string> = {
        0: 'colored-label is-warning is-extra-small',
        1: 'colored-label is-success is-extra-small',
        2: 'colored-label is-danger is-extra-small',
    }

    const renderDecisionLabel = (decision: number): string => {
        const labelClass = DECISION_LABELS[decision]
        const value = t(`assetMonitoring.subType.planningApplication.decision.${ decision }`)
        return labelClass ? `<span class="${ labelClass }">${ value }</span>` : value
    }

    const headings = computed<IBaseSubTypeTableColumn<PlanningApplicationsDatapoint>[]>(() => ([
        {
            field: 'identifier',
            title: t('label.identifier'),
            dataTest: 'identifier',
        },
        {
            field: 'decision',
            title: t('label.decision'),
            dataTest: 'decision',
            customRender: (row: PlanningApplicationsDatapoint) => {
                return renderDecisionLabel(row.decision)
            },
        },
        {
            field: 'type',
            title: t('label.type'),
            dataTest: 'type',
            customRender: (row: PlanningApplicationsDatapoint) => {
                return t(`assetMonitoring.subType.planningApplication.type.${ row.type }`)
            },
        },
        {
            field: 'address',
            title: t('label.address'),
            dataTest: 'address',
        },
        {
            field: 'postcode',
            title: t('label.postcode'),
            dataTest: 'postcode',
        },
        {
            field: 'url',
            title: t('label.url'),
            dataTest: 'url',
            customRender: (row: PlanningApplicationsDatapoint) => {
                return row.url ? `<a class="cursor-pointer" href="${ row.url }" target="_blank">${ row.url }</a>` : null
            },
        },
    ]))

    defineProps<{
        item: IAssetMonitoringNotificationDetail,
    }>()

    defineExpose({
        updateCount,
    })
</script>