<template>
    <v-progress-circular :size="currentValues.size"
                         :width="currentValues.width"
                         :color="color"
                         indeterminate />
</template>

<script lang="ts">
    export interface IProgressType {
        size: number,
        width: number,
    }

    const SIZES = {
        'x-small': {
            size: 14,
            width: 2,
        },
        small: {
            size: 20,
            width: 3,
        },
        medium: {
            size: 35,
            width: 7,
        },
        large: {
            size: 70,
            width: 10,
        },
    }

    const EVENTS = {
        input: 'input',
    }

    export default {
        name: 'OwProgress',

        props: {
            size: {
                type: String,
                required: false,
                default: 'small',
                validator: (prop: string) => {
                    return ['x-small', 'small', 'medium', 'large'].includes(prop)
                },
            },
            color: {
                type: String,
                required: false,
                default: 'primary',
                validator: (prop: string) => {
                    return ['primary', 'secondary', 'warning', 'error', 'success'].includes(prop)
                },
            },
        },

        emits: [
            EVENTS.input,
        ],

        computed: {
            currentValues(): IProgressType {
                return SIZES[this.size]
            },
        },
    }
</script>
