<template>
    <div v-hotkey="keymap"
         class="matter-side-panel-filter-item">
        <div v-if="!isWalkthrough && !isMatterLinkShareUser"
             class="matter-side-panel-filter-item__action-buttons">
            <ow-button data-test="matter-add-titles-button"
                       full-width
                       is-secondary
                       @click="onEventHandler(events.addTitles)">
                {{ $t('matter.addTitles') }}
            </ow-button>
            <app-upload-documents-button v-if="isUploadDocumentsEnabled"
                                         :current-matter-id="currentMatterId"
                                         button-data-test-attribute="matter-titles-list-upload-documents-btn"
                                         button-data-track-attribute="MATTER - Upload documents"
                                         full-width-button />
        </div>
        <div v-if="showFilterItemControls"
             :class="{ '--matter-link-share-user': isMatterLinkShareUser }"
             class="matter-side-panel-filter-item__controls"
             data-test="matter-side-panel-filter-item__controls">
            <v-checkbox-btn v-show="!isWalkthrough && !isMatterLinkShareUser"
                            id="matter-side-panel-filter-item__controls--select-all"
                            data-test="matter-side-panel-filter-item__controls--select-all"
                            color="primary"
                            density="compact"
                            :model-value="selectingAll || indeterminate"
                            :indeterminate="indeterminate"
                            :label="selectAllLabel"
                            @update:model-value="handleSelectionChange" />

            <ow-button ref="filterToggle"
                       small
                       :icon="groupCount > 1"
                       data-test="matter-side-panel-filter-item__controls--filter"
                       :tooltip="filtering ? $t('matter.closeFilterHint') : $t('matter.filterHint')"
                       @click="toggleFilter">
                <template v-if="groupCount <= 1"
                          #iconSuffix>
                    <v-icon>
                        {{ filterIcon }}
                    </v-icon>
                </template>
                <v-icon v-if="groupCount > 1"
                        class="ml-2">
                    {{ filterIcon }}
                </v-icon>
                <span v-if="groupCount <= 1">{{ $t('titlePanel.filter') }}</span>
            </ow-button>

            <ow-button v-if="groupCount > 1 && !isWalkthrough && !isMatterLinkShareUser"
                       ref="sortGroupsToggle"
                       small
                       icon
                       :tooltip="getSortTooltip"
                       data-test="matter-side-panel-filter-item__controls-sort-groups"
                       @click="handleSort">
                <v-icon class="ml-2">
                    {{ sortIcon }}
                </v-icon>
            </ow-button>

            <ow-button v-if="groupCount > 1 && !isWalkthrough && !isMatterLinkShareUser"
                       ref="expandCollapseGroupsToggle"
                       small
                       icon
                       :tooltip="getExpandCollapseGroupsTooltip"
                       data-test="matter-side-panel-filter-item__controls-expand-collapse-groups"
                       @click="handleExpandCollapseGroups">
                <v-icon class="ml-2"
                        :class="{
                            'mdi-rotate-90': !localExpandCollapseGroupsValue,
                        }">
                    {{ expandCollapseGroupsIcon }}
                </v-icon>
            </ow-button>

            <ow-button v-if="!isWalkthrough && !isMatterLinkShareUser"
                       class="matter-side-panel-filter-item__controls--create-group"
                       data-test="matter-side-panel-filter-item__controls--create-group"
                       small
                       icon
                       :tooltip="$t('matter.createGroupHint')"
                       @click="onEventHandler(events.createGroup)">
                <v-icon class="ml-2">
                    {{ `$create-group` }}
                </v-icon>
            </ow-button>
        </div>

        <ow-textfield v-if="filtering"
                      ref="textfield"
                      v-model="localFilter"
                      class="matter-side-panel-filter-item__hint"
                      small
                      clearable
                      @blur="handleBlur"
                      @click:clear="handleClear">
            <template #iconPrefix>
                <v-icon>$filter</v-icon>
            </template>
            <span v-dompurify-html="$t('matter.filterTitlesHint')" />
        </ow-textfield>
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue'
    import {
        mapActions,
        mapState,
    } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import AppUploadDocumentsButton from '@/components/shared/upload-documents-button.vue'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'
    import { IState } from '@/interfaces/store/state.interface'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'

    export enum ExpandCollapseGroupsValue {
        Expand = 'expand',
        Collapse = 'collapse',
    }
    export enum SortValue {
        Asc = 'asc',
        Desc = 'desc',
        None = null,
    }

    export default {
        components: {
            AppUploadDocumentsButton,
            OwButton,
            OwTextfield,
        },
        mixins: [FeatureFlagsMixin],
        props: {
            value: {
                type: String,
                default: '',
            },
            sortValue: {
                type: Object as PropType<SortValue>,
                default: null,
            },
            expandCollapseGroupsValue: {
                type: Object as PropType<ExpandCollapseGroupsValue>,
                default: ExpandCollapseGroupsValue.Expand,
            },
            titleCount: {
                type: Number,
                default: 0,
            },
            groupCount: {
                type: Number,
                default: 0,
            },
            selectedTitleCount: {
                type: Number,
                default: 0,
            },
            showFilterItemControls: {
                type: Boolean,
                default: true,
            },
        },
        emits: [
            'toggle-filter',
            'select:all',
            'select:none',
            'add-titles',
            'create-group',
            'update:model-value',
            'update:sort-value',
            'sort-group',
            'on-expand',
            'on-collapse',
            'expand-collapse-groups',
            'update:expand-collapse-groups-value',
        ],
        data() {
            return {
                events: {
                    toggleFilter: 'toggle-filter',
                    selectAll: 'select:all',
                    selectNone: 'select:none',
                    addTitles: 'add-titles',
                    createGroup: 'create-group',
                },
                filtering: false,
            }
        },
        computed: {
            ...mapState({
                isWalkthrough: (state: IState) => state.walkthrough.enabled,
                isMatterLinkShareUser: (state: IState) => state.linkShareClient.isMatterLinkShareUser || state.linkShareClient.isSharedLinkView,
                currentMatterId: (state: IState) => state.matter.currentMatter.id,
            }),
            localFilter: {
                get(): string {
                    return this.value
                },
                set(newValue: string): void {
                    this.$emit('update:model-value', newValue)
                },
            },
            localSortValue: {
                get(): SortValue {
                    return this.sortValue
                },
                set(newValue: SortValue): void {
                    this.$emit('update:sort-value', newValue)
                },
            },
            localExpandCollapseGroupsValue: {
                get(): ExpandCollapseGroupsValue {
                    return this.expandCollapseGroupsValue
                },
                set(newValue: ExpandCollapseGroupsValue): void {
                    this.$emit('update:expand-collapse-groups-value', newValue)
                },
            },
            selectAllLabel(): string {
                if (!this.isWalkthrough) {
                    if (this.selectedTitleCount === 0) {
                        return this.$t('matter.selectAll', { count: this.titleCount })
                    } else {
                        return this.$t('matter.selectedCount', { count: this.selectedTitleCount })
                    }
                }

                if (this.selectingAll || this.indeterminate) {
                    return this.$t('matter.removeAll', { count: this.selectedTitleCount })
                } else {
                    return this.$t('matter.addAll', { count: this.titleCount })
                }
            },
            selectingAll(): boolean {
                return Boolean(this.titleCount) && this.titleCount === this.selectedTitleCount
            },
            indeterminate(): boolean {
                return !this.selectingAll && Boolean(this.selectedTitleCount)
            },
            keymap(): object {
                return {
                    esc: () => {
                        this.filtering = false
                    },
                }
            },
            getSortTooltip(): string {
                if (this.localSortValue === SortValue.Asc) {
                    return this.$t('matter.sortTitlesAscending')
                } else if (this.localSortValue === SortValue.Desc) {
                    return this.$t('matter.sortTitlesDescending')
                } else {
                    return this.$t('matter.sortTitlesHint')
                }
            },
            getExpandCollapseGroupsTooltip(): string {
                if (this.localExpandCollapseGroupsValue === ExpandCollapseGroupsValue.Expand) {
                    return this.$t('matter.collapseGroups')
                }
                return this.$t('matter.expandGroups')
            },
            sortIcon() {
                if (this.localSortValue === SortValue.Asc) {
                    return '$sortAscending'
                } else if (this.localSortValue === SortValue.Desc)  {
                    return '$sortDescending'
                } else {
                    return '$sort'
                }
            },
            expandCollapseGroupsIcon() {
                if (this.localExpandCollapseGroupsValue === ExpandCollapseGroupsValue.Expand) {
                    return '$chevron-up'
                }
                return '$chevron-down'
            },
            filterIcon() {
                return this.filtering ? '$close' : '$filter'
            },
        },
        watch: {
            filtering(value) {
                if (!value) {
                    this.localFilter = ''
                    this.$refs.filterToggle?.$el.blur()
                } else {
                    this.$nextTick(() => this.$refs.textfield.focus())
                }
            },
        },
        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),
            async handleSort(): Promise<void> {
                await this.logHeapEvent({
                    type: 'MAT-TITLE-LIST - Sort button clicked',
                })

                // sort logic asc -> desc -> none
                if (this.localSortValue === SortValue.Asc) {
                    this.localSortValue = SortValue.Desc
                } else if (this.localSortValue === SortValue.Desc) {
                    this.localSortValue = null
                } else {
                    this.localSortValue = SortValue.Asc
                }
                this.$emit('sort-group', this.localSortValue)
            },
            async toggleFilter(): Promise<void> {
                this.filtering = !this.filtering

                this.$emit(this.events.toggleFilter, this.filtering)

                if (this.filtering) {
                    await this.logHeapEvent({
                        type: 'MAT-TITLE-LIST - Filter button clicked',
                    })
                } else {
                    await this.logHeapEvent({
                        type: 'MAT-TITLE-LIST - Filter button closed',
                    })
                }
            },
            async handleExpandCollapseGroups(): Promise<void> {
                await this.logHeapEvent({
                    type: 'MAT-TITLE-LIST - Expand/collapse button clicked',
                })

                // expand/collapse logic collapse -> expand -> none
                if (this.localExpandCollapseGroupsValue === ExpandCollapseGroupsValue.Expand) {
                    this.localExpandCollapseGroupsValue = ExpandCollapseGroupsValue.Collapse
                } else if (this.localExpandCollapseGroupsValue === ExpandCollapseGroupsValue.Collapse) {
                    this.localExpandCollapseGroupsValue = ExpandCollapseGroupsValue.Expand
                }
                this.$emit('expand-collapse-groups', this.localExpandCollapseGroupsValue)
            },
            async handleClear(): Promise<void> {
                await this.logHeapEvent({
                    type: 'MAT-TITLE-LIST - Clear filter input',
                })
            },
            async handleBlur(): Promise<void> {
                if (this.localFilter) {
                    await this.logHeapEvent({
                        type: 'MAT-TITLE-LIST - Filter input box',
                        metadata: {
                            input: this.localFilter,
                        },
                    })
                }
            },
            handleSelectionChange(value: boolean): void {
                if (value) {
                    this.$emit(this.events.selectAll)
                } else {
                    this.$emit(this.events.selectNone)
                }
            },
            onEventHandler(evtName: string): void {
                this.$emit(evtName)
            },
        },
    }
</script>

<style lang="scss">
    @import 'matter-side-panel-filter-item';
</style>
