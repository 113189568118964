<template>
    <div class="asset-monitoring-data-grid">
        <header class="asset-monitoring-data-grid__header">
            <div class="label-caps-small asset-monitoring-data-grid__header--details">
                {{ t('assetMonitoring.dataGrid.label.details') }}
            </div>
            <div />
            <div class="label-caps-small asset-monitoring-data-grid__header--date text-no-wrap"
                 data-track="Asset Monitoring: sort alerts by date"
                 @click="dateSortHandler">
                {{ t('assetMonitoring.dataGrid.label.date') }}
                <v-icon :class="{
                    'date-desc': currentSortOrder === NotificationSorting.dateDescending,
                    'date-asc': currentSortOrder === NotificationSorting.dateAscending,
                }">
                    $chevron-up
                </v-icon>
            </div>
        </header>

        <div :class="{
                 'asset-monitoring-data-grid__updates-banner--visible': pendingNotifications.length > 0,
             }"
             class="asset-monitoring-data-grid__updates-banner body-regular">
            {{
                t('assetMonitoring.text.updatesReceived', {
                    count: pendingNotifications.length,
                })
            }}
            <ow-button is-secondary
                       small
                       data-track="Asset monitoring - refresh alerts"
                       @click="refreshHandler">
                {{ t('action.refresh') }}
            </ow-button>
        </div>

        <div v-if="isLoading"
             class="asset-monitoring-data-grid__skeleton">
            <ow-loading-skeleton v-for="i in 5"
                                 :key="i"
                                 class="asset-monitoring-data-grid__skeleton"
                                 height="130px"
                                 wiidth="100%" />
        </div>
        <div v-else-if="!hasItems"
             data-test="asset-monitoring-data-grid-no-data"
             class="asset-monitoring-data-grid__no-data">
            <ow-icon-asset-monitoring />
            <div class="asset-monitoring-data-grid__no-data--text">
                <h3 class="headers-display">
                    {{ t('assetMonitoring.text.noData') }}
                </h3>
                <p class="body-regular">
                    {{ t('assetMonitoring.text.noDataInfo') }}
                </p>
            </div>
        </div>
        <div v-else>
            <v-list class="asset-monitoring-data-grid__row-container">
                <v-list-item v-for="row in rows"
                             :key="row.data.notificationId"
                             :data-test="`asset-monitoring-data-grid-item-${ row.data.notificationId }`"
                             data-track="Asset monitoring - alert item"
                             class="asset-monitoring-data-grid__rows">
                    <component :is="row.component"
                               :item="row.data"
                               :matter-id="matterId"
                               :is-showing-details-panel="expandAllRows"
                               container-selector=".asset-monitoring-data-grid__rows"
                               @mark-as-read="handleMarkAsRead"
                               @view-title="handleViewTitle"
                               @view-company="handleViewCompany" />
                </v-list-item>
                <v-list-item v-if="!isLoading && moreDataAvailable && autoLoadMoreData"
                             v-intersect="loadNextPage"
                             data-test="asset-monitoring-data-grid-loader">
                    <ow-loading-skeleton height="112px" />
                </v-list-item>
            </v-list>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        markRaw,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import {
        useRoute,
        useRouter,
    } from 'vue-router'
    import { useStore } from 'vuex'

    import {
        INotificationMessageArguments,
        NotificationSorting,
    } from '@/api/notifications.api'
    import { IAssetMonitoringNotification } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import NotificationTypeRowItem
        from '@/components/asset-monitoring/grid/row-items/notification-type-row-item.vue'
    import SubTypeRowItem
        from '@/components/asset-monitoring/grid/row-items/sub-type-row-item.vue'
    import { NotificationSubType } from '@/components/asset-monitoring/notification-sub-type.enum'
    import { NotificationType } from '@/components/asset-monitoring/notification-type.enum'
    import OwIconAssetMonitoring from '@/components/core/icons/ow-icon-asset-monitoring.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import { Route } from '@/enums/route.enum'
    import { TitlePanelTabName } from '@/enums/title-panel-tab-name'
    import { checkFlag } from '@/feature-flags'
    import { OPEN_WINDOW } from '@/store/mutation-types'
    import { useAssetMonitoringStore } from '@/stores/asset-monitoring'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { getCompaniesHouseLinkFromCompanyRegistrationNumber } from '@/utils/link-utils'

    const { t } = useI18n()
    const rows = ref([])
    const assetMonitoringStore = useAssetMonitoringStore()
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const emit = defineEmits<{
        (e: 'refresh'),
    }>()

    const props = withDefaults(defineProps<{
        items: IAssetMonitoringNotification[],
        matterId: number,
        isLoading: boolean,
        autoLoadMoreData?: boolean,
        expandAllRows?: boolean,
    }>(), {
        items: () => [],
        matterId: undefined,
        isLoading: false,
        autoLoadMoreData: true,
        expandAllRows: false,
    })

    const pendingNotifications = computed<Array<INotificationMessageArguments>>(() => assetMonitoringStore.pendingNotifications)
    const hasItems = computed((): boolean => !isNullOrEmpty(props.items))
    const totalResults = computed((): number => assetMonitoringStore.totalResults)
    const moreDataAvailable = computed((): boolean => (totalResults.value - props.items.length) > 0)

    const getNotificationTypeComponent = (notificationType: NotificationType) => {
        switch (notificationType) {
            case NotificationType.TitleRegisterOutOfDate:
            case NotificationType.OwnershipChanged:
            case NotificationType.BoundaryChanged:
            case NotificationType.EpcRecordChanged:
                return markRaw(SubTypeRowItem)
            case NotificationType.PlanningApplicationRecordChanged:
                if (!store.state?.config?.featureFlags?.assetMonitoringPlanningApplications) {
                    return false
                }
                return markRaw(SubTypeRowItem)
            case NotificationType.CompaniesHouseChanged:
                return markRaw(NotificationTypeRowItem)

        }
    }

    watch(() => props.items, () => {
        rows.value = props.items?.map((item: IAssetMonitoringNotification) => {
            return {
                component: getNotificationTypeComponent(item.notificationType),
                data: {
                    ...item,
                    titleNumber: item.titleNumber,
                },
            }
        })
    }, { immediate: true })

    const currentSortOrder = computed({
        get: () => assetMonitoringStore.sortBy,
        set: (value: string) => assetMonitoringStore.sortBy = value,
    })
    const dateSortHandler = () => {
        if (currentSortOrder.value === NotificationSorting.dateAscending) {
            currentSortOrder.value = 'desc'
        } else {
            currentSortOrder.value = 'asc'
        }

        switch (currentSortOrder.value) {
            case 'asc':
                assetMonitoringStore.updateSortBy(NotificationSorting.dateAscending, props.matterId)
                break
            default:
                assetMonitoringStore.updateSortBy(NotificationSorting.dateDescending, props.matterId)
                break
        }
    }

    const refreshHandler = async () => {
        assetMonitoringStore.clearPendingNotifications()
        emit('refresh')
    }

    const loadNextPage = (intersects: boolean) => {
        if(intersects && moreDataAvailable.value){
            handleLoadNextPage()
        }
    }


    const getActiveTab = (notificationSubType: NotificationSubType) => {
        let activeTab =  TitlePanelTabName.Summary
        switch (notificationSubType) {
            case NotificationSubType.Epc:
                activeTab = TitlePanelTabName.Epc
                break
            case NotificationSubType.NewDocumentInOCDA:
            case NotificationSubType.EditionDateDoesntMatchOcdaResponse:
                activeTab = TitlePanelTabName.CopiesFiled
                break
            case NotificationSubType.CompletedPendingApplication:
                activeTab = TitlePanelTabName.DayList
                break
            case NotificationSubType.PlanningApplication:
                activeTab = TitlePanelTabName.Planning
                break
        }
        return activeTab
    }

    const handleViewCompany = async (args: {
        companyNumbers: number[],
        notificationSubType: NotificationSubType
    }): Promise<void> => {
        await store.dispatch(OPEN_WINDOW, getCompaniesHouseLinkFromCompanyRegistrationNumber(args?.companyNumbers?.[0]))
    }

    const handleViewTitle = async (args: {
        titleNumber: string,
        notificationSubType: NotificationSubType
    }): Promise<void> => {
        if (checkFlag('title-panel-v-2', false)) {
            const activeTab = getActiveTab(args.notificationSubType)
            await router.push({
                name: Route.AssetMonitoringTitleDetailsPanel,
                params: {
                    titleNumber: args.titleNumber,
                    matterId: props.matterId,
                },
                query: {
                    from: route.name.toString(),
                    tab: activeTab,
                },
            })
        } else {
            await router.push({
                name: Route.MatterMapTitle,
                params: {
                    titleNumber: args.titleNumber,
                    matterId: props.matterId,
                },
            })
        }
    }

    const handleMarkAsRead = async ({
        notificationId,
        isRead,
    }) => {
        await assetMonitoringStore.markAsRead(props.matterId, [notificationId], isRead)
    }

    const handleLoadNextPage = async () => {
        await assetMonitoringStore.getNextNotificationsPage(props.matterId)
    }

</script>

<style lang="scss"
       scoped>
    @import "./data-grid.scss";
</style>
