<template>
    <div class="exporting-status-modal">
        <ow-modal v-model="showModal"
                  :title="modalContent.title"
                  persistent>
            <div class="exporting-status-modal__content">
                <v-icon v-if="modalContent.icon"
                        :class="{
                            'icon-warning': modalContent.isFailure,
                            'icon-success': modalContent.isSuccess,
                        }"
                        class="exporting-status-modal__content--icon"
                        data-test="exporting-status-modal-content-icon">
                    {{ modalContent.icon }}
                </v-icon>

                <ow-progress v-if="modalContent.showLoadingIcon"
                             class="exporting-status-modal__content--icon"
                             color="primary"
                             data-test="exporting-status-modal-content-progress"
                             size="medium" />

                <p v-if="modalContent.text"
                   class="caption-regular exporting-status-modal__content--text"
                   data-test="exporting-status-modal-content-text">
                    {{ modalContent.text }}
                    <a v-if="modalContent.showTryAgainLink"
                       :class=" {
                           'disabled': isButtonOrLinkDisabled,
                       }"
                       data-test="exporting-status-modal-content-text-try-again"
                       href="#"
                       @click="retryHandler">try again</a>
                    .
                </p>

                <p v-if="showCarryOnAndKeepWaitingMsg"
                   v-t="'report.error.tooLong'"
                   data-test="exporting-status-modal-content-carry-on-waiting" />
            </div>
            <template #actions>
                <div class="exporting-status-modal__actions">
                    <ow-button v-if="modalContent.showDoneButton"
                               :disabled="isButtonOrLinkDisabled"
                               data-test="exporting-status-modal-actions-done"
                               full-width
                               is-secondary
                               @click="showModal = false">
                        <span v-t="'action.done'" />
                    </ow-button>
                    <ow-button v-if="modalContent.showCancelButton"
                               data-test="exporting-status-modal-actions-cancel"
                               full-width
                               @click="showModal = false">
                        <span v-t="'action.cancel'" />
                    </ow-button>
                    <ow-button v-if="modalContent.showTryAgainButton"
                               :disabled="isButtonOrLinkDisabled"
                               data-test="exporting-status-modal-actions-try-again"
                               full-width
                               is-secondary
                               @click="retryHandler">
                        <span v-t="'action.tryAgain'" />
                    </ow-button>
                </div>
            </template>
        </ow-modal>
    </div>
</template>

<script lang="ts">
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import OwProgress from '@/components/core/ow-progress.vue'

    export const EVENTS = {
        Input: 'update:modelValue',
        Retry: 'request-retry',
    }

    export interface IModalContent {
        title: string,
        text: string,
        icon?: string,
        isFailure?: boolean,
        isSuccess?: boolean,
        showCancelButton?: boolean,
        showDoneButton?: boolean,
        showTryAgainButton?: boolean,
        showLoadingIcon?: boolean,
        showTryAgainLink?: boolean,
    }

    export default {
        name: 'ExportingStatusModal',

        components: {
            OwButton,
            OwModal,
            OwProgress,
        },

        props: {
            modelValue: {
                type: Boolean,
                required: false,
            },
            ordering: {
                type: Boolean,
                required: false,
            },
            exporting: {
                type: Boolean,
                required: true,
            },
            error: {
                type: Boolean,
                default: false,
                required: false,
            },
            loadingTimeout: {
                type: Number,
                required: false,
                default: 5000,
            },
            isAsyncExporting: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        emits: [
            EVENTS.Input,
            EVENTS.Retry,
        ],

        data() {
            return {
                loadingTimeoutObj: null,
                showCarryOnAndKeepWaitingMsg: false,
            }
        },

        computed: {
            showModal: {
                get(): boolean {
                    return this.modelValue
                },
                set(isVisible: boolean): void {
                    this.$emit(EVENTS.Input, isVisible)
                },
            },

            modalContent(): IModalContent {
                if (this.error) {
                    return {
                        title: this.$t('report.modals.failure.title'),
                        text: this.$t('report.modals.failure.text'),
                        icon: '$warning-octagon',
                        isFailure: true,
                        showCancelButton: true,
                        showTryAgainButton: true,
                        showTryAgainLink: false,
                    }
                } else if (this.ordering || this.exporting) {
                    return {
                        title: this.$t('report.modals.generating.title'),
                        text: this.exporting
                            ? this.$t('report.modals.generating.text')
                            : this.$t('report.modals.ordering.text'),
                        icon: null,
                        isFailure: false,
                        showLoadingIcon: true,
                        showDoneButton: true,
                        showTryAgainLink: true,
                    }
                }

                return {
                    title: this.isAsyncExporting ? this.$t('report.modals.asyncGeneration.title') : this.$t('report.modals.success.title'),
                    text: this.isAsyncExporting ? this.$t('report.modals.asyncGeneration.text') : this.$t('report.modals.success.text'),
                    icon: '$success-circle',
                    isSuccess: true,
                    showLoadingIcon: false,
                    showDoneButton: true,
                    showTryAgainLink: !this.isAsyncExporting,
                }
            },

            isButtonOrLinkDisabled(): boolean {
                return this.ordering || this.exporting
            },
        },

        watch: {
            exporting(isExporting): void {
                if (isExporting === true) {
                    this.resetLoadingTimeoutTimer()
                } else {
                    this.showCarryOnAndKeepWaitingMsg = false
                    this.clearLoadingTimeoutTimer()
                }
            },
        },

        beforeUnmount() {
            this.clearLoadingTimeoutTimer()
        },

        methods: {
            retryHandler(): void {
                // If already processing a request, then don't trigger an new one.
                if (!this.isButtonOrLinkDisabled) {
                    this.$emit(EVENTS.Retry)
                }
            },

            clearLoadingTimeoutTimer() {
                if (this.loadingTimeoutObj) {
                    clearTimeout(this.loadingTimeoutObj)
                }
            },

            resetLoadingTimeoutTimer() {
                this.showCarryOnAndKeepWaitingMsg = false

                this.clearLoadingTimeoutTimer()
                this.loadingTimeoutObj = setTimeout(() => {
                    this.showCarryOnAndKeepWaitingMsg = true
                }, this.loadingTimeout)
            },
        },
    }
</script>

<style lang="scss">
    @import './exporting-status-modal';
</style>
