import { Region } from "@/consts/region"
import { ssoOptions } from '@/consts/sso-types'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const selectedOrganisationInitialState = {
    name: null,
    maxUsers: 10,
    maxPremiumUsers: 0,
    maxStandardUsers: 10,
    maxCopilotUsers: 0,
    pricingModelId: null,
    showDialog: false,
    sectionName: 'Main office',
    sectionAddressHouseNumber: null,
    sectionAddressStreet: null,
    sectionAddressLocality: null,
    sectionAddressTown: null,
    sectionAddressPostcode: null,
    sections: [],
    chargesEmails: null,
    usesClientCodes: true,
    mandatoryMatterCodes: false,
    preferChargesCSVWithoutHeader: false,
    preferChargesLandRegistryCSVFormat: false,
    preferChargesUserFullNameRatherThanEmail: false,
    preferChargesCSVMatterCodeSeparator: '/',
    ppcStandardCharge: null,
    hasTitleSummaryCharge: true,
    titleSummaryCharge: 3,
    pmpmMatterCharge: 50,
    copilotPricingModel: "Credit based",
    ssoOption: ssoOptions.SSO_DISABLED,
    ssoProviderId: null,
    validEmailDomains: [],
    enableFeatureHideableMatterSidePanel: false,
    industry: '',
    status: '',
    matterCodeFormat: null,
    matterCodeMask: null,
    clientCodeFormat: null,
    canShareMattersWithWholeOrg: false,
    products: [],
    regions: [Region.UK],
    defaultLocale: 'en-GB',
    grandfatheredModules: [],
}

const state = {
    list: [],
    profile: {
        id: null,
        name: null,
        maxUsers: 0,
        allocatedUsers: 0,
        sections: [],
    },
    pricingModels: [],
    availableProducts: [],
    selectedOrganisation: selectedOrganisationInitialState,
    selectedOrganisationSection: {},
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
