<template>
    <v-dialog id="popupWarningForm"
              v-model="show"
              max-width="540">
        <v-card>
            <v-card-title class="text-h5">
                Warning - You do not have pop-ups enabled
            </v-card-title>

            <v-card-text>
                <p>Orbital Witness attempted to open a link to provide you with a PDF or other resource, but you do not have popups enabled in your browser.</p>
                <img src="../media/popupwarning.webp" />
                <p>Please enable popups and try again to resolve this issue. You can do this by clicking the icon in the address bar as shown above and then select "Always allow popups...".</p>
            </v-card-text>
            <ow-card-actions justify="start"
                             @primary-button-click="close()" />
        </v-card>
    </v-dialog>
</template>
<script>
    import * as types from '../store/mutation-types'
    import OwCardActions from './core/ow-card-actions.vue'

    export default {
        name: 'Popupsblocked',
        components: { OwCardActions },

        computed: {
            show: {
                get: function() {
                    return this.$store.state.user.showWarnPopupsBlocked
                },
                set: function(val) {
                    if (val == false) {
                        this.$store.dispatch(types.HIDE_POPUP_BLOCKED_WARNING)
                    }
                },
            },
        },

        methods: {
            close: function() {
                this.$store.dispatch(types.HIDE_POPUP_BLOCKED_WARNING)
            },
        },
    }
</script>
<style lang="scss">
    @import '../css/_variables.scss';
</style>
