import { Geometry } from 'ol/geom'
import {
    Fill,
    Icon,
    Stroke,
    Style,
    Text,
} from 'ol/style'

import { SketchType } from '@/enums/sketches-enums'
import { IOwStyle } from '@/store/modules/sketches/types/style'
import { hexToRGBArray } from '@/utils/colour-utils'
import {
    addArrowheads,
    getDefaultStyle,
    getLineStringsFromGeometry,
    getSketchMarkerPinSVG,
} from '@/utils/sketch-utils'

export enum StyleTarget{
    VectorTile,
    Boundary,
    Marker,
    Text,
    Line,
    Area = 5,
}

export const getTextStyleForOWStyleDefinition = (source: any, highlight = false) => {
    const style = new Style()

    const fontSizes = [12, 14, 16, 20, 24, 30]
    const offsetY = [-8, -8, -9, -10, -9, -8]
    const strokeWidth = [6, 7, 8, 10, 11, 12]
    const rgb = hexToRGBArray(source?.strokeColour)
    const hasBackground = source.fillOpacity !== 0

    style.setText(new Text({
        font: `${ fontSizes[source?.strokeWidth - 1] + 'px' } 'IBM Plex Sans'`,
        textBaseline: 'bottom',
        justify: 'left',
    }))

    if (hasBackground) {
        style.getText().setFill(new Fill({
            color: highlight ? 'rgb(0, 0, 0)' : source.fillColour,
        }))
        style.getText().setBackgroundFill(new Fill({
            color: highlight ? 'rgb(255, 255, 255)' : `rgba(${ rgb.toString() },1)`,
        }))
        style.getText().setOffsetY(-7)
        style.getText().setPadding([2, 4, 0, 6])
    } else {
        style.getText().setStroke(new Stroke({
            color: highlight ? 'rgb(255, 255, 255)' : `rgba(${ rgb.toString() },1)`,
            width: strokeWidth[source?.strokeWidth - 1],
            lineCap: 'round',
        }))
        style.getText().setFill(new Fill({
            color: highlight ? 'rgb(0, 0, 0)' : source.fillColour,
        }))
        style.getText().setOffsetY(offsetY[source?.strokeWidth - 1])
    }
    style.getText().setText(`${ source.name }`)
    style.setZIndex(source.zIndex)
    return style
}

const getLineStyleForOWStyleDefinition = (source: any, highlight = false): Style[] | null => {
    const styles = []
    source?.features?.forEach((feature) => {
        const geometry = feature.getGeometry()
        const lines = getLineStringsFromGeometry(geometry as Geometry)
        lines.forEach((line) => {
            const segmentCount = line.getCoordinates().length - 1
            let segmentIndex = 0
            line.forEachSegment((a, b) => {
                addArrowheads(a, b, segmentIndex, segmentCount, source, highlight).forEach((arrowheadStyle) => {
                    styles.push(arrowheadStyle)
                })
                segmentIndex++
            })
        })
    })
    return styles
}

/**
 * Returns an OpenLayers Style for a given title/sketch.
 * @param {Object} source - title/sketch style metadata.\
 * @param {StyleTarget} targetType - the type of feature the style is applied to.
 */
export const getOLStyleForOWStyleDefinition = (source?: any, targetType: StyleTarget = StyleTarget.Boundary, highlight = false): Style | Style[] => {
    if (!source || source?.show === false) {
        return null
    }

    // create a default style
    const defaultStyle = getDefaultStyle(source, targetType, highlight).clone()

    switch (targetType) {
        case StyleTarget.Marker: {
            defaultStyle.setImage(new Icon(({
                src: `data:image/svg+xml;utf8,${ getSketchMarkerPinSVG(source.strokeColour, source.strokeWidth, true, highlight) }`,
                scale: 1,
                anchor: [0.5, 0.9],
            })))
            break
        }
        case StyleTarget.Text:
            return getTextStyleForOWStyleDefinition(source, highlight)
        case StyleTarget.Line:
            return [...getLineStyleForOWStyleDefinition(source, highlight), defaultStyle]
    }
    return defaultStyle
}

/**
 * Returns a CSS style object for use in Vue templates based on an OW style definition e.g. from a title.
 * @param {Object} source - OW style definition.
 */
export const getCSSStyleForOWStyleDefinition = (source: any, type: SketchType) => {
    const result:any = {}
    const fillColourRbgArray = hexToRGBArray(source?.colour ?? source?.fillColour ?? source.strokeColour)
    const strokeColour = hexToRGBArray(source?.strokeColour ?? source?.colour ?? source.fillColour)
    const borderStyle = (source.dashed || source.dash) ? 'dashed' : 'solid'

    switch (type) {
        case SketchType.Text:
            result.border = `${ source.strokeWidth / 2 }px solid rgb(${ strokeColour.toString() })`
            result.background = `rgb(${ strokeColour.toString() })`
            result.color = `rgb(${ fillColourRbgArray.toString() })`
            break
        default: {
            result.border = `${ source.strokeWidth / 2 }px ${ borderStyle } rgb(${ strokeColour.toString() })`

            let fillColour
            if (source.fillOpacity !== null) {
                fillColour = `rgba(${ fillColourRbgArray.toString() },${ source.fillOpacity })`
            } else {
                fillColour = `rgb(${ fillColourRbgArray.toString() })`
            }

            // Background
            if (source.fill === true || (source.fillColour && source.fillOpacity)) {
                if (source.hatch === true) {
                    result.background = `repeating-linear-gradient(150deg, ${ fillColour }, ${ fillColour } 2px, #fff 2px, #fff 5px)`
                } else {
                    result.background = fillColour
                }
            }
        }
    }
    return result
}

/** Returns true if the two OW style definitions are equal. */
export const owStyleDefinitionsAreEqual = (style1: Partial<IOwStyle>, style2: Partial<IOwStyle>, sketchType: SketchType = SketchType.Area) => {
    switch (sketchType) {
        case SketchType.Line:
            return (style1?.strokeColour === style2?.strokeColour &&
                    style1?.strokeWidth === style2?.strokeWidth &&
                    style1?.dash === style2?.dash &&
                    style1?.arrowhead === style2?.arrowhead)
        case SketchType.Marker:
            return (style1?.strokeColour === style2?.strokeColour &&
                    style1?.strokeWidth === style2?.strokeWidth)
        case SketchType.Text:
            return (style1?.strokeColour === style2?.strokeColour &&
                    style1?.strokeWidth === style2?.strokeWidth &&
                    style1?.fillOpacity === style2?.fillOpacity &&
                    style1?.fillColour === style2?.fillColour)
        default:
            return (style1?.strokeColour === style2?.strokeColour &&
            style1?.strokeWidth === style2?.strokeWidth &&
            style1?.fillColour === style2?.fillColour &&
            style1?.fillOpacity === style2?.fillOpacity &&
            style1?.hatch === style2?.hatch &&
            !!style1?.dash === !!style2?.dash)
    }
}
