<template>
    <div id="map-snapshot-renderer"
         ref="containerRef"
         class="map-snapshot-renderer">
        <div v-if="showTitleArea"
             ref="titleAreaEl"
             class="map-snapshot-title-area"
             data-test="map-snapshot-title-area"
             :style="titleAreaStyle">
            <ow-textfield v-if="showTitle"
                          v-model="service.snapshotModel.renderData.title"
                          class="map-snapshot-title"
                          data-test="map-snapshot-title"
                          :placeholder="$t('snapshots.title.titleEditPrompt')"
                          :borderless="true" />
            <ow-textfield v-if="showSubTitle"
                          v-model="service.snapshotModel.renderData.subtitle"
                          class="map-snapshot-subtitle"
                          :placeholder="$t('snapshots.title.subtitleEditPrompt')"
                          :style="subTitleStyle"
                          data-test="map-snapshot-subtitle"
                          :borderless="true" />
        </div>
        <ow-snapshot-map :layers="owLayers"
                         :initial-view="initialView"
                         @map-initialised="onMapInitialised"
                         @map-post-render="onPostrender">
            <img src="@/media/snapshot-watermark.webp"
                 class="map-watermark"
                 alt="watermark" />
            <map-watermark id="snapshotWatermark"
                           :style="watermarkStyle" />
            <map-north-arrow :target-map="props.service.targetMap" />
            <map-snapshot-scale-text v-if="showScaleText"
                                     :service="props.service" />
            <map-key-renderer v-if="showKey"
                              ref="keyEl"
                              :parent-element-selector="'.ow-snapshot-map'"
                              :config="props.service.keyConfig"
                              :scaling-multiplier="currentLayout.graphicSizeMultiplier" />
        </ow-snapshot-map>
    </div>
</template>
<script setup lang="ts">
    import { debounce } from 'lodash'
    import { ScaleLine } from 'ol/control'
    import OlMap from 'ol/Map'
    import {
        computed,
        nextTick,
        onMounted,
        ref,
        watch,
    } from 'vue'
    import { useStore } from "vuex"

    import { CoreMapView } from '@/components/core/maps/core-map-view.interface'
    import OwTextfield from "@/components/core/ow-textfield.vue"
    import MapNorthArrow from '@/components/map/map-north-arrow.vue'
    import MapWatermark from "@/components/map/map-watermark.vue"
    import {
        PageLayouts,
        SnapshotMapMaxZoom,
    } from '@/components/snapshots/common/snapshot-enums-consts'
    import { IMapSnapshotLayer } from '@/components/snapshots/map-snapshots/map-snapshot-interfaces'
    import { MapSnapshotRenderData } from '@/components/snapshots/map-snapshots/map-snapshot-models'
    import { MapSnapshotService } from '@/components/snapshots/map-snapshots/map-snapshot-service'
    import MapKeyRenderer from '@/components/snapshots/map-snapshots/render-components/map-key-renderer.vue'
    import MapSnapshotScaleText from '@/components/snapshots/map-snapshots/render-components/map-snapshot-scale-text.vue'
    import OwSnapshotMap from '@/components/snapshots/map-snapshots/render-components/ow-snapshot-map.vue'
    import { SUPPORTED_MAP_PROJECTIONS } from '@/consts/map-projections'
    import { initBritishNationalGridProjection } from '@/utils/map-utils'

    interface Props {
        service: MapSnapshotService
    }
    const props = defineProps<Props>()
    const owLayers = ref<Array<IMapSnapshotLayer>>([])
    const initialView = ref<CoreMapView>(null)
    const map = ref<OlMap>()
    const keyEl = ref(null)
    const titleAreaEl = ref(null)
    const store = useStore()
    const titleAreaStyle = ref<Partial<CSSStyleDeclaration>>({})
    const subTitleStyle = ref<Partial<CSSStyleDeclaration>>({})
    const containerRef = ref<HTMLElement | null>(null)

    onMounted(() => {
        props.service.isLoadingContent.value = true
        initBritishNationalGridProjection()
        initialView.value = {
            extent: SUPPORTED_MAP_PROJECTIONS.EPSG27700.extent,
        }
    })
    const updateKey = debounce(() => {
        props.service.updateKey(map.value)
    }, 250)

    const onPostrender = () => {
        updateKey()
    }

    const onMapInitialised = (loadedMap: OlMap) => {
        props.service.setTargetMap(loadedMap)
        map.value = loadedMap
        owLayers.value = MapSnapshotService.getOWMapLayersFromSnapshotConfig(
            renderData.value.layers,
            map.value,
            store.state.config.settings,
        )

        map.value.getView().fit(renderData.value.extent)
        owLayers.value.forEach(async (x) => {
            await x.setVisible(true, map.value)
        })
        applyCurrentLayout()
        props.service.targetMap.updateSize()

        // Temporary fix to disable zoom levels that tiled layers from GeoServer are not displayed correctly at.
        map.value.getView().setMaxZoom(SnapshotMapMaxZoom)
    }

    const renderData = computed<MapSnapshotRenderData>(() => props.service.snapshotModel.renderData as MapSnapshotRenderData)

    const showTitleArea = computed(() => {
        return renderData.value?.showTitle || renderData.value?.showSubtitle
    })
    const showTitle = computed(() => {
        return renderData.value?.showTitle
    })
    const showSubTitle = computed(() => {
        return renderData.value?.showSubtitle
    })

    const layersLoaded = computed(() => {
        return owLayers.value.some(x => x.getIsLoading())
    })

    const currentLayout = computed(() => {
        return PageLayouts[props.service.snapshotModel.renderData.layoutId]
    })

    // When the layers have finished loading, update the service and redraw the key.
    watch(layersLoaded, async (loaded: boolean) => {
        if (loaded) {
            props.service.isLoadingContent.value = false
        }
    })

    // When the layout changes, pass the information to various elements that may require changes to their presentation.
    const applyCurrentLayout = async () => {
        await nextTick()
        const layout = PageLayouts[props.service.snapshotModel.renderData.layoutId]
        // Map layers
        for (const x of owLayers.value) {
            if (x.setSnapShotLayout) {
                x.setSnapShotLayout(layout)
            }
        }

        // Some OL map controls need adjusting
        if ((props.service.snapshotModel.renderData as MapSnapshotRenderData).includeScaleBar) {
            const controls = props.service.targetMap.getControls().getArray() as Array<any>
            let scaleLine = controls.find(control => control instanceof ScaleLine)
            if (scaleLine) {
                props.service.targetMap.removeControl(scaleLine)
                scaleLine = new ScaleLine({
                    minWidth: containerRef.value.clientWidth * 0.2,
                    maxWidth: containerRef.value.clientWidth * 0.3,
                    bar: true,
                })
                props.service.targetMap.addControl(scaleLine)
            }
        }

        props.service.targetMap.updateSize()
    }
    watch(() => props.service.snapshotModel.renderData.layoutId, applyCurrentLayout)

    const showKey = computed(() => Boolean(props.service.keyConfig.items.length &&
        props.service.targetMap &&
        (props.service.snapshotModel.renderData as MapSnapshotRenderData).includeKey))

    watch(() => (props.service.snapshotModel.renderData as MapSnapshotRenderData).includeKey, val => {
        updateKey()
    })

    watch(() => (props.service.snapshotModel.renderData as MapSnapshotRenderData).includeScaleBar, val => {
        if (val) {
            const scaleLine = new ScaleLine({
                minWidth: containerRef.value.clientWidth * 0.2,
                maxWidth: containerRef.value.clientWidth * 0.3,
                bar: true,
            })
            props.service.targetMap.addControl(scaleLine)
        } else {
            const controls = props.service.targetMap.getControls().getArray() as Array<any>
            const scaleLine = controls.find(control => control instanceof ScaleLine)
            if (scaleLine) {
                props.service.targetMap.removeControl(scaleLine)
            }
        }
    })

    const watermarkStyle = computed(() => {
        const hasScaleBar = (props.service.snapshotModel.renderData as MapSnapshotRenderData).includeScaleBar
        const hasScaleText = (props.service.snapshotModel.renderData as MapSnapshotRenderData).includeToScale
        if (hasScaleBar) {
            return {
                bottom: '6em',
            }
        } else if (hasScaleText) {
            return {
                bottom: '10em',
            }
        } else {
            return { bottom: '2em' }
        }
    })

    const showScaleText = computed(() => {
        return (props.service.snapshotModel.renderData as MapSnapshotRenderData).includeToScale !== null
    })

    defineExpose({
        applyCurrentLayout,
        showKey,
        owLayers,
        watermarkStyle,
        showScaleText,
        showTitle,
        showTitleArea,
        renderData,
    })
</script>
<style lang="scss">
    @import 'map-snapshot-renderer.scss';
</style>
