<template>
    <v-app id="ow-app"
           :class="appClass"
           class="ow-app">
        <main v-if="!showLoadingIndicator">
            <!-- All modals and popups -->
            <app-dialogs />

            <app-global-nav />

            <v-container id="mainContainer"
                         :class="{inset: $route.meta.admin || $route.meta.orgAdmin }"
                         fluid>
                <router-view v-slot="{ Component }">
                    <keep-alive :exclude="componentsToExcludeFromKeepAlive">
                        <component :is="Component" />
                    </keep-alive>
                </router-view>
            </v-container>

            <ow-snackbar v-model="isReloadSnackbarVisible">
                <section class="snackbar__content">
                    <h3>{{ $t('newAppVersion') }}</h3>
                    <p>{{ $t('newAppVersionMessage') }}</p>
                </section>
                <template #actions>
                    <div class="snackbar__buttons">
                        <a class="button-large"
                           data-track="APP VERSION CHANGED - Reload"
                           href=""
                           @click.self="reloadApp">
                            {{ $t('reloadApp') }}
                        </a>
                    </div>
                </template>
            </ow-snackbar>

            <app-notifications-toaster :heading="notificationToaster?.heading"
                                       :is-error="notificationToaster?.isError ?? false"
                                       :is-in-progress="notificationToaster?.isInProgress ?? false"
                                       :is-success="notificationToaster?.isSuccess ?? false"
                                       :is-warning="notificationToaster?.isWarning ?? false"
                                       :message="notificationToaster?.message ?? ''"
                                       :timeout-in-milliseconds="notificationToaster?.timeoutInMilliseconds ?? 5000"
                                       :model-value="notificationToaster?.isVisible ?? false"
                                       @update:model-value="updateNotificationToaster" />
        </main>

        <!-- Loading indicator -->
        <main v-else>
            <ow-loading-slot loading />
        </main>
    </v-app>
</template>

<script lang="ts">
    import { provide } from "vue"
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwIconOwLogo from '@/components/core/icons/ow-icon-ow-logo.vue'
    import OwLoadingSlot from "@/components/core/ow-loading-slot.vue"
    import OwSnackbar from '@/components/core/ow-snackbar.vue'
    import AppDialogs from '@/components/dialogs/dialog-root.vue'
    import GlobalNav from '@/components/global-nav/global-nav.vue'
    import NotificationsToaster from '@/components/notifications/toaster.vue'
    import { provide as provideLicencing } from "@/composables/use-licence-controller"
    import { provide as provideTitlePanel } from "@/composables/use-title-panel"
    import { provide as provideUser} from "@/composables/use-user"
    import { Route } from '@/enums/route.enum'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin.js'
    import { IState } from '@/interfaces/store/state.interface'
    import PageTitleMixin from '@/mixins/page-title.mixin.js'
    import { AppVersion } from '@/models/app-version.model'
    import {
        AUTH_GET_IS_AUTHENTICATED,
    } from '@/store/modules/auth-oidc/types'
    import { RELOAD } from '@/store/modules/config/types'
    import * as diagnosticsTypes from '@/store/modules/diagnostics/types'
    import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '@/store/modules/link-share-client/types'
    import { NOTIFICATIONS_MUTATE_CLEAR_TOASTER } from '@/store/modules/notifications/types'
    import { SUBSCRIBE_TO_ORGANISATION_HUB } from '@/store/modules/organisation-hub/types'
    import {
        LOGGING_HEAP_RESET_IDENTITY,
        LOGGING_INITIALISE_ALL,
        USER_INITIATE_STATUS_UPDATES,
        USER_SHOW_POPUP,
    } from '@/store/mutation-types'

    import usePermissionsNotification from "./composables/use-permissions-notification"

    interface IAppData {
        isReloadSnackbarVisible: boolean,
        checkForNewAppVersionInterval: number,
        newAppVersionIntervalTracker: any,
    }

    export default {
        name: 'AppTest',

        components: {
            OwLoadingSlot,
            AppDialogs,
            appGlobalNav: GlobalNav,
            appNotificationsToaster: NotificationsToaster,
            OwSnackbar,
            OwIconOwLogo,
        },

        mixins: [
            FeatureFlagsMixin,
            PageTitleMixin,
        ],

        setup() {
            provideUser()
            provideLicencing()
            provideTitlePanel()
            usePermissionsNotification()
        },

        data(): IAppData {
            return {
                isReloadSnackbarVisible: false,
                checkForNewAppVersionInterval: 300000, // time in milliseconds (5 mins)
                newAppVersionIntervalTracker: null,
            }
        },

        computed: {
            ...mapState({
                getDebugModeEnabled: (state: IState) => state.diagnostics.debugModeEnabled,
                loadedUserProfile: (state: IState) => state.user.profileLoaded,
                matterLoading: (state: IState) => state.matter.loading,
                loadedWalkthrough: (state: IState) => state.walkthrough.walkthrough !== null,
                notificationToaster: (state: IState) => state.notifications?.toaster,
                organisationId: (state: IState) => state.user.organisationId,
            }),

            ...mapGetters({
                isAuthenticated: AUTH_GET_IS_AUTHENTICATED,
            }),

            ...mapGetters('linkShareClient', {
                isSharedLinkView: LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW,
            }),

            promptLoadLatestVersion(): boolean {
                return this.promptToLoadLatestVersion
            },

            forceLoadLatestVersion(): boolean {
                return this.shouldForceLoadLatestVersion
            },

            debugModeEnabled: {
                get(): boolean {
                    return this.shouldAllowDebugMode &&
                        this.getDebugModeEnabled
                },

                set(val: boolean) {
                    if (this.$store) {
                        this.mutateDebugMode(val)
                    }
                },
            },

            showLoadingIndicator(): boolean {
                // If route doesn't require authentication, then don't bother with the loader, just show the page
                const doesRouteRequireAuth = ('requiresAuth' in this.$route?.meta) ? this.$route.meta.requiresAuth : true
                if (!doesRouteRequireAuth) {
                    return false
                }

                // Has the user been authenticated? - i.e. logged in/accessing via a link share.
                const notAuthed = !this.isAuthenticated ||
                    !this.loadedUserProfile

                // Is the user on the landing page for a shareable link?
                const notLinkShareLandingPage = this.$route.name !== Route.LinkShareLanding &&
                    this.$route.name !== Route.Snapshot

                // Is the read-only walkthrough loading?
                const loadingWalkthroughPresentation = this.matterLoading &&
                    this.isSharedLinkView &&
                    this.$route.name === Route.MatterMapWalkthrough &&
                    !this.loadedWalkthrough

                return (notAuthed || loadingWalkthroughPresentation) && notLinkShareLandingPage
            },

            appClass(): { walkthrough: boolean, 'is-shared-link-view': boolean } {
                return {
                    walkthrough: Boolean(this.$route.name?.includes('walkthrough')),
                    'is-shared-link-view': this.isSharedLinkView,
                }
            },
            componentsToExcludeFromKeepAlive(): string {
                return [
                    'CheckoutProcessing',
                    'DocumentLibraryPage',
                    'DocumentViewer',
                    'ReportingSelectTitles',
                    'Reports',
                    'ReviewAssistantPage',
                    'TitleDetailsPage',
                    'SnapshotAssistant',
                    'SearchDocumentViewer',
                    'MatterSearches',
                    'MatterSearchesCreate',
                    'MatterSearchesSelectProductStep',
                    'MatterSearchesDefinePolygonStep',
                    'LeaseHierarchy',
                    'CompanyGroupsCreate',
                    'AssetMonitoring',
                    'TitleAnalysisPage',
                    'Summary',
                ].join(',')
            },
        },

        watch: {
            isShutDownEverythingEnabled(val): void {
                if (val) {
                    window.location.replace('https://www.orbitalwitness.com/orbital-witness-down')
                }
            },

            async isAuthenticated(val): Promise<void> {
                if (val === true) {
                    await this.initialiseApp()
                } else {
                    await this.resetHeapIdentity()
                }
            },

            async promptLoadLatestVersion(newVal, oldVal): Promise<void> {
                if (newVal === true && oldVal !== undefined) {
                    if (AppVersion.currentAppVersion !== await AppVersion.getLatestAppVersion()) {
                        this.showReloadToNewVersionPopup(async () => {
                            await AppVersion.reloadToLatestAppVersion()
                        })
                    }
                }
            },

            async forceLoadLatestVersion(newVal, oldVal): Promise<void> {
                if (newVal === true && oldVal !== undefined) {
                    if (AppVersion.currentAppVersion !== await AppVersion.getLatestAppVersion()) {
                        await AppVersion.reloadToLatestAppVersion()
                    }
                }
            },

            shouldAllowDebugMode(): void {
                this.refreshDebugModeState()
            },
        },

        mounted(): void {
            // open reload prompt if unexisting chunk is trying to be fetched
            window.addEventListener('error', e => {
                const chunkLoadErrorString = 'ChunkLoadError: Loading chunk'
                if (e?.message.includes(chunkLoadErrorString)) {
                    this.showReloadToNewVersionPopup(() => {
                        window.location.reload()
                    })
                }
            })
        },

        methods: {
            ...mapActions('config', {
                loadSettings: RELOAD,
            }),

            ...mapActions('organisationHub', {
                subscribeToOrganisationHub: SUBSCRIBE_TO_ORGANISATION_HUB,
            }),

            ...mapActions({
                initialiseLogging: LOGGING_INITIALISE_ALL,
                initiateUserStatusUpdates: USER_INITIATE_STATUS_UPDATES,
                resetHeapIdentity: LOGGING_HEAP_RESET_IDENTITY,
                showUserPopup: USER_SHOW_POPUP,
            }),

            ...mapMutations('diagnostics', {
                mutateDebugMode: diagnosticsTypes.DIAGNOSTICS_MUTATE_DEBUG_MODE,
            }),

            ...mapMutations({
                clearNotificationsToaster: NOTIFICATIONS_MUTATE_CLEAR_TOASTER,
            }),

            showReloadToNewVersionPopup(okCallback): void {
                this.showUserPopup({
                    title: this.$t('newAppVersion'),
                    contentHTML: this.$t('newAppVersionMessage'),
                    persistent: true,
                    testName: 'new-app-version-prompt',
                    showCancelButton: true,
                    okText: this.$t('reloadApp'),
                    okCallback,
                })
            },

            async initialiseApp(): Promise<void> {
                await this.loadSettings()

                // User logged in, config loaded, profile loaded, start logging
                await this.initialiseLogging()
                await this.initiateUserStatusUpdates()

                await this.subscribeToOrganisationHub({
                    hub: this.$organisationNotifications,
                    organisationId: this.organisationId,
                })

                this.cancelAppVersionCheck()
                this.newAppVersionIntervalTracker = setInterval(this.checkAppVersion, this.checkForNewAppVersionInterval)
            },

            refreshDebugModeState(): void {
                let enableDebugMode = false
                // Enables / disabled debug mode based on current context - feature flags, URL params
                if (this.shouldAllowDebugMode === true) {
                    // Enabled / disabled via URL param?
                    const urlSearchParams = new URLSearchParams(window.location.search)
                    if (urlSearchParams.get('debug') !== null) {
                        enableDebugMode = urlSearchParams.get('debug') === 'true'
                    } else if (localStorage.getItem('debugMode') === 'true') {
                        enableDebugMode = true
                    }
                }
                this.debugModeEnabled = enableDebugMode
            },

            async reloadApp(): Promise<void> {
                await AppVersion.reloadToLatestAppVersion()
                this.isReloadSnackbarVisible = false
            },

            async checkAppVersion(): Promise<void> {
                const latestAppVersion = await AppVersion.getLatestAppVersion()
                if (AppVersion.hasAppVersionChanged(latestAppVersion)) {
                    this.isReloadSnackbarVisible = false /// RW-286: Disabling the popup for now. Will appear in notification panel soon.
                }
            },

            cancelAppVersionCheck(): void {
                if (this.newAppVersionIntervalTracker) {
                    clearInterval(this.newAppVersionIntervalTracker)
                }
                this.isReloadSnackbarVisible = false
            },

            updateNotificationToaster(isVisible: boolean): void {
                if (!isVisible) {
                    this.clearNotificationsToaster()
                }
            },
        },
    }
</script>

<style lang="scss">
    @import './App';
</style>
