<template>
    <matter-side-panel-add-titles-option import-method="shape"
                                         :current-selected="modelValue"
                                         :title="$t('matter.addTitlesPanel.uploadShapeFile.heading')"
                                         data-test="matter-add-titles-shapefile"
                                         @select="$emit('update:modelValue', 'shape')">
        <span v-t="'matter.addTitlesPanel.uploadShapeFile.prompt'"
              class="caption-regular" />

        <ow-checkbox id="add-title-store-sketch"
                     v-model="saveAsSketch"
                     :label="$t('matter.addTitlesPanel.drawOnMap.saveAsSketch')"
                     data-test="matter-add-titles-store-sketch-checkbox" />
        <div>
            <ow-checkbox id="import-only-within"
                         v-model="importOnlyWithinTitles"
                         :label="$t('matter.addTitlesPanel.uploadShapeFile.onlyWithin')"
                         data-test="matter-add-titles-within-checkbox"
                         style="margin-bottom: 10px" />
            <ow-tooltip activator="parent"
                        :position="OwTooltipPosition.Right">
                {{ $t('matter.addTitlesPanel.uploadShapeFile.onlyWithinTooltip') }}
            </ow-tooltip>
        </div>

        <ow-button is-secondary
                   small
                   data-test="upload-shape-file-upload-file-button"
                   @click="setUploadModalVisible(true)">
            {{ $t('matter.addTitlesPanel.uploadShapeFile.uploadButton') }}
        </ow-button>

        <ow-file-uploader :accepted-file-types="[UploadFileTypes.KML,
                                                 UploadFileTypes.SHP,
                                                 UploadFileTypes.KMZ,
                                                 UploadFileTypes.GEOJSON]"
                          :accepted-file-extensions="[UploadFileExtensions.KML,
                                                      UploadFileExtensions.SHP,
                                                      UploadFileExtensions.KMZ,
                                                      UploadFileExtensions.GEOJSON,
                                                      UploadFileExtensions.JSON]"
                          :min-file-size="200"
                          :data-test-attribute="'sketches-list-import-modal'"
                          i18n-path-for-accepted-file-types-error="matter.addTitlesPanel.uploadShapeFile.import.acceptedFileTypeError"
                          i18n-path-for-drop-zone-text="matter.addTitlesPanel.uploadShapeFile.import.dropZoneText"
                          i18n-path-for-text="matter.addTitlesPanel.uploadShapeFile.import.instructions"
                          i18n-path-for-title="matter.addTitlesPanel.uploadShapeFile.import.uploadFiles"
                          @files="fileInputChange" />

        <span v-if="spatialFileImportMetadata"
              class="caption-regular">
            Uploaded File: {{ spatialFileImportMetadata?.filename }}
        </span>

        <add-to-group v-model="selectedGroup" />

        <ow-button :disabled="!spatialFileImportWkt"
                   data-test="add-titles-from-shapefile"
                   is-primary
                   small
                   @click="importTitles">
            Add Titles from Shapefile
        </ow-button>
    </matter-side-panel-add-titles-option>
</template>

<script setup lang="ts">
    import JSZip, {JSZipObject} from 'jszip'
    import {easeOut} from 'ol/easing'
    import {extend,
            getHeight,
            getWidth} from 'ol/extent'
    import Feature from 'ol/Feature'
    import {KML,
            WKT} from 'ol/format'
    import GeoJSON from 'ol/format/GeoJSON'
    import Geometry from 'ol/geom/Geometry'
    import VectorLayer from 'ol/layer/Vector'
    import VectorSource from 'ol/source/Vector'
    import {getArea} from 'ol/sphere'
    import {Stroke} from 'ol/style'
    import CircleStyle from 'ol/style/Circle'
    import Style from 'ol/style/Style'
    import shp from 'shpjs'
    import {computed,
            onBeforeUnmount,
            ref,
            watch} from 'vue'
    import {useStore} from "vuex"

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import OwFileUploader from '@/components/core/ow-file-uploader.vue'
    import OwTooltip from "@/components/core/ow-tooltip.vue"
    import {IOwFileUploaderEvent} from '@/components/core/types/ow-file-uploader-event.interface'
    import {CoordinateSystemCode} from '@/enums/coordinate-systems'
    import {OwTooltipPosition} from "@/enums/ow-tooltip-position"
    import {SketchSource} from '@/enums/sketches-enums'
    import {UploadFileExtensions,
            UploadFileTypes} from '@/enums/upload-file-types.enum'
    import {SET_UPLOAD_DOCUMENTS_DIALOG_STATE} from "@/store/modules/documents/documents-types"
    import {MATTER_IMPORT_FROM_WKT_AREA,
            MATTER_REQUEST_SHOW_TITLE_LIST} from '@/store/modules/matter/types'
    import {TitlePanels} from '@/store/modules/title-panels'
    import {TITLE_MUTATE_ACTIVE_TITLE_PANEL} from '@/store/modules/titles/types'
    import {LOGGING_DATADOG_LOG_INFO_EVENT,
            LOGGING_HEAP_TRACK_EVENT,
            LOGGING_LOG_FEATURE_USAGE} from '@/store/mutation-types'
    import {openLayersFeaturesFromGeoJSON} from "@/utils/spatial-io"

    import AddToGroup, {GroupItem} from './add-to-group.vue'
    import MatterSidePanelAddTitlesOption from './matter-side-panel-add-titles-option.vue'
    import {useStoreSketch} from './use-store-sketch'

    const props = defineProps<{modelValue: string}>()
    defineEmits<{
        (e: 'update:modelValue', importMethod: string)
    }>()

    const store = useStore()

    const selectedGroup = ref<GroupItem | undefined>()

    const drawLayer = ref<VectorLayer<VectorSource<Geometry>> | null>(null)

    const saveAsSketch = ref(true)

    const importOnlyWithinTitles = ref(false)

    const isSelected = computed(() => props.modelValue === 'shape')
    watch(isSelected, (newValue) => {
        if (!newValue) {
            resetMap()
            spatialFileImportMetadata.value = null
            spatialFileImportWkt.value = null
        }
    })

    const featuresFromShapefile = async (file: File) => {
        const geojson = await shp(await file.arrayBuffer()) as shp.FeatureCollectionWithFilename[]
        return new GeoJSON({
            dataProjection: CoordinateSystemCode.EPSG4326,
            featureProjection: CoordinateSystemCode.EPSG27700,
        }).readFeatures(geojson)
            .filter(feature => Boolean(feature.getGeometry()))
    }

    const featuresFromKMLText = (text: string) => {
        const format: KML = new KML()
        return format.readFeatures(text, {
            featureProjection: 'EPSG:27700',
        })
            .filter(feature => Boolean(feature.getGeometry()))
    }

    const featuresFromKML = async (file: File) => {
        const kmlText: string = await file.text()
        return featuresFromKMLText(kmlText)
    }

    const featuresFromKMZ = async (file: File) => {
        const zip: JSZip = new JSZip()
        await zip.loadAsync(file)
        const kmlFile: JSZipObject = zip.file(/\.kml$/i)[0]
        if (!kmlFile) {
            // No KML file found, consider handling.
            return
        }
        const kmlText: string = await kmlFile.async('text')
        return featuresFromKMLText(kmlText)
    }

    const initialiseDrawLayer = () => {
        if (!drawLayer.value) {
            drawLayer.value = new VectorLayer({
                source: new VectorSource(),
                visible: true,
                style: new Style({
                    zIndex: 100,
                    stroke: new Stroke({
                        color: '#ff0000',
                        width: 3,
                    }),
                    image: new CircleStyle({
                        radius: 12,
                        stroke: new Stroke({
                            color: '#ff0000',
                            width: 4,
                        }),
                    }),
                }),
            })
            store.state.map.map.addLayer(drawLayer.value)
        }
    }

    const addAndZoomToFeatures = (features:Array<Feature>) => {
        drawLayer.value.getSource().addFeatures(features)
        store.state.map.map.getView().fit(drawLayer.value.getSource().getExtent(), {
            duration: 1000,
            padding: [150, 150, 150, 150],
            easing: easeOut,
        })
    }

    const resetMap = () => {
        if (drawLayer.value) {
            drawLayer.value.getSource().clear()
            store.state.map.map.removeLayer(drawLayer.value)
            drawLayer.value = null
        }
    }

    const processFile = async (file: File) => {
        try {
            // Initialise the layer if required.
            initialiseDrawLayer()

            // Determine the format.
            const fileExtension: string = file.name.split('.').pop()

            // Read the file and return features to be added to the map layer.
            let features: Array<Feature> = []
            switch (fileExtension) {
                case 'geojson':
                case 'json':
                    features = await openLayersFeaturesFromGeoJSON(file)
                    break
                case 'zip':
                    features = await featuresFromShapefile(file)
                    break
                case 'kml':
                    features = await featuresFromKML(file)
                    break
                case 'kmz':
                    features = await featuresFromKMZ(file)
                    break
                default:
            // Consider handling /logging unexpected file extensions - this shouldn't happen due to the file input filter.
            }

            // Remove any styling inherited from various file formats, such that the layer style is always used.
            features.forEach((feature: Feature) => {
                feature.setStyle(null)
            })

            // Add it to the map and zoom to it.
            addAndZoomToFeatures(features)

            spatialFileImportWkt.value = []
            const olFormat = new WKT()
            features.forEach((f) => {
                const wkt = olFormat.writeGeometry(f.getGeometry())
                spatialFileImportWkt.value.push(wkt)
            })

            // Store metadata about the imported file to log both here and on completion of the import.
            updateSpatialFileImportMetadata(features, fileExtension, file.name)

            await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'SPATIAL-FILE-IMPORT - Loaded file',
                metadata: spatialFileImportMetadata,
            })

            await store.dispatch(LOGGING_DATADOG_LOG_INFO_EVENT, {
                message: 'SPATIAL-FILE-IMPORT - Loaded file',
                properties: {
                    ...spatialFileImportMetadata,
                },
            })
        } catch (error: unknown) {
            if (error instanceof Error) {
                console.error(`Spatial file import error - ${ error.message }`)
            } else {
                console.error('Spatial file import - Unexpected error', error)
            }
        } finally {
            setUploadModalVisible(false)
        }
    }

    const fileInputChange = async (filesToUpload: IOwFileUploaderEvent) => {
        resetMap()
        const files = filesToUpload.formData.getAll('files') as Array<File>
        if (files.length === 1) {
            const file: File = files[0]
            if (file) {
                await processFile(file)
            }
        }
    }

    const spatialFileImportWkt = ref()
    const spatialFileImportMetadata = ref(null)

    const updateSpatialFileImportMetadata = (features, format, filename) => {
        const combinedExtent = features.reduce((result, feature) => {
            return extend(result, feature.getGeometry().getExtent())
        }, [Number.MAX_VALUE, Number.MAX_VALUE, Number.MIN_VALUE, Number.MIN_VALUE])

        spatialFileImportMetadata.value = {
            format,
            features,
            filename,
            featureCount: features.length,
            areaSqKm: features.reduce((result, feature) => {
                return result + getArea(feature.getGeometry())
            }, 0) / 1000000,
            sizeKm: Math.max(getWidth(combinedExtent), getHeight(combinedExtent)) / 1000,
        }
    }

    const { storeSketchRequest } = useStoreSketch()

    const importTitles = async () => {
        const request = {
            geometries: spatialFileImportWkt.value,
            matterGroupId: selectedGroup.value != null ? selectedGroup.value.id : null,
            matterId: store.state.matter.currentMatter.id,
            onlyWithin: importOnlyWithinTitles.value,
        }
        const titlesAdded = await store.dispatch(MATTER_IMPORT_FROM_WKT_AREA, request)

        if (saveAsSketch.value) {
            const sketchName = spatialFileImportMetadata.value.filename
            await storeSketchRequest(sketchName, spatialFileImportMetadata.value.features, SketchSource.DrawOnMapTitleSearchImport)
        }

        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'SPATIAL-FILE-IMPORT - Added titles',
            metadata: spatialFileImportMetadata,
            titlesCount: titlesAdded,
        })
        await store.dispatch(LOGGING_DATADOG_LOG_INFO_EVENT, {
            message: 'SPATIAL-FILE-IMPORT - Added titles',
            properties: {
                ...spatialFileImportMetadata,
                titlesCount: titlesAdded,
            },
        })
        await store.dispatch(LOGGING_LOG_FEATURE_USAGE, {
            type: 'search-map-spatial-file-added-titles',
            description: titlesAdded,
        })

        await store.dispatch(MATTER_REQUEST_SHOW_TITLE_LIST)
        store.commit(TITLE_MUTATE_ACTIVE_TITLE_PANEL, TitlePanels.LIST)

        spatialFileImportMetadata.value = null
    }

    const setUploadModalVisible = (value) => store.commit(SET_UPLOAD_DOCUMENTS_DIALOG_STATE, value)

    onBeforeUnmount(() => {
        resetMap()
    })

    defineExpose({
        fileInputChange,
        drawLayer,
        processFile,
    })
</script>
