// @/stores/asset-monitoring/getters.ts

import { IGetCompanyGroupResponse } from '@/api/company-groups.api'
import {
    IGetNotificationResponseItem,
    ISummaryItem,
} from '@/api/notifications.api'
import { IAssetMonitoringNotification } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
import { NotificationPriorityType } from '@/components/asset-monitoring/notification-priority-type.enum'
import { NotificationSubType } from '@/components/asset-monitoring/notification-sub-type.enum'
import {
    IAssetMonitoringState,
    useAssetMonitoringStore,
} from '@/stores/asset-monitoring/index'
import {
    convertToNotificationSubType,
    convertToNotificationType,
} from '@/stores/asset-monitoring/utils'
import {
    isNullOrEmpty,
    unique,
} from '@/utils/array-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

export const mapNotificationResponseToAssetMonitoringItem = (notification: IGetNotificationResponseItem): IAssetMonitoringNotification => {
    return {
        notificationId: notification.notificationId,
        userNotificationId: notification.userNotificationId,
        message: notification.message,
        createdOn: new Date(notification.createdOn),
        isRead: notification.isRead,
        titleNumber: notification.titleNumber,
        organisationId: notification.organisationId,
        notificationType: convertToNotificationType(notification.type),
        companyNumbers: notification.companyNumbers,
        notificationDetails: notification.notificationDetails?.map(x => ({
            difference: x.difference,
            notificationSubType: convertToNotificationSubType(x.notificationSubType),
            priority: x.priority,
        })),
    } as IAssetMonitoringNotification
}

export default {
    userNotifications: (state: IAssetMonitoringState): Array<IAssetMonitoringNotification> => {
        return [ ...state.notifications?.values() ]
            .map(mapNotificationResponseToAssetMonitoringItem)
    },

    /**
     * Checks if the given state has any unread notifications.
     *
     * @param {IAssetMonitoringState} state - The state object containing notifications.
     * @return {boolean} - True if there are unread notifications, false otherwise.
     */
    hasUnreadNotifications: (state: IAssetMonitoringState): boolean => {
        return state?.unreadCompanyNotifications > 0
            || state?.unreadTitleNotifications > 0
            || !isNullOrEmpty(state.pendingNotifications)
    },
    /**
     * Retrieves a notification by its title number.
     *
     * @returns {Function} Function that takes a title number as input and returns the corresponding notification.
     */
    getNotificationByTitleNumber: (): Function => {
        // To use a getter inside a getter we need a reference to the store again
        const assetMonitoringStore = useAssetMonitoringStore()
        return (titleNumber: string) => assetMonitoringStore.userNotifications.filter(notification => notification.titleNumber === titleNumber)
    },

    /**
     * Get a list of all the unique titles number we have notifications for
     * @param state
     */
    getSummaryTitleNumbers: (state: IAssetMonitoringState): Array<ISummaryItem> => {
        return state.summaryTitles.map((titleNumber: string) => ({
            id: titleNumber,
            label: titleNumber,
        }))
    },

    /**
     * Get a list of all the unique companies we have notifications for
     * @param state
     */
    getSummaryCompanies: (state: IAssetMonitoringState): Array<ISummaryItem> => {
        const companyNumbers = state.summaryCompanies
        const groupedCompanies = state.companyGroups

        const matchedNames = companyNumbers.map(companyNumber => {
            // Find the matching company object by companyNumber
            const company = groupedCompanies
                .flatMap(group => group.companies) // Flatten all companies from all groups
                .find(company => company.companyNumber === companyNumber) // Match the companyNumber

            // Return the company name if found
            return {
                id: company?.companyNumber,
                label: company?.name || null,
            }
        })

        // Remove any null values in case some company numbers have no match
        return matchedNames.filter(company => !isNullOrWhitespace(company.label))
    },

    /**
     * Retrieves the available notification subtypes based on the current state and filtering logic.
     *
     * @param {IAssetMonitoringState} state - The current state object that determines filtering criteria and available entities.
     * @return {NotificationSubType[]} An array of unique notification subtypes that satisfy the filtering conditions.
     */
    getAvailableNotificationSubTypes(state: IAssetMonitoringState): NotificationSubType[] {
        const notificationSubTypes = state.allNotifications
            ?.filter((notification: IGetNotificationResponseItem) =>
                !(state.showOnlyUnread && notification.isRead)
                    && (
                        state.availableTitleNumbers.includes(notification.titleNumber)
                        || notification.companyNumbers.some(company => state.availableCompanies.includes(company))),
            )
            .flatMap((notification: IGetNotificationResponseItem) =>
                notification.notificationDetails?.map(x => convertToNotificationSubType(x.notificationSubType)) || [])

        return unique(notificationSubTypes)
    },

    /**
     * Retrieves the list of available notification priorities based on the state of the asset monitoring system.
     *
     * @param {IAssetMonitoringState} state - The current state of the asset monitoring system, containing all notifications and relevant data.
     * @return {NotificationPriorityType[]} An array of unique notification priorities available based on the filtering criteria.
     */
    getAvailablePriorities(state: IAssetMonitoringState): NotificationPriorityType[] {
        const priorities = [...state.allNotifications?.values()]
            .map((notification: IGetNotificationResponseItem) => {
            // if unread
                if (state.showOnlyUnread && notification.isRead) {
                    return []
                }
                if (state.availableTitleNumbers.includes(notification.titleNumber)
                    || state.availableCompanies.some((company: string) => notification.companyNumbers.includes(company))) {
                    return notification.notificationDetails?.map(x => x.priority)
                }
            })
            .filter(x => !!x) // remove any undefined/null entries

        return unique(priorities.flat())
    },

    /**
     * Retrieves the list of company groups that have at least one company with alerts.
     *
     * @param {IAssetMonitoringState} state - The state containing the available companies and company groups data.
     * @returns {IGetCompanyGroupResponse[]} - An array of company groups that include companies present in the list of available companies with alerts.
     */
    getCompanyGroupsWithAlerts: (state: IAssetMonitoringState): IGetCompanyGroupResponse[] => {
        const companyNumbersWithAlerts = state.availableCompanies
        return state.companyGroups
            .filter((group: IGetCompanyGroupResponse) => group.companies.some(company => companyNumbersWithAlerts.includes(company.companyNumber)))
    },

    /**
     * Get the eraliest date of an unread alert.
     * Used as the default from date on the summary page.
     * @param state
     */
    getEarliestUnreadAlertDate: (state: IAssetMonitoringState): Date => {
        return state.notifications.reduce((earliestDate: Date, notification: IGetNotificationResponseItem) => {
            const createdOn = new Date(notification.createdOn)
            return notification.isRead ? earliestDate : createdOn < earliestDate ? createdOn : earliestDate
        }, new Date())
    },
}
