<template>
    <ow-card :loading="!selectedTitle?.record || isLoading"
             :loading-skeleton="{ rows: 1, columns: 1, expandRows: false, reverse: true, ctas: 1 }"
             class="ml-tags-card"
             has-beta-label
             subtitle="Source: Boffins at Orbital Witness"
             title="Orbital Witness Flags">
        <template #actions>
            <ow-button data-track="TITLE-DETAILS-PANEL - MLTAGS - Export table"
                       outlined
                       @click="download">
                <img alt="Click to download the tags as CSV"
                     class="ml-tags-card__excel-icon"
                     src="../../../../media/icon-excel.webp"
                     width="16"
                     height="16"
                     title="Export as CSV" />
                {{ $t('action.exportToExcel') }}
            </ow-button>
        </template>
        <div>
            <ow-data-grid :allow-selection="false"
                          fixed-header
                          :headers="tableDataGridHeaders"
                          height="300"
                          :items="tags"
                          :sort-by="sortBy"
                          narrow
                          no-data-text="No tags found for this title">
                <template #item.family="{ item }">
                    <td class="body-copy metadata"
                        :title="item.family">
                        {{ item.family }}
                    </td>
                </template>
                <template #item.topic="{ item }">
                    <td class="body-copy metadata"
                        :title="item.topic">
                        {{ item.topic }}
                    </td>
                </template>
                <template #item.type="{ item }">
                    <td class="body-copy metadata"
                        :title="item.type">
                        {{ item.type }}
                    </td>
                </template>
                <template #item.location="{ item }">
                    <td class="body-copy location">
                        <ow-tooltip :position="OwTooltipPosition.Right"
                                    max-width="300px">
                            <template #activator="{ props }">
                                <div v-bind="props">
                                    <ow-button v-if="showLinkForItem(item)"
                                               text
                                               class="location"
                                               @click="viewEntry(item)">
                                        {{ item.location }}
                                    </ow-button>
                                    <span v-else>{{ item.location }}</span>
                                </div>
                            </template>
                            <span>{{ item.text }}</span>
                        </ow-tooltip>
                    </td>
                </template>
            </ow-data-grid>
        </div>
    </ow-card>
</template>
<script lang="ts">
    import { ref } from 'vue'
    import {
        mapMutations,
    } from 'vuex'

    import TitleInformationApi from '@/api/title-information.api'
    import OwButton from '@/components/core/ow-button.vue'
    import OwCard from '@/components/core/ow-card.vue'
    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { TitlePanelTabName } from "@/enums/title-panel-tab-name"
    import {
        TITLE_MUTATE_REGISTER_INDICATOR_SELECTION,
        TITLE_MUTATE_REGISTER_INDICATOR_TEXT_SELECTION_SPAN,
    } from '@/store/modules/titles/types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { exportAsCsv } from '@/utils/csv-utils'
    import { format } from '@/utils/date-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'MLTags',

        components: {
            OwButton,
            OwCard,
            OwDataGrid,
            OwTooltip,
        },

        props: {
            selectedTitleNumber: {
                default: null,
                type: String || null,
            },
            selectedTitle: {
                type: Object,
                default: null,
            },
        },

        emits: [
            'set-selected-tab',
        ],

        data() {
            return {
                tags: [],
                isLoading: false,
                hasLoaded: false,
                tableDataGridHeaders: [
                    { title: 'Family', key: 'family', class: 'metadata' },
                    { title: 'Topic', key: 'topic', class: 'metadata' },
                    { title: 'Type', key: 'type', class: 'metadata' },
                    { title: 'Location', key: 'location', class: 'location' },
                ],
                OwTooltipPosition,
                sortBy: ref([{key: 'family', order: 'asc'}]),
            }
        },

        watch: {
            selectedTitleNumber(val) {
                if (isNullOrWhitespace(val)) {
                    this.tags = []
                } else {
                    this.populateData()
                }
            },
        },

        async mounted() {
            try {
                if (this.selectedTitleNumber) {
                    await this.populateData()
                }
            } catch (e) {
                console.error(e)
            } finally {
                this.isLoading = false
            }
        },

        methods: {
            ...mapMutations({
                setRegisterIndicatorSelection: TITLE_MUTATE_REGISTER_INDICATOR_SELECTION,
                setRegisterIndicatorSelectedTextSpan: TITLE_MUTATE_REGISTER_INDICATOR_TEXT_SELECTION_SPAN,
            }),

            exportAsCsv,

            async populateData() {
                this.isLoading = true
                const response = await TitleInformationApi.getMLTagsByTitleNumbers([this.selectedTitleNumber])
                const result = response?.filter(x => x.titleNumber == this.selectedTitleNumber)
                if (isNullOrEmpty(result)) {
                    console.warn(`No tags found for ${ this.selectedTitleNumber }`)
                    return
                }
                this.tags = result[0].titleRegisterMlTags ?? []
                this.tags.forEach(x => {
                    x.position = `${ x.startPosition } - ${ x.endPosition }`
                    x.location = `${ x.section } - ${ x.entryNumber }`
                    x.text = x.entryText.substring(x.startPosition, x.endPosition)
                    x.description = `${ x.topic ?? 'no topic' } - ${ x.family } - ${ x.type }`
                    x.code = `${ x.section }${ x.entryNumber }`
                })
                this.hasLoaded = true
                this.isLoading = false
            },
            download(): Array<Array<string | number>> {
                const rows = []
                const headers = ['Family', 'Topic', 'Type', 'Section', 'Entry Number', 'Start Position', 'End Position']
                const data = this.tags.map(x => {
                    return [
                        x.family,
                        x.topic,
                        x.type,
                        x.section,
                        x.entryNumber,
                        x.startPosition,
                        x.endPosition,
                    ]
                })

                rows.push(headers, ...data)
                const filename = `tags-data-export-${ format(new Date(), 'dd-MM-yyyy') }-${ this.selectedTitleNumber }.csv`
                this.exportAsCsv(rows, filename)

                return rows
            },
            viewEntry(item) {
                this.$emit('set-selected-tab', TitlePanelTabName.Register)

                const indicator = {
                    codes: [{
                        code: item.code,
                    }],
                    label: item.description,
                }
                this.setRegisterIndicatorSelection(indicator)
                this.setRegisterIndicatorSelectedTextSpan([item.startPosition, item.endPosition])
            },
            showLinkForItem(item): boolean {
                // TODO: At the moment the linking to schedules doesn't work, and the schedule of notices of lease isn't shown. Needs revisiting.
                return !item.code.includes('SCHEDULE')
            },
        },
    }
</script>

<style lang="scss">
    @import './ml-tags-card.scss';
</style>
