<template>
    <div class="ow-snapshot-map">
        <slot />
        <core-map v-if="initialView !== null"
                  :initial-view="initialView"
                  :layers="olLayers"
                  :name="'snapshot-map'"
                  :show-map-scale="true"
                  :interactions="interactions"
                  data-test="ow-snapshot-map"
                  @loading="$emit('loading')"
                  @map-post-render="$emit('map-post-render')"
                  @map-initialised="handleMapInitialised" />
    </div>
</template>

<script lang="ts" setup>
    import OlMap from 'ol/Map'
    import {
        computed,
    } from 'vue'

    import CoreMap from '@/components/core/maps/core-map.vue'
    import { CoreMapInteractions } from '@/components/core/maps/core-map-interactions.interface'
    import { CoreMapView } from '@/components/core/maps/core-map-view.interface'
    import { IMapSnapshotLayer } from '@/components/snapshots/map-snapshots/map-snapshot-interfaces'
    interface Props {
        layers: Array<IMapSnapshotLayer>
        initialView?: CoreMapView
    }

    const props = defineProps<Props>()

    const olLayers = computed(() => {
        return props.layers.map(x => x.getLayer())
    })

    const interactions: CoreMapInteractions = {
        doubleClickZoom: true,
        dragPan: true,
        keyboard: true,
        mouseWheelZoom: true,
    }
    const emit = defineEmits(['loaded', 'loading', 'map-initialised', 'map-post-render'])

    const handleMapInitialised = (loadedMap: OlMap) => {
        emit('map-initialised', loadedMap)
    }

</script>
