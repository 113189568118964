<template>
    <section class="am-summary">
        <div class="am-summary__side">
            <ow-loading-skeleton v-if="isLoading"
                                 color="secondary"
                                 height="250px"
                                 width="256px" />
            <h3 v-if="hasTitles"
                v-t="'assetMonitoring.text.titles'"
                class="label-caps-smallest" />
            <ul v-if="hasTitles"
                class="am-summary__side--items-container">
                <li v-for="title in monitoredTitles"
                    :key="title.id">
                    <ow-button class="d-inline-flex"
                               is-link
                               @click="selectSummaryItemHandler(title)">
                        <span :class="{ 'selected': title.id === selected?.id}"
                              class="body-highlight">{{ title.label }}</span>
                    </ow-button>
                </li>
            </ul>

            <h3 v-if="hasCompanies"
                v-t="'assetMonitoring.text.companies'"
                class="label-caps-smallest" />
            <ul v-if="hasCompanies"
                class="am-summary__side--items-container">
                <li v-for="company in monitoredCompanies"
                    :key="company.id">
                    <ow-button class="d-inline-flex"
                               is-link
                               @click="selectSummaryItemHandler(company)">
                        <span :class="{ 'selected': company.id === selected?.id}"
                              class="body-highlight">{{ company.label }}</span>
                    </ow-button>
                </li>
            </ul>
        </div>
        <div class="am-summary__main body-regular">
            <i18n-t class="body-regular am-summary__header"
                    keypath="assetMonitoring.text.changesSummary"
                    scope="global"
                    tag="p">
                <template #changes>
                    <span class="body-highlight">{{ t('assetMonitoring.text.change', { count: numberOfChanges }) }}</span>
                </template>
                <template #titles>
                    <span class="body-highlight">{{ t('assetMonitoring.text.title', { count: numberOfTitles }) }}</span>
                </template>
                <template #companies>
                    <span class="body-highlight">{{ t('assetMonitoring.text.company', { count: numberOfCompanies }) }}</span>
                </template>
                <template #dates>
                    <ow-date-picker :date-before="beforeDate"
                                    :date-since="startDate"
                                    class="am_summary__header--date-picker"
                                    disable-future-dates
                                    @change="datesChangeHandler" />
                </template>
            </i18n-t>
            <div class="am-summary__feed">
                <data-grid :auto-load-more-data="false"
                           :expand-all-rows="true"
                           :is-loading="isLoading"
                           :items="filteredNotifications"
                           :matter-id="props.matterId"
                           @refresh="$emit('refresh')" />
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import {
        computed,
        onActivated,
        onDeactivated,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import {
        IGetNotificationResponseItem,
        ISummaryItem,
    } from '@/api/notifications.api'
    import { IAssetMonitoringNotification } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import DataGrid from '@/components/asset-monitoring/grid/data-grid.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwDatePicker from '@/components/core/ow-date-picker.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import { useAssetMonitoringStore } from '@/stores/asset-monitoring'
    import { mapNotificationResponseToAssetMonitoringItem } from '@/stores/asset-monitoring/getters'
    import { isNullOrEmpty } from '@/utils/array-utils'

    const store = useAssetMonitoringStore()
    const { t } = useI18n()

    const props = defineProps<{
        matterId: number
    }>()

    defineEmits<{
        (e: 'refresh')
    }>()

    const isLoading = computed<boolean>(() => store.isLoading || store.isLoadingSummaryData)
    const hasLoadedAllNotifications = computed<boolean>(() => !isNullOrEmpty(store.allNotifications))
    const numberOfChanges = computed<number>(() => store.summaryTotalChanges)
    const notifications = computed<IGetNotificationResponseItem[]>(() => store.summaryNotifications)

    const monitoredTitles = computed<ISummaryItem[]>(() => store.getSummaryTitleNumbers)
    const numberOfTitles = computed<number>(() => monitoredTitles.value?.length)
    const hasTitles = computed(() => !isNullOrEmpty(monitoredTitles.value) && !isLoading.value)

    const monitoredCompanies = computed<ISummaryItem[]>(() => store.getSummaryCompanies)
    const numberOfCompanies = computed<number>(() => monitoredCompanies.value?.length)
    const hasCompanies = computed(() => !isNullOrEmpty(monitoredCompanies.value) && !isLoading.value)

    const startDate = ref<Date>(null)
    const beforeDate = ref<Date>(null)
    const selected = ref<ISummaryItem>(null)
    const filteredNotifications = ref<IAssetMonitoringNotification[]>([])

    const fetchData = async () => {
        if (startDate.value
            && beforeDate.value) {
            await store.fetchSummaryNotifications(props.matterId, startDate.value, beforeDate.value)
            setFilteredNotifications()
        }
    }

    const setFilteredNotifications = (): void => {
        // filter the notifications based on the selected item
        filteredNotifications.value = notifications.value
            .filter((notification: IGetNotificationResponseItem) =>
                notification?.companyNumbers?.includes(selected.value?.id) || notification?.titleNumber === selected.value?.id)
            .map((notification: IGetNotificationResponseItem) => mapNotificationResponseToAssetMonitoringItem(notification))
    }

    const selectSummaryItemHandler = async (item: ISummaryItem): Promise<void> => {
        selected.value = item
        setFilteredNotifications()
    }

    const datesChangeHandler = async (dates: string[]): Promise<void> => {
        startDate.value = new Date(dates[0])
        beforeDate.value = new Date(dates[1])
        setFilteredNotifications()
    }

    watch(() => hasLoadedAllNotifications.value, async (hasLoaded: boolean) => {
        if (hasLoaded) {
            // Once setting these, there is a watcher setup to go perform the fetch.
            beforeDate.value = new Date()
            startDate.value = store.getEarliestUnreadAlertDate
        }
    }, { immediate: true })

    watch([ startDate, beforeDate ], async () => {
        await fetchData()
    }, { immediate: true })

    watch(monitoredTitles, async () => {
        // Set the initial selected item
        if (selected.value === null
            && !isNullOrEmpty(monitoredTitles.value)) {
            await selectSummaryItemHandler(monitoredTitles.value[0])
        }
    }, { immediate: true })

    watch(monitoredCompanies, async () => {
        // Set the initial selected item
        if (selected.value === null
            && isNullOrEmpty(monitoredTitles.value)
            && !isNullOrEmpty(monitoredCompanies.value)) {
            // only set this if the titles are empty. Titles would be selected over the companies.
            await selectSummaryItemHandler(monitoredCompanies.value[0])
        }
    }, { immediate: true })

    onActivated(async () => {
        await fetchData()
    })

    onDeactivated(() => {
        store.resetSummaryData()
        filteredNotifications.value = []
    })
</script>

<style lang="scss" scoped>
@import './am-summary';
</style>
