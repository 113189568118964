/**
 * Represents the type of notification.
 *
 * @enum {string}
 */
export enum NotificationType {
    Unknown = -1,
    TitleRegisterOutOfDate = 0,
    OwnershipChanged = 1,
    BoundaryChanged = 2,
    CompaniesHouseChanged = 3,
    EpcRecordChanged = 4,
    PlanningApplicationRecordChanged = 5,
}
