<template>
    <nav v-if="showGlobalNavigation"
         id="globalNav">
        <!-- Home button, likely a bit different to the others -->
        <div class="global-nav__top">
            <div id="globalNavHeaderIcon"
                 :class="{selected: homeNavItemSelected}"
                 data-global-nav-item="home"
                 data-test="global-nav-homepage"
                 data-track="GLOBAL-NAV - Homepage button"
                 @click="onHeaderIconClick">
                <ow-icon-ow-logo class="global-nav__ow-logo"
                                 data-test="global-nav-logo-selected"
                                 height="34"
                                 width="40" />
                <ow-icon-ow-logo-bw class="global-nav__ow-logo-bw"
                                    data-test="global-nav-logo-selected"
                                    height="34"
                                    width="40" />
            </div>

            <global-nav-item v-if="!isSharedLinkView"
                             data-test-attribute="global-nav-matters-list"
                             data-track-attribute="GLOBAL-NAV - Matters list button"
                             global-nav-name="matters"
                             icon="$matters"
                             label="Matters"
                             router-path="/matters"
                             toggle />

            <global-nav-loading-skeleton v-if="isLoading" />
            <div v-else-if="isAuthenticated"
                 class="d-flex flex-column">
                <!-- Map -->
                <global-nav-item v-if="inAMatter && !showIcons"
                                 :charges-text="showCharge ? chargesText : null"
                                 :disabled="isCurrentMatterClosed && !isSharedLinkView"
                                 :is-matter-related="true"
                                 :tooltip-text="$t('reopenMatterText')"
                                 data-test-attribute="global-nav-map"
                                 data-track-attribute="GLOBAL-NAV - Map button"
                                 icon="$map"
                                 :global-nav-name="isTopNavVisible ? ['map','sketches'] : 'map'"
                                 label="Map"
                                 toggle
                                 @click="onMapClicked" />

                <!-- Title analysis -->
                <global-nav-item v-if="showTitleAnalysis && !isSharedLinkView && !showIcons"
                                 :custom-icon="Icons.TAM"
                                 :disabled="isCurrentMatterClosed"
                                 :is-matter-related="true"
                                 :router-path="analysisPath"
                                 :tooltip-text="$t('reopenMatterText')"
                                 data-test-attribute="global-nav-titles"
                                 data-track-attribute="GLOBAL-NAV - Analysis button"
                                 global-nav-name="analysis"
                                 label="Analysis" />

                <!-- Documents -->
                <global-nav-item v-if="inAMatter && !isSharedLinkView && !showIcons"
                                 :is-matter-related="true"
                                 :router-path="documentsPath"
                                 data-test-attribute="global-nav-titles-documents"
                                 data-track-attribute="GLOBAL-NAV - Documents button"
                                 global-nav-name="document-library"
                                 icon="$documents"
                                 label="Documents" />

                <!-- Searches -->
                <global-nav-item v-if="inAMatter && !isSharedLinkView && !showIcons && isSearchesEnabled"
                                 :custom-icon="Icons.SEARCHES"
                                 :disabled="isCurrentMatterClosed"
                                 :is-matter-related="true"
                                 :router-path="searchesPath"
                                 data-test-attribute="global-nav-searches"
                                 data-track-attribute="GLOBAL-NAV - Searches button"
                                 global-nav-name="searches"
                                 label="Searches" />

                <!-- Reports -->
                <global-nav-item v-if="inAMatter && !isSharedLinkView && !showIcons"
                                 :is-matter-related="true"
                                 :router-path="reportsPath"
                                 data-test-attribute="global-nav-reports"
                                 data-track-attribute="GLOBAL-NAV - Reports button"
                                 global-nav-name="reports"
                                 icon="$reporting"
                                 label="Reports" />

                <!-- Asset Monitoring -->
                <global-nav-item v-if="inAMatter && !isSharedLinkView && !showIcons"
                                 :is-matter-related="true"
                                 :router-path="assetMonitoringPath"
                                 data-test-attribute="global-nav-asset-monitoring"
                                 data-track-attribute="GLOBAL-NAV - Matter asset monitoring button"
                                 :data-am-active="!assetMonitoringInactive"
                                 :has-notification="hasUnreadNotifications"
                                 icon="$notification"
                                 :feature-id="FeatureId.Alerts"
                                 global-nav-name="alerts"
                                 :label="$t('label.alerts')" />

                <!-- Settings -->
                <global-nav-item v-if="inAMatter && !isSharedLinkView && !showIcons"
                                 :is-matter-related="true"
                                 :router-path="matterSettingsPath"
                                 data-test-attribute="global-nav-matter-settings"
                                 data-track-attribute="GLOBAL-NAV - Matter settings button"
                                 global-nav-name="matter"
                                 icon="$matter-settings"
                                 label="Settings" />
            </div>
        </div>

        <div class="d-flex flex-column">
            <!-- Account -->
            <global-nav-item v-if="!isSharedLinkView && !isLoading"
                             data-test-attribute="global-nav-titles-account"
                             data-track-attribute="GLOBAL-NAV - Account button"
                             global-nav-name="account"
                             icon="$account-circle"
                             label="Account"
                             router-path="/settings/account" />

            <!-- 9 dot menu -->
            <ow-9-dot-menu v-if="!isSharedLinkView && appNavEnabled"
                           data-test="global-nav-9-dot-menu"
                           :items="navItems"
                           :selected="selectedNavItem"
                           position="bottom"
                           @select="handleSelectAppNavItem" />

            <!-- Exit - currently presented for matter link share client view -->
            <global-nav-item v-if="isSharedLinkView"
                             data-test-attribute="global-nav-titles-exit"
                             data-track-attribute="GLOBAL-NAV - Exit interactive site plan button"
                             global-nav-name="exit"
                             icon="$exit-to-app"
                             label="Exit"
                             @click="onExitClicked" />
        </div>
    </nav>
</template>
<script>
    import {computed} from "vue"
    import {mapActions,
            mapGetters,
            mapMutations,
            mapState} from 'vuex'

    import OwIconOwLogo from '@/components/core/icons/ow-icon-ow-logo.vue'
    import OwIconOwLogoBw from '@/components/core/icons/ow-icon-ow-logo-bw.vue'
    import Ow9DotMenu from '@/components/core/ow-9-dot-menu.vue'
    import GlobalNavItem from '@/components/global-nav/global-nav-item'
    import GlobalNavLoadingSkeleton from '@/components/loading-skeletons/global-nav-loading-skeleton.vue'
    import useAppNav from '@/composables/use-app-nav'
    import {FeatureId} from "@/composables/use-licence-controller"
    import {useMapTopNav} from '@/composables/use-map-top-nav'
    import {Icons} from '@/consts/icons'
    import {Route} from '@/enums/route.enum'
    import {checkFlag} from "@/feature-flags"
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'
    import {AUTH_GET_IS_AUTHENTICATED,
            AUTH_LOGOUT} from '@/store/modules/auth-oidc/types'
    import {LINK_SHARE_CLIENT_EXIT_AUTH_USER_SHARED_LINK_VIEW,
            LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW} from '@/store/modules/link-share-client/types'
    import {MAP_UPDATE_SIZE} from '@/store/modules/map/types'
    import {MATTER_GET_IS_CURRENT_MATTER_CLOSED} from '@/store/modules/matter/types'
    import {TITLE_CLEAR} from "@/store/modules/titles/types"
    import {LOGGING_HEAP_TRACK_EVENT,
            USER_MUTATE_SHOW_MAP_TITLES_NAV} from '@/store/mutation-types'
    import {useAssetMonitoringStore} from "@/stores/asset-monitoring"

    export default {
        name: 'AppNav',
        components: {
            GlobalNavItem,
            GlobalNavLoadingSkeleton,
            OwIconOwLogo,
            OwIconOwLogoBw,
            Ow9DotMenu,
        },

        mixins: [
            FeatureFlagsMixin,
        ],

        setup() {
            const { navItems, selected, enabled: appNavEnabled } = useAppNav({})
            const assetMonitoringStore = useAssetMonitoringStore()
            const assetMonitoringInactive = computed(() => !assetMonitoringStore.titleNotificationSettings.isActive)
            const hasUnreadNotifications = computed(() => assetMonitoringStore.hasUnreadNotifications && !assetMonitoringInactive.value)
            return {
                navItems,
                appNavEnabled,
                selectedNavItem: selected,
                hasUnreadNotifications,
                assetMonitoringInactive,
            }
        },

        data() {
            return {
                Icons,
                // TODO: make sketches work like overlays so it runs off the route `uses`.
                isTopNavVisible: useMapTopNav(),
            }
        },

        computed: {
            FeatureId() {
                return FeatureId
            },
            ...mapGetters({
                isAuthenticated: AUTH_GET_IS_AUTHENTICATED,
                isCurrentMatterClosed: MATTER_GET_IS_CURRENT_MATTER_CLOSED,
            }),

            ...mapGetters('linkShareClient', {
                isSharedLinkView: LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW,
            }),

            ...mapState({
                currentMatter: state => state.matter.currentMatter,
                selectedTitleNumber: state => state.title.selectedTitleNumber,
                showMapTitlesNav: state => state.user.showMapTitlesNav,
                loadingUserProfile: state => state.user.loading,
                inAMatter: state => state.matter.currentMatter.id !== null,
                loadingState: state => state.matter.loadingState,
                currentLinkShare: state => state.matter.currentMatter.linkShare,
                isMatterLinkShareUser: state => state.linkShareClient.isMatterLinkShareUser,

            }),

            ...mapState('config', {
                isTitlePanelV2Enabled: state => state.featureFlags?.titlePanelV2,
                isSearchesEnabled: state => state.featureFlags?.orderSearch,
            }),

            // Display text
            chargesText() {
                return `£${ this.currentMatter.charges.totalDispursableCharges !== null ? this.currentMatter.charges.totalDispursableCharges : '-' }`
            },

            reportsPath() {
                return `/matters/${ this.currentMatter.id }/reports`
            },

            matterSettingsPath() {
                return `/matters/${ this.currentMatter.id }/settings`
            },

            assetMonitoringPath() {
                return `/matters/${ this.currentMatter.id }/alerts`
            },

            analysisPath() {
                return {
                    name: Route.Analysis,
                    params: {
                        matterId: this.currentMatter.id,
                    },
                }
            },

            mapRoute() {
                if (this.selectedTitleNumber) {
                    return {
                        name: Route.MatterMapTitle,
                        params: {
                            matterId: this.currentMatter.id,
                            titleNumber: this.selectedTitleNumber,
                        },
                    }
                } else {
                    return {
                        name: Route.MatterMap,
                        params: {
                            matterId: this.currentMatter.id,
                        },
                    }
                }
            },

            documentsPath() {
                return `/matters/${ this.currentMatter.id }/documents`
            },

            searchesPath() {
                return `/matters/${ this.currentMatter.id }/searches`
            },

            homeNavItemSelected() {
                return this.$route.name === Route.Homepage
            },

            showIcons() {
                // When creating a new matter, need to hide all current matter icons
                return this.$route?.name === Route.MattersCreate || !this.inAMatter
            },

            showTitleAnalysis() {
                return this.inAMatter
            },

            showGlobalNavigation() {
                return !this.$route.meta?.hideGlobalNavigation && this.$route.name !== Route.MatterMapWalkthrough
            },

            isLoading() {
                return this.loadingState.deleteMatter || this.loadingState.loadingCurrentMatter || this.loadingUserProfile
            },

            showCharge() {
                return this.inAMatter && !this.isSharedLinkView && !checkFlag('hide-charge-on-global-nav', false)
            },
        },

        methods: {
            ...mapMutations({
                setShowMapTitlesNav: USER_MUTATE_SHOW_MAP_TITLES_NAV,
            }),

            ...mapActions({
                updateMapSize: MAP_UPDATE_SIZE,
                logout: AUTH_LOGOUT,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
                clearTitle: TITLE_CLEAR,
            }),

            ...mapActions('linkShareClient', {
                exitAuthUserLinkSharedView: LINK_SHARE_CLIENT_EXIT_AUTH_USER_SHARED_LINK_VIEW,
            }),

            async handleSelectAppNavItem(item) {
                if (item?.url) {
                    await this.logHeapEvent({
                        type: 'Global App Nav - App Selector',
                        metadata: {
                            position: 'App Nav',
                            url: item.url,
                        },
                    })
                    window.open(item.url, '_blank')
                }
            },

            onMapClicked() {
                if (this.$route.meta?.globalNavName === 'map') {
                    // if the title panel is v2 & there is a title number, close it on map click
                    if (this.isTitlePanelV2Enabled && this.$route.params.titleNumber) {
                        this.clearTitle()
                    } else {
                        this.setShowMapTitlesNav(!this.showMapTitlesNav)
                    }
                } else {
                    this.setShowMapTitlesNav(true)
                    this.$router.push(this.mapRoute)
                }
                this.updateMapSize()
            },

            onHeaderIconClick() {
                if (this.isSharedLinkView) {
                    this.$router.push({
                        name: Route.LinkShareLanding,
                        params: {
                            linkGuid: this.currentLinkShare.linkGuid,
                        },
                    })
                } else {
                    this.$router.push({ name: Route.Homepage})
                }
            },

            onExitClicked() {
                if (this.isMatterLinkShareUser) {
                    this.logout()
                } else {
                    this.exitAuthUserLinkSharedView()
                }
            },
        },
    }
</script>
<style lang="scss">
    @import './global-nav.scss';
</style>
