<template>
    <ow-page-layout class="matters-create-v2"
                    :class="{
                        'matters-create-v2--loading': isLoading,
                    }"
                    fixed
                    page-name="matters-create">
        <ow-wizard :key="key"
                   v-model="stepIndex"
                   class="matters-create-v2__content mt-6"
                   :steps="steps"
                   :heading="t('matterCreate.heading')"
                   @submit="onSubmit">
            <template #header>
                <div v-if="hasTitlesToAdd"
                     class="d-flex body-regular-small gc-1 align-center pb-3">
                    <v-icon>$info</v-icon>
                    <p class="body-regular-small"
                       data-test="matters-create-titles-to-add-text">
                        {{ titlesToAddText }}
                    </p>
                </div>
            </template>
            <template #step-create-matter="{ step }">
                <matter-create-details-step :step="step" />
            </template>
            <template #step-matter-features="{ step }">
                <matter-create-features-step :step-data="stepData"
                                             :step="step" />
            </template>
            <template #step-company-alerts="{ step }">
                <matter-create-company-alerts-step v-model="stepIndex"
                                                   :step-data="stepData"
                                                   :active="stepIndex === 2"
                                                   :step="step" />
            </template>
            <template #step-matter-sharing="{ step }">
                <ow-loading-slot :loading="isLoading"
                                 offset-y="250px">
                    <matter-create-sharing-step :step="step" />
                </ow-loading-slot>
            </template>
        </ow-wizard>
    </ow-page-layout>
</template>

<script lang="ts" setup>
    import {
        computed,
        onActivated,
        onBeforeMount,
        onDeactivated,
        ref,
        useId,
    } from "vue"
    import { useI18n } from "vue-i18n"
    import {
        useRouter,
    } from "vue-router"
    import { useStore } from "vuex"

    import CompanyGroupsApi from "@/api/company-groups.api"
    import OwPageLayout from '@/components/core/layout/page.vue'
    import OwLoadingSlot from "@/components/core/ow-loading-slot.vue"
    import { IWizardStep } from "@/components/core/ow-wizard"
    import OwWizard from "@/components/core/ow-wizard/ow-wizard.vue"
    import MatterCreateCompanyAlertsStep from "@/components/matter/create/matter-create-company-alerts-step.vue"
    import MatterCreateDetailsStep from "@/components/matter/create/matter-create-details-step.vue"
    import MatterCreateFeaturesStep from "@/components/matter/create/matter-create-features-step.vue"
    import MatterCreateSharingStep from "@/components/matter/create/matter-create-sharing-step.vue"
    import {
        IMatterCreateCompanyAlertsStepData,
        IMatterCreateDetailsStepData,
        IMatterCreateFeaturesStepData,
        IMatterCreateSharingStepData,
    } from "@/components/matter/create/types"
    import { MatterShareType } from "@/enums/matter-share-type.enum"
    import { Route } from "@/enums/route.enum"
    import {
        MATTER_ADD_MULTIPLE_TITLES,
        MATTER_CREATE,
        MATTER_MUTATE_TITLES_TO_ADD_AFTER_MATTER_CREATE,
        MATTER_UPDATE_PERMISSIONS,
    } from "@/store/modules/matter/types"
    import { LOGGING_HEAP_TRACK_EVENT } from "@/store/mutation-types"
    import { useAssetMonitoringStore } from "@/stores/asset-monitoring"
    import { isNullOrWhitespace } from "@/utils/string-utils"
    const stepIndex = defineModel<number>()
    const steps = ref<IWizardStep<unknown>[]>([])
    const store = useStore()
    const isLoading = ref(false)
    const isMatterCreateErrorState = ref(false)
    const newMatter = ref()
    const hasError = ref(false)
    const assetMonitoringStore = useAssetMonitoringStore()
    const router = useRouter()
    const key = ref()
    const { t } = useI18n()

    const initSteps = () => {
        steps.value = [
            {
                id: 'create-matter',
                heading: t('matterCreate.steps.createMatter.heading'),
                description: t('matterCreate.steps.createMatter.description'),
                prevButtonLabel: t('action.cancel'),
                onPrev: () => {
                    router.push({ name: Route.MattersList })
                },
            },
            {
                id: 'matter-features',
                heading: t('matterCreate.steps.matterFeatures.heading'),
                description: t('matterCreate.steps.matterFeatures.description'),
            },
            // disabled for now
            // {
            //     id: 'company-alerts',
            //     heading: t('matterCreate.steps.companyAlerts.heading'),
            //     description: t('matterCreate.steps.companyAlerts.description'),
            //     eager: true,
            // },
            {
                id: 'matter-sharing',
                heading: t('matterCreate.steps.matterSharing.heading'),
                description: t('matterCreate.steps.matterSharing.description'),
            },
        ]
    }

    const titlesToAddAfterMatterCreate = computed(() => store.state.matter.titlesToAddAfterMatterCreate)
    const hasTitlesToAdd = computed(() => titlesToAddAfterMatterCreate?.value?.length)
    const titlesToAddText = computed(() => {
        if (titlesToAddAfterMatterCreate?.value?.length === 1) {
            return `Your matter will be created with Title ${ titlesToAddAfterMatterCreate.value[0] }.`
        }
        if (titlesToAddAfterMatterCreate?.value?.length > 1) {
            return `Your matter will be created with ${ titlesToAddAfterMatterCreate.value.length } Titles.`
        }
        return null
    })

    const stepData = computed(() => {
        // Return the data for all the steps
        return steps.value.reduce((acc, step) => {
            acc[step.id] = step.data
            return acc
        }, {})
    })

    onBeforeMount(() => {
        initSteps()
    })

    onActivated(() => {
        // Reset the steps when the component is activated
        key.value = useId()
    })

    onDeactivated(() => {
        // Reset the steps when the component is deactivated
        store.commit(MATTER_MUTATE_TITLES_TO_ADD_AFTER_MATTER_CREATE, null)
    })

    const setPermissions = async (data: IMatterCreateSharingStepData, matterId: number) => {
        if (data.shareType !== MatterShareType.Private) {
            await store.dispatch(MATTER_UPDATE_PERMISSIONS, {
                matterId,
                shareWithOrganisation: data.shareType === MatterShareType.All,
                userIds: data.selectedUsers,
            })
        }
    }

    const reset = () => {
        steps.value['create-matter'].data = {
            name: null,
            clientMatterCode: {
                clientCode: null,
                matterCode: null,
            },
            type: null,
        } as IMatterCreateDetailsStepData
        hasError.value = false
        stepIndex.value = 0
    }

    const createMatter = async (data: IMatterCreateDetailsStepData): Promise<{
        id: number
        type: string
        clientCode: string
    }> => {
        const matter = {
            name: data.name,
            code: data.clientMatterCode?.matterCode,
            clientCode: data.clientMatterCode?.clientCode,
            type: data.type,
            isCurrent: true,
        }

        let newMatter: {
            id: number
            type: string
            clientCode: string
        } | null = null
        try {
            newMatter = await store.dispatch(MATTER_CREATE, matter)
        } catch (e) {
            console.error(e)
            isMatterCreateErrorState.value = true
            isLoading.value = false
            reset()
            return null
        }
        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'MAT-CREATE - Matter created type',
            metadata: {
                matterId: newMatter.id,
                type: newMatter.type,
                isUsingClientCode: !isNullOrWhitespace(newMatter.clientCode),
            },
        })

        return newMatter
    }

    const setMatterFeatures = async (featureData: IMatterCreateFeaturesStepData, companyAlertData: IMatterCreateCompanyAlertsStepData,  matterId: number) => {
        if (featureData.toggleTitleAlerts) {
            // update asset monitoring settings
            await assetMonitoringStore.updateTitleNotificationSettings(matterId, {
                isActive: true,
                trackBoundary: true,
                trackDaylist: true,
                trackOcda: true,
                trackOwnership: true,
                trackCompaniesHouse: false,
                trackEpc: false,
                trackPlanningApplications: false,
            })
        }

        if (featureData.toggleCompanyAlerts) {
            const companyNumbers =  companyAlertData.currentGroup.items.map((item) => item.regNumber)
            try {
                await CompanyGroupsApi.createCompanyGroup({
                    groupName: companyAlertData.currentGroup.groupName,
                    companyNumbers,
                    isEnabled: true,
                    matterId,
                })
            } catch (err) {
                console.error(err)
                isMatterCreateErrorState.value = true
                isLoading.value = false
                return
            }
        }
    }


    const onSubmit = async (data: {
        ['create-matter']: IMatterCreateDetailsStepData
        ['matter-features']: IMatterCreateFeaturesStepData
        ['company-alerts']: IMatterCreateCompanyAlertsStepData
        ['matter-sharing']: IMatterCreateSharingStepData
    }) => {
        isLoading.value = true
        try {
            // create matter
            newMatter.value = await createMatter(data['create-matter'])
            if (!newMatter.value) {
                return
            }

            // set permissions
            await setPermissions(data['matter-sharing'], newMatter.value.id)

            // save matter features if step is not disabled
            const matterFeaturesStep = steps.value.find(step => step.id === 'matter-features')
            if (!matterFeaturesStep.disabled) {
                await setMatterFeatures(data['matter-features'], data['company-alerts'], newMatter.value.id)
            }

            if (titlesToAddAfterMatterCreate?.value?.length) {
                await store.dispatch(MATTER_ADD_MULTIPLE_TITLES, {
                    matterId: newMatter.value.id,
                    titleNumbers: titlesToAddAfterMatterCreate.value,
                })
                store.commit(MATTER_MUTATE_TITLES_TO_ADD_AFTER_MATTER_CREATE, null)
            }

            // redirect
            await router.push({
                name: titlesToAddAfterMatterCreate?.value ? Route.MatterMapTitle : Route.MatterMap,
                params: {
                    titleNumber: titlesToAddAfterMatterCreate?.value ? titlesToAddAfterMatterCreate.value[0] : undefined,
                    matterId: newMatter.value.id,
                },
            })

        } finally {
            isLoading.value = false
        }
    }

    defineExpose({
        newMatter,
        createMatter,
        reset,
        router,
    })

</script>

<style lang="scss">
    @import './matters-create';
</style>
