<template>
    <div class="users">
        <ow-loading-skeleton v-if="refreshingOrganisation"
                             class="my-4 w-25" />

        <seat-overview v-else
                       class="mb-4"
                       :active-witness-users="organisation?.activeWitnessUsers"
                       :max-witness-users="organisation?.maxWitnessUsers"
                       :active-copilot-users="organisation?.activeCopilotUsers"
                       :max-copilot-users="organisation?.maxCopilotUsers" />

        <v-autocomplete v-if="userIsSystemAdmin"
                        v-model="organisationId"
                        :clearable="true"
                        :items="organisations"
                        :label="$t('admin.user.label.filterByOrganisation')"
                        auto-select-first
                        data-test="users-header-filter-by-organisation"
                        hide-details
                        item-title="name"
                        item-value="organisationId"
                        style="width: 30vw;"
                        variant="underlined" />

        <user-dialog v-if="showCreateUserDialog"
                     v-model="showCreateUserDialog"
                     :clear-form="createNewUser.clearForm"
                     :error-message="createNewUser.errorMessage"
                     :loading="createNewUser.loading"
                     :organisations="organisations"
                     :selected-organisation-id="selectedOrganisationId"
                     :user-is-system-admin="userIsSystemAdmin"
                     create-user
                     @cancel="hideCreateUserDialog"
                     @submit="createUser" />

        <user-dialog v-if="showEditUserDialog"
                     v-model="showEditUserDialog"
                     :error-message="editUser.errorMessage"
                     :loading="editUser.loading"
                     :user="editUser.selectedUser"
                     :user-is-system-admin="userIsSystemAdmin"
                     :selected-organisation-id="editUser.organisationId"
                     :organisations="organisations"
                     @cancel="hideEditUserDialog"
                     @submit="updateUser" />

        <users-header :status-filter-options="statusFilterOptions"
                      :roles-filter-options="rolesFilterOptions"
                      :loading-users="refreshingUsers"
                      :organisations="organisations"
                      :users-current-organisation-id="usersCurrentOrganisationId"
                      :user-count="filteredUsers.length"
                      :selected-user-count="userLicenceManager.selectedUserIds.length"
                      :selected-witness-user-count="userLicenceManager.witnessUsers.length"
                      @search="setSearchValue"
                      @add-user="showCreateUserDialog = true"
                      @download-users="exportUsers"
                      @filter-organisation="getUsersForOrganisation"
                      @filter-status="selectedFilterStatus = $event"
                      @filter-role="selectedFilterRole = $event"
                      @filter-licence-type="selectedFilterLicenceType = $event"
                      @refresh-users="getUsersForOrganisation(selectedOrganisationId)"
                      @set-licences="toggleSetLicencesModal" />

        <users-table ref="usersTable"
                     :loading="refreshingUsers"
                     :selected-organisation-id="selectedOrganisationId"
                     :users="filteredUsers"
                     @edit-user="selectUser"
                     @row-selected="setSelectedUsers" />

        <user-licence-management v-model="showLicenceModal"
                                 :user-licence-manager="userLicenceManager"
                                 :organisation-id="selectedOrganisationId"
                                 :user-is-system-admin="userIsSystemAdmin"
                                 :active-lite-users-count="organisation?.activeLiteUsers"
                                 :max-lite-users-count="organisation?.maxLiteUsers"
                                 :active-standard-users-count="organisation?.activeStandardUsers"
                                 :max-standard-users-count="organisation?.maxStandardUsers"
                                 :active-premium-users-count="organisation?.activePremiumUsers"
                                 :max-premium-users-count="organisation?.maxPremiumUsers"
                                 @update-users="onSubmitLicenceUpdate"
                                 @close="toggleSetLicencesModal" />
    </div>
</template>

<script lang="ts">
    import {mapActions,
            mapGetters,
            mapState} from 'vuex'

    import OrganisationApi from '@/api/organisations.api'
    import UserApi from '@/api/user.api'
    import SeatOverview from '@/components/admin/seats/seat-overview.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import {USER_ROLES} from '@/consts/users'
    import {ApplicationRoles} from '@/enums/application-roles.enum'
    import {LicenceType} from '@/enums/licenceType'
    import { Route } from '@/enums/route.enum'
    import * as types from '@/store/mutation-types'
    import {LOGGING_HEAP_TRACK_EVENT} from '@/store/mutation-types'
    import {dynamicSort,
            isNullOrEmpty} from '@/utils/array-utils'
    import {exportAsCsv} from '@/utils/csv-utils'
    import {isNullOrWhitespace} from '@/utils/string-utils'
    import {getDisplayRoles,
            getFriendlyRoleName} from '@/utils/user.utils'

    import UserDialog from './user-dialog.vue'
    import UserLicenceManagement from './user-licence-management.vue'
    import { UserLicenceManager } from './user-licence-manager'
    import UsersHeader from './users-header.vue'
    import UsersTable from './users-table.vue'

    export default {
        name: 'Users',

        components: {
            OwLoadingSkeleton,
            UsersHeader,
            UsersTable,
            UserDialog,
            SeatOverview,
            UserLicenceManagement,
        },

        data() {
            return {
                users: [],
                organisations: [],
                selectedOrganisationId: null,
                selectedFilterStatus: [],
                selectedFilterRole: [],
                selectedFilterLicenceType: [],
                showCreateUserDialog: false,
                showEditUserDialog: false,
                createNewUser: {
                    errorMessage: null,
                    loading: false,
                    clearForm: false,
                },
                editUser: {
                    selectedUser: {},
                    errorMessage: null,
                    loading: false,
                },
                refreshingUsers: false,
                refreshingOrganisation: false,
                // I have no idea why the API would send back the default timestamp instead of null
                defaultTimestamp: '0001-01-01T00:00:00',
                searchValue: '',
                userLicenceManager: new UserLicenceManager(),
                showLicenceModal: false,
            }
        },

        computed: {
            ...mapState({
                usersCurrentOrganisationId: state => state.user.organisationId,
            }),

            ...mapGetters({
                userIsSystemAdmin: types.USER_IS_ADMIN,
                userIsOrgAdmin: types.USER_IS_ORGANISATION_ADMIN,
            }),

            organisationId: {
                get() {
                    return this.selectedOrganisationId !== null ? this.selectedOrganisationId : this.usersCurrentOrganisationId
                },
                set(newVal) {
                    this.getUsersForOrganisation(newVal)
                },
            },

            filteredUsers() {
                let users = this.users
                if (this.searchValue) {
                    users = users.filter((user) =>
                        user.name.toLowerCase().includes(this.searchValue.toLowerCase()) ||
                        user.email.toLowerCase().includes(this.searchValue.toLowerCase()),
                    )
                }

                // Only 2 options, so either nothing or both selected. Either way would do no filtering.
                if (!isNullOrEmpty(this.selectedFilterStatus) &&
                    this.selectedFilterStatus.length < 2) {
                    const isActiveFilter = this.selectedFilterStatus[0] === 'active'
                    users = users.filter((user) => isActiveFilter ? user.active : !user.active)
                }

                if (!isNullOrEmpty(this.selectedFilterRole)) {
                    users = users.filter(user => {
                        const userRoles = user.roles.split(',')
                        return this.selectedFilterRole.every(role => userRoles.includes(role))
                    })
                }

                if (!isNullOrEmpty(this.selectedFilterLicenceType)) {
                    users = users.filter((user) => {
                        return this.selectedFilterLicenceType.includes(user.licenceType)
                    })
                }

                return users
            },

            organisation() {
                return this.organisations.find(org => org.organisationId === this.selectedOrganisationId)
            },

            statusFilterOptions() {
                return [
                    {
                        title: 'Active',
                        value: 'active',
                    },
                    {
                        title: 'Inactive',
                        value: 'inactive',
                    },
                ]
            },

            rolesFilterOptions() {
                const roles = [
                    {
                        title: `${ this.$t('applicationRoles.owUser') }`,
                        value: ApplicationRoles.OwApp,
                    },
                    {
                        title: `${ this.$t('applicationRoles.copilot') }`,
                        value: ApplicationRoles.Copilot,
                    },
                    {
                        title: `${ this.$t('applicationRoles.residential') }`,
                        value: ApplicationRoles.Residential,
                    },
                    {
                        title: `${ this.$t('applicationRoles.orgAdmin') }`,
                        value: USER_ROLES.OrgAdmin,
                    },
                ]

                if (this.userIsSystemAdmin) {
                    roles.push({
                        title: `${ this.$t('applicationRoles.sysAdmin') }`,
                        value: USER_ROLES.SystemAdmin,
                    })
                }

                return roles
            },
        },

        mounted() {
            if (!this.userIsOrgAdmin && !this.userIsSystemAdmin) {
                this.$router.push({ name: Route.Homepage })
            }
            this.getOrganisations()
            this.getUsersForOrganisation(this.usersCurrentOrganisationId)
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            async getOrganisations() {
                this.refreshingOrganisation = true
                const response = await OrganisationApi.getSummaryOrganisations()
                if (response.ok) {
                    this.organisations = response.data.filter(x => x.name).sort(dynamicSort('name', false))
                    this.refreshingOrganisation = false
                } else {
                    console.error('getOrganisations: error getting organisations!', response)
                }
            },

            async getUsersForOrganisation(organisationId) {
                if (isNullOrWhitespace(organisationId)) {
                    this.users = []
                    return
                }
                this.selectedOrganisationId = organisationId

                this.refreshingUsers = true
                this.users = []
                const response = await UserApi.getUsersByOrganisationId(organisationId)
                if (response.ok) {
                    const mappedUsers = response.data.users.map(this.mapUser)
                    this.users = mappedUsers.sort(dynamicSort('name', false))
                } else {
                    console.error('getUsersForOrganisation: error getting users!', response)
                }
                this.refreshingUsers = false
            },

            getTimestampText(timestamp) {
                if (timestamp === this.defaultTimestamp) {
                    return 'Unknown'
                } else {
                    return timestamp.replace('T', ' ').substr(0, 19)
                }
            },

            async createUser(newUser) {
                this.createNewUser.errorMessage = null
                this.createNewUser.loading = true
                newUser.addToHubSpot = true
                newUser.productIds = newUser.products.filter(x => x.selected).map(x => x.id)

                if (newUser.organisationId === undefined || newUser.organisationId === 0) {
                    newUser.organisationId = this.selectedOrganisationId
                }

                try {
                    const response = await UserApi.create(newUser)
                    if (response.ok) {
                        // Could have added a user to another organisation, so no point refreshing the data
                        if (newUser.organisationId === this.selectedOrganisationId) {
                            await this.getUsersForOrganisation(this.selectedOrganisationId)
                        }
                        this.showCreateUserDialog = false
                        this.createNewUser.clearForm = true
                    } else {
                        if (isNullOrEmpty(response.data)) {
                            this.createNewUser.errorMessage = response.data
                        } else {
                            this.createNewUser.errorMessage = response.data.join(' ')
                        }
                    }
                } catch (error) {
                    this.createNewUser.error = true
                    this.createNewUser.errorMessage = error?.response?.data ?? 'An error occurred'
                }

                this.createNewUser.loading = false
            },

            async exportUsers() {
                const rows = [ [ this.$t('table.label.name'), this.$t('table.label.email'), this.$t('table.label.organisation'), this.$t('table.label.lastSeen'), this.$t('table.label.registered'), this.$t('table.label.roles'), this.$t('table.label.active') ] ]

                this.filteredUsers.forEach(user => {
                    rows.push([ user.name, user.email, user.organisation, user.lastSeen, user.registered, getDisplayRoles(user.roles), user.active.toString() ])
                })

                const exportOrganisationName = this.organisations.filter(o => o.organisationId === this.selectedOrganisationId)[0].name

                exportAsCsv(rows, `${ exportOrganisationName } - Users.csv`)
            },

            selectUser(user) {
                if (!user) {
                    console.error('users/admin-users.vue: Must select a user to edit!')
                }
                const userOrganisation = this.organisations.filter(o => o.organisationId === user.organisationId)[0]
                this.editUser.selectedUser = {
                    ...user,
                    licenceType: user?.licenceType ?? LicenceType.Standard,
                    orgAdmin: user.roles.includes(USER_ROLES.OrgAdmin),
                    admin: user.roles.includes(USER_ROLES.SystemAdmin),
                }
                this.editUser.organisationId = userOrganisation.organisationId

                this.showEditUserDialog = true
            },

            async updateUser(user) {
                this.editUser.loading = true
                this.editUser.errorMessage = null

                try {
                    const originalUser = this.users.find(u => u.id === user.id)
                    user.productIds = user.products.filter(x => x.selected).map(x => x.id)
                    const response = await UserApi.update(user)
                    if (response.ok) {
                        // can only edit users for the current organisation, so always need to refresh
                        const userToStore = {
                            ...this.users.find(u => u.id === user.id),
                            ...user,
                            organisation: user.organisation.name,
                        }
                        this.users = this.users.map(u => u.id === user.id ? userToStore : u)
                        this.editUser.selectedUser = null
                        this.showEditUserDialog = false

                        if (originalUser.active !== user.active) {
                            await this.logHeapEvent({
                                type: `ADMIN - User ${ user.active ? 'activated' : 'deactivated' }`,
                                metadata: {
                                    timestamp: new Date().toISOString(),
                                    organisation: user.organisation.name,
                                    userId: user.id,
                                },
                            })
                        }
                    } else {
                        if (isNullOrEmpty(response.data)) {
                            this.editUser.errorMessage = response.data
                        } else {
                            this.editUser.errorMessage = response.data.join(' ')
                        }
                    }
                } catch (error) {
                    this.editUser.error = true
                    this.editUser.errorMessage = error?.response?.data ?? 'An error occurred'
                }

                this.editUser.loading = false
                await this.getUsersForOrganisation(this.selectedOrganisationId)
            },

            mapUser(user) {
                return {
                    id: user.id,
                    name: `${ user.firstName } ${ user.lastName }`,
                    email: user.email,
                    organisation: user.organisation,
                    lastSeen: this.getTimestampText(user.lastSeen),
                    registered: this.getTimestampText(user.registered),
                    role: getFriendlyRoleName(user.roles),
                    active: user.active,
                    roles: user.roles,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    phone: user.phone,
                    organisationId: user.organisationId,
                    sectionId: user.sectionId,
                    products: user.products ?? [],
                    licenceType: user.licenceType ?? LicenceType.Standard,
                }
            },

            setSearchValue(value) {
                this.searchValue = value
            },

            hideCreateUserDialog() {
                this.showCreateUserDialog = false
                this.createNewUser = {
                    errorMessage: null,
                    loading: false,
                    clearForm: false,
                }
            },

            hideEditUserDialog() {
                this.showEditUserDialog = false
                this.editUser = {
                    errorMessage: null,
                    loading: false,
                    clearForm: false,
                }
            },

            setSelectedUsers(selectedUsers) {
                this.userLicenceManager.setSelectedUsers(this.users, selectedUsers)
            },

            onSubmitLicenceUpdate() {
                this.showLicenceModal = false
                this.userLicenceManager.reset()
                this.$refs.usersTable.clearSelectedRows()
                this.getUsersForOrganisation(this.organisationId)
            },

            toggleSetLicencesModal() {
                this.showLicenceModal = !this.showLicenceModal
            },
        },
    }
</script>

<style lang="scss">
    @import './users';
</style>
