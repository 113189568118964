<template>
    <article :class="{
                 'ow-action-card__banner': banner,
                 'ow-action-card': !banner
             }"
             data-test="ow-action-card">
        <div class="ow-action-card__top">
            <ow-icon v-if="svgIcon"
                     :icon="svgIcon" />
            <img v-else-if="banner"
                 width="100%"
                 :src="bannerSource"
                 fetchpriority="high"
                 height="75px"
                 alt="Action card banner" />
            <v-icon v-else
                    class="ow-action-card__icon"
                    data-test="ow-action-card-icon">
                {{ icon }}
            </v-icon>
            <div class="ow-action-card__title content__subheading"
                 data-test="ow-action-card-title">
                {{ title }}
            </div>
            <div class="ow-action-card__subtitle body-copy"
                 data-test="ow-action-card-subtitle">
                {{ subtitle }}
            </div>
        </div>
        <div class="ow-action-card__bottom">
            <slot />
        </div>
    </article>
</template>

<script lang="ts">
    import OwIcon from '@/components/core/ow-icon.vue'

    export default {
        name: 'OwActionCard',
        components: {
            OwIcon,
        },
        props: {
            icon: {
                type: String,
                required: false,
                default: null,
            },
            svgIcon: {
                type: String,
                required: false,
                default: null,
            },
            title: {
                type: String,
                required: true,
            },
            subtitle: {
                type: String,
                required: true,
            },
            banner: {
                type: Boolean,
                required: false,
            },
            bannerSource: {
                type: String,
                required: false,
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-action-card.scss';
</style>
