<template>
    <div class="am-alerts">
        <ow-loading-slot v-if="loading"
                         loading />
        <div v-else-if="splashPage"
             class="am-alerts__splash-page">
            <splash-page :title="splashTitle"
                         :button-title="t('action.enableMonitoring')"
                         :matter-id="matterId"
                         @button-click="$emit('splash-button-click', !splashPage)" />
        </div>
        <div v-else>
            <am-alert-filter v-model:items="items"
                             v-model:filters="selectedItems"
                             :disabled="showLoadingSkeleton"
                             @clear="$emit('clear')"
                             @check="$emit('item-check', $event)" />

            <ow-loading-slot :loading="showLoadingSkeleton">
                <am-data-grid :is-loading="assetMonitoringStore.isLoading"
                              :items="assetMonitoringStore.userNotifications"
                              :matter-id="matterId"
                              data-test="alert-monitors-grid"
                              class="am-alerts__data-grid"
                              @refresh="$emit('refresh')" />
            </ow-loading-slot>
        </div>
    </div>
</template>

<script setup lang="ts">

    import {
        computed,
        ref,
        watch,
    } from "vue"
    import { useI18n } from "vue-i18n"

    import AmAlertFilter from "@/components/asset-monitoring/am-alert-filter.vue"
    import AmDataGrid from "@/components/asset-monitoring/grid/data-grid.vue"
    import SplashPage from "@/components/asset-monitoring/splash-page.vue"
    import OwLoadingSlot from "@/components/core/ow-loading-slot.vue"
    import { IOptionLinkItem } from "@/interfaces/option-item.interface"
    import { useAssetMonitoringStore } from "@/stores/asset-monitoring"

    const { t } = useI18n()

    const props = withDefaults(defineProps<{
        matterId: number
        splashPage: boolean
    }>(), {
        splashPage: false,
    })

    const assetMonitoringStore = useAssetMonitoringStore()
    const isLoadingSettings = computed(() => assetMonitoringStore.isLoadingSettings)
    const isLoadingNotifications = computed(() => assetMonitoringStore.isLoading)
    const loading = ref<boolean>(true)
    const splashTitle = computed(() =>{
        return t('assetMonitoring.splashPage.assetMonitoring')
    })
    const items = defineModel('items', {
        type: Array as () => Array<IOptionLinkItem>,
        default: [],
    })
    const selectedItems = defineModel('selectedItems', {
        type: Array as () => Array<IOptionLinkItem>,
        default: [],
    })

    const showLoadingSkeleton = computed(() => {
        return isLoadingNotifications.value || isLoadingSettings.value
    })

    const emit = defineEmits<{
        (e: 'splash-button-click', active: boolean)
        (e: 'clear')
        (e: 'item-check', item: IOptionLinkItem)
        (e: 'refresh')
        (e: 'sort-by', value: string)
    }>()

    const fetch = async () => {
        await assetMonitoringStore.fetchNotifications(props.matterId)
    }

    defineExpose({
        fetch,
    })

    watch(() => props.matterId, (value, oldValue) => {
        if (value !== oldValue) {
            emit('clear')
        }
    })

    watch(() => loading, (val) => {
        if (val) {
            setTimeout(() => {
                loading.value = false
            }, 500)
        }
    }, {
        immediate: true,
    })
</script>

<style scoped lang="scss">
    @import './am-alerts';
</style>
