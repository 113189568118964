<template>
    <ow-tooltip :disabled="isRegisterAvailable"
                position="bottom">
        <template #activator="{ props }">
            <span :class="{ 'fill-height': fullHeight }"
                  v-bind="props">
                <ow-button v-if="!isDocumentPurchased"
                           :data-test="buttonDataTestAttribute"
                           :data-track="buttonText?.dataTrack"
                           :disabled="!isRegisterAvailable || isRegisterOrdering || isLoadingAvailability || !isOrderingAllowed"
                           :full-height="fullHeight"
                           :icon="isRegisterOrdering"
                           :class="buttonClass"
                           is-primary
                           @click="handleRegisterOrder">
                    <v-progress-circular v-if="isRegisterOrdering"
                                         :size="20"
                                         :width="3"
                                         color="primary"
                                         indeterminate />
                    <span v-else>{{ buttonText?.text }}</span>
                </ow-button>
                <slot />
            </span>
        </template>
        <span v-t="'documents.error.registerUnavailable'" />
    </ow-tooltip>
</template>

<script lang="ts">
    import { computed } from 'vue'
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { HmlrDocumentAvailabilityCode } from '@/consts/document-availability'
    import { DocumentOrderStatus } from '@/consts/document-order-status'
    import { DOCUMENT_SOURCE,
             getDataProviderText } from '@/consts/document-source'
    import { Route } from '@/enums/route.enum'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin.js'
    import { IHmlrDocumentAvailabilityCode } from '@/interfaces/document-availability.interface'
    import { IState } from '@/interfaces/store/state.interface'
    import { BasicTitleInformation } from '@/models/basic-title-information.model'
    import { CheckoutItemDocumentOrder } from '@/models/checkout/checkout-item-document-order.model'
    import { DocumentOrder } from '@/models/store/document-ordering/document-order.model'
    import { DocumentOrderRequest } from '@/models/store/document-ordering/document-order-request.model'
    import { ADD_ITEMS_TO_CART } from '@/store/modules/checkout/types'
    import { ORDER_DOCUMENTS } from '@/store/modules/document-ordering/types'
    import { MATTER_ADD_TITLE } from '@/store/modules/matter/types'
    import {
        TITLE_GET_IS_REGISTER_PURCHASED,
        TITLE_MUTATE_REGISTER_ORDER_LOADING,
    } from '@/store/modules/titles/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        USER_GET_DOCUMENT_CHARGE,
    } from '@/store/mutation-types'
    import { TENURE_FULL_FROM_CODE } from '@/utils/title-enums'

    interface IButtonText {
        text: string,
        disabled: boolean,
        dataTrack: string,
    }

    export default {
        name: 'OrderRegisterButton',

        components: {
            OwButton,
            OwTooltip,
        },

        mixins: [
            FeatureFlagsMixin,
        ],

        props: {
            currentMatterId: {
                type: [Number, String],
                required: false,
            },
            buttonDataTestAttribute: {
                type: String,
                required: false,
                default: 'order-register-button',
            },
            selectedTitleNumber: {
                type: String,
                required: false,
            },
            orderWithCheckout: {
                type: Boolean,
                required: false,
            },
            fullHeight: {
                type: Boolean,
                required: false,
            },
            buttonClass: {
                type: String,
                required: false,
            },
            isOrderingAllowed: {
                type: Boolean,
                default: true,
                required: false,
            },
        },

        computed: {
            ...mapState({
                registerAvailability: (state: IState) => state.title?.selectedTitle?.officialCopiesAvailability?.results?.titleRegister,
                isLoadingAvailability: (state: IState) => state.title?.selectedTitle?.officialCopiesAvailability?.loading,
                selectedTitle: (state: IState) => state.title.selectedTitle,
            }),

            ...mapGetters({
                documentCharge: USER_GET_DOCUMENT_CHARGE,
                isDocumentPurchased: TITLE_GET_IS_REGISTER_PURCHASED,
            }),

            isRegisterOrdering() {
                return Boolean(this.registerAvailability?.loadingOrder)
            },

            isScottishTitle() {
                return this.selectedTitle?.record?.source === DOCUMENT_SOURCE.SCOTLAND
            },

            isRegisterAvailable(): boolean {
                if (this.selectedTitle?.record?.tenure === TENURE_FULL_FROM_CODE.CN) {
                    return false
                }
                if (this.isScotlandLayerEnabled && this.isScottishTitle) {
                    return true
                }
                return this.registerAvailability?.hmlrAvailabilityCode === HmlrDocumentAvailabilityCode.Available
            },

            isRegisterUnderInvestigation() {
                return this.registerAvailability?.orderStatus === DocumentOrderStatus.UNDER_INVESTIGATION
            },

            isRegisterBackdated(): boolean {
                return Boolean(this.registerAvailability?.hmlrAvailableVersionIsBackdated)
            },

            documentAvailabilityCode(): IHmlrDocumentAvailabilityCode {
                return this.registerAvailability?.hmlrAvailabilityCode
            },

            isButtonDisabled() {
                if (this.isScotlandLayerEnabled && this.isScottishTitle) {
                    return false
                }
                return this.documentAvailabilityCode === HmlrDocumentAvailabilityCode.Unavailable ||
                    this.documentAvailabilityCode === HmlrDocumentAvailabilityCode.NotImmediatelyAvailable ||
                    this.isRegisterUnderInvestigation
            },

            isFailedOrder() {
                return Boolean(this.registerAvailability?.failedOrder)
            },

            buttonText(): IButtonText {
                if (this.isScotlandLayerEnabled) {
                    return {
                        text: this.orderWithCheckout
                            ? this.$t('action.order')
                            : this.$t('action.orderWithCost', { cost: this.documentCharge }),
                        disabled: false,
                        dataTrack: this.isScottishTitle // TODO: might be useful to always use this text but unsure what relies on original, then can remove this check
                            ? `TITLE-DETAILS-PANEL - Order register from ${ getDataProviderText(this.selectedTitle?.record?.source) }`
                            : 'TITLE-DETAILS-PANEL - Order register',
                    }
                }
                if (!this.isDocumentPurchased && (!this.isRegisterBackdated || this.hideBackDatedStates && this.isRegisterBackdated)) { // Register not yet requested
                    return {
                        text: this.orderWithCheckout
                            ? this.$t('action.orderRegister')
                            : this.$t('action.orderRegisterWithCost', { cost: this.documentCharge }),
                        disabled: this.isButtonDisabled,
                        dataTrack: 'TITLE-DETAILS-PANEL - Order register',
                    }
                }
                if (!this.isDocumentPurchased && this.isRegisterBackdated) { // Order Backdated register
                    return {
                        text: this.orderWithCheckout
                            ? this.$t('action.orderRegisterBackdated')
                            : this.$t('action.orderRegisterBackdatedWithCost', { cost: this.documentCharge }),
                        disabled: this.isButtonDisabled,
                        dataTrack: 'TITLE-DETAILS-PANEL - Order register',
                    }
                }
                return null
            },
        },

        methods: {
            ...mapActions({
                addTitleToMatter: MATTER_ADD_TITLE,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            ...mapActions('documentOrdering', {
                orderDocuments: ORDER_DOCUMENTS,
            }),

            ...mapActions('checkout', {
                addRegisterToCart: ADD_ITEMS_TO_CART,
            }),

            ...mapMutations({
                setOrderRegisterLoading: TITLE_MUTATE_REGISTER_ORDER_LOADING,
            }),

            async handleRegisterOrder(): Promise<void> {
                if (this.orderWithCheckout) {
                    const order = new CheckoutItemDocumentOrder(
                        this.selectedTitleNumber,
                        new BasicTitleInformation(
                            this.selectedTitleNumber,
                            this.selectedTitle?.record?.tenure,
                            this.selectedTitle?.record?.addresses[0]?.address),
                        new DocumentOrder(
                            this.selectedTitleNumber,
                            DocumentOrderRequest.createRegisterRequest(this.selectedTitleNumber, this.currentMatterId, false),
                            this.registerAvailability?.hmlrAvailabilityCode,
                        ),
                        false)
                    await this.addRegisterToCart([order])
                    await this.$router.push({ name: Route.CheckoutReview })
                } else {
                    this.setOrderRegisterLoading(true)
                    const request = DocumentOrderRequest.createRegisterRequest(
                        this.selectedTitleNumber,
                        this.currentMatterId,
                        this.isFailedOrder)
                    const response = await this.orderDocuments([request])
                    if (response === undefined || response?.ok === false) {
                        this.setOrderRegisterLoading(false)
                    }
                    await this.addTitleToMatter({
                        titleNumber: this.selectedTitleNumber,
                        show: true,
                    })
                }

                await this.logHeapEvent({
                    type: 'Title Register Ordered',
                    metadata: {
                        titleNumber: this.selectedTitleNumber,
                        matterId: this.currentMatterId,
                        documentSource: getDataProviderText(this.selectedTitle?.record?.source),
                    },
                })
            },
        },
    }
</script>

<style lang="scss"
       scoped>
@import './order-register-button';
</style>
