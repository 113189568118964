<template>
    <base-monitoring-preference-card :card-type="t('assetMonitoring.preferences.cards.presets.cardTitle')"
                                     :card-type-description="t('assetMonitoring.preferences.cards.presets.cardDescription')"
                                     class="preference-presets-card">
        <ul class="preference-presets-card__options">
            <li :class="{'is-selected': isOwRecommendedSelected}"
                @click="setOwRecommended">
                <h4 class="caption-highlight">
                    {{ t(`assetMonitoring.preferences.cards.presets.basicAlerts`) }}
                </h4>
                <p class="caption-regular">
                    {{ t(`assetMonitoring.preferences.cards.presets.basicTitleAlertsDescription`) }}
                </p>
            </li>
        </ul>
    </base-monitoring-preference-card>
</template>

<script lang="ts" setup>
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { ITitleNotificationSettings } from '@/api/notifications.api'
    import BaseMonitoringPreferenceCard from "@/components/asset-monitoring/monitoring-preferences/base-monitoring-preference-card.vue"


    const { t } = useI18n()
    const emit = defineEmits<{
        (event: 'update', value: ITitleNotificationSettings): void
    }>()

    const props = defineProps<{
        settings: ITitleNotificationSettings,
    }>()

    // OW Recommended card
    const isOwRecommendedSelected = computed<boolean>(() => {
        return props.settings.trackDaylist &&
            props.settings.trackOwnership &&
            props.settings.trackBoundary &&
            (!props.settings.trackEpc && !props.settings.trackCompaniesHouse && !props.settings.trackPlanningApplications)
    })
    const setOwRecommended = () => {
        const prefs: ITitleNotificationSettings = {
            trackDaylist: true,
            trackOwnership: true,
            trackBoundary: true,
            trackOcda: true,
            trackEpc: false,
            trackCompaniesHouse: false,
            trackPlanningApplications: false,
        }
        emit('update', prefs)
    }

</script>

<style lang="scss" scoped>
    @import './preference-presets-card';
</style>
