<template>
    <div class="ow-date-picker">
        <input id="datepicker" />
    </div>
</template>

<script lang="ts" setup>
    import { easepick } from '@easepick/core'
    import { DateTime } from '@easepick/datetime'
    import { LockPlugin } from '@easepick/lock-plugin'
    import { PresetPlugin } from '@easepick/preset-plugin'
    import { RangePlugin } from '@easepick/range-plugin'
    import {
        onActivated,
        onDeactivated,
        onMounted,
        onUnmounted,
        ref,
        watch,
    } from 'vue'

    import { format } from '@/utils/date-utils'

    const emits = defineEmits<{
        (e: 'change', value: string[]),
    }>()

    const props = defineProps<{
        dateSince: Date | null,
        dateBefore: Date | null,
        disableFutureDates?: boolean,
    }>()

    const startDate = ref<DateTime>(null)
    const endDate = ref<DateTime>(null)

    let datePicker: any
    const initialiseDatePicker = () => {
        if (!datePicker) {
            new easepick.create({
                element: '#datepicker',
                css: [
                    'https://cdn.jsdelivr.net/npm/@easepick/core@1.2.1/dist/index.css',
                    'https://cdn.jsdelivr.net/npm/@easepick/range-plugin@1.2.1/dist/index.css',
                    'https://cdn.jsdelivr.net/npm/@easepick/preset-plugin@1.2.1/dist/index.css',
                    'https://cdn.jsdelivr.net/npm/@easepick/lock-plugin@1.2.1/dist/index.css',
                    '/ow-easepick-theme.css',
                ],
                format: 'DD MMM YYYY',
                calendars: 1,
                zIndex: 999,
                plugins: [
                    LockPlugin,
                    PresetPlugin,
                    RangePlugin,
                ],
                RangePlugin: {
                    tooltip: true,
                },
                LockPlugin: {
                    maxDate: props.disableFutureDates ? new Date().toISOString() : '',
                },
                setup(picker) {
                    picker.on('select', (e) => {
                        emits('change', [ format(e.detail.start, 'yyyy-MM-dd'), format(e.detail.end, 'yyyy-MM-dd') ])
                    })

                    datePicker = picker
                    setStartDate(props.dateSince)
                    setEndDate(props.dateBefore)
                },
            })
        }
    }

    const setStartDate = (newDate: Date): void => {
        if (newDate) {
            startDate.value = new DateTime(newDate)

            datePicker?.setStartDate(startDate.value)
        }
    }

    const setEndDate = (newDate: Date):  void => {
        if (newDate) {
            endDate.value = new DateTime(newDate)

            datePicker?.setEndDate(endDate.value)
        }
    }

    watch(() => props.dateSince, (newDate: Date) => {
        setStartDate(newDate)
    })
    watch(() => props.dateBefore, (newDate: Date) => {
        setEndDate(newDate)
    })

    onMounted(() => {
        initialiseDatePicker()
    })

    onUnmounted(() => {
        startDate.value = null
        endDate.value = null
        datePicker.destroy()
        datePicker = null
    })

    onActivated(() => {
        setStartDate(props.dateSince)
        setEndDate(props.dateBefore)
    })

    onDeactivated(() => {
        startDate.value = null
        endDate.value = null
    })
</script>

<style lang="scss">
@import './ow-date-picker';
</style>
