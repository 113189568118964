<template>
    <div class="title-alerts-preferences d-flex flex-column gr-4">
        <section class="email-preferences__content">
            <preference-details-card :is-loading="isUpdatingSettings"
                                     :settings="settings"
                                     class="monitoring-preferences__content--row"
                                     :preference-properties="matterPreferenceProperties"
                                     data-test="email-preferences-matter-settings"
                                     hide-header
                                     @update="updateMatterSettings">
                <ow-field v-if="settings?.dailySummaryEmailEnabled"
                          class="my-3"
                          :label="t('label.emailFrequency')">
                    <ow-select v-model="emailFrequency"
                               is-single-line
                               hide-details
                               is-outlined
                               is-dense
                               :items="emailFrequencies"
                               data-test="email-frequency-select" />
                </ow-field>
            </preference-details-card>
        </section>

        <section class="email-preferences__content">
            <preference-details-card :is-loading="isUpdatingUserEmailPreferences"
                                     :settings="userEmailPreferences"
                                     class="monitoring-preferences__content--row"
                                     :preference-properties="userPreferenceProperties"
                                     :disabled="!store.titleNotificationSettings.dailySummaryEmailEnabled"
                                     data-test="email-preferences-user-settings"
                                     hide-header
                                     @update="updateUserSettings" />
        </section>
    </div>
</template>

<script setup lang="ts">

    import {
        computed,
        onMounted,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import {useRoute} from "vue-router"

    import {ITitleNotificationSettings} from '@/api/notifications.api'
    import {IUserEmailPreferences} from '@/api/user-email-preferences.api'
    import PreferenceDetailsCard from "@/components/asset-monitoring/monitoring-preferences/preference-details-card.vue"
    import {IPreferenceDetailsModel} from "@/components/asset-monitoring/monitoring-preferences/preference-details-model-interface"
    import OwField from "@/components/core/ow-field.vue"
    import OwSelect from "@/components/core/ow-select.vue"
    import { checkFlag } from "@/feature-flags"
    import {useAssetMonitoringStore} from "@/stores/asset-monitoring"

    const { t } = useI18n()
    const route = useRoute()
    const store = useAssetMonitoringStore()
    const currentMatterId = computed<number>(() => Number(route.params.matterId))
    const settings = computed<ITitleNotificationSettings>(() => store.titleNotificationSettings)
    const userEmailPreferences = computed<IUserEmailPreferences>(() => store.userEmailPreferences)
    const isUpdatingSettings = computed<boolean>(() => store.isUpdatingSettings)
    const isUpdatingUserEmailPreferences = computed<boolean>(() => store.isUpdatingUserEmailPreferences)
    const emailFrequencies = ref<Array<string>>([
        t('label.daily'),
        t('label.weekly'),
        t('label.monthly'),
    ])

    onMounted(async () => {
        if (!store.areTitleNotificationSettingsLoaded) {
            await store.getTitleNotificationSettings(currentMatterId.value)
        }

        if (!store.areUserEmailPreferencesLoaded) {
            await store.fetchUserEmailPreferences(currentMatterId.value)
        }
    })

    const getFriendlyNameForEmailFrequency = (emailFrequency: string) => {
        switch (emailFrequency) {
            case 'daily':
                return t('label.daily')
            case 'weekly':
                return t('label.weekly')
            case 'monthly':
                return t('label.monthly')
            default:
                return emailFrequency
        }
    }

    const emailFrequency = computed({
        get: () => getFriendlyNameForEmailFrequency(store?.titleNotificationSettings?.emailFrequency),
        set: (value: string) => {
            store.titleNotificationSettings.emailFrequency = value
            updateMatterSettings(store.titleNotificationSettings)
        },
    })

    const matterPreferenceProperties = ref<Array<IPreferenceDetailsModel>>(
        [
            {
                value: 'dailySummaryEmailEnabled',
                getTranslationKey: () => {
                    return 'enableEmailNotifications'
                },
            },
        ],
    )

    const userPreferenceProperties = ref<Array<IPreferenceDetailsModel>>(
        [
            {
                value: 'optedIn',
                getTranslationKey: () => {
                    return 'enableEmailOptIn'
                },
            },
        ],
    )

    const updateMatterSettings = async (request: ITitleNotificationSettings) => {
        await store.updateTitleNotificationSettings(currentMatterId.value, request)
    }

    const updateUserSettings = async (request: IUserEmailPreferences) => {
        await store.updateUserEmailPreferences(currentMatterId.value, request)
    }
</script>
