import { easeOut } from 'ol/easing.js'
import Feature from 'ol/Feature'
import Geometry from 'ol/geom/Geometry'
import Point from 'ol/geom/Point'
import VectorLayer from 'ol/layer/Vector'
import WebGLPointsLayer from 'ol/layer/WebGLPoints'
import olMap from 'ol/Map'
import { unByKey } from 'ol/Observable.js'
import { getVectorContext } from 'ol/render.js'
import VectorSource from 'ol/source/Vector'
import {
    Circle as CircleStyle,
    Stroke,
    Style,
} from 'ol/style.js'

import { Colors } from '@/enums/colors.enum'
import {
    PointData,
    PointLayerInitialisationParams,
} from '@/store/modules/map/layers/title-boundary-layer/point-layer'
import { TitleBoundaryLayerSettings } from '@/store/modules/map/layers/title-boundary-layer/settings'
import { ISearchResultOwner } from '@/store/modules/search/types/search-result-interface'
import { isNullOrEmpty } from '@/utils/array-utils'
import { hexToRGBArray } from '@/utils/colour-utils'
import { enableWebGlLayerReloadWorkaround,
    layerEquals } from '@/utils/map-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'
import {
    getOLStyleForOWStyleDefinition,
    StyleTarget,
} from '@/utils/style-utils'

export interface ISearchResultPoint extends PointData {
    // Search result text to display in a rollover
    result: string,
    // Show the point as selected colour
    selected: string,
}

const Settings = {
    ShowResultsAtZoomLevelAndLower: 12,
    DefaultPointColour: '#b5b9bd',
    HighlightPointColour: '#515d65',
    SelectedPointColour: '#1d73ad',
}

/**
 * The point layer is used to provide a rough indication as to the location of a title, intended to be faster than loading detailed boundaries.
 * It loads all titles provided. In the future this can be replaced with clustering (with clusters computed on the server).
 * It uses WebGL, so if users have that disabled for some reason, none of this will work. We should consider fallbacks, matter size limits and comms etc.
 */
export class SearchResultsLayer {
    private map: olMap
    private highlightedTitleNumber: string
    public layer: WebGLPointsLayer<VectorSource<Point>>
    public readonly highlightLayer: VectorLayer<VectorSource<Geometry>>
    public readonly getTitlesDataFn: () => any
    private readonly onPointClickFn: (titleNumber: string[]) => void
    private pointData: ISearchResultPoint[] = []
    private titleNumbersLoaded: Set<string> = new Set<string>()
    private readonly styleCache: Map<any, Style | Style[]>
    private clickedTitleNumbers: string[] = []
    private readonly featureTitleKey = 'title'
    private readonly interactive: boolean = true
    private readonly overFeaturesClassName = 'ow-over-layer-search-result-points'
    private selectedTitles = []
    private iconSrc = new URL("../../../../media/owner-pin.webp", import.meta.url).href
    private reloadStyle: boolean = false

    constructor(params: PointLayerInitialisationParams) {
        this.getTitlesDataFn = params.getTitlesDataFn
        this.onPointClickFn = params.onPointClickFn
        this.styleCache = new Map()
        this.interactive = params.interactive

        // Initialise the web gl layer
        this.layer = new WebGLPointsLayer<VectorSource<Point>>({
            zIndex: 15,
            source: new VectorSource(),
            style: this.style(),
        })
        enableWebGlLayerReloadWorkaround(this.layer, () => this.map)

        // Initialise the Vector highlight layer used for text highlights.
        this.highlightLayer = new VectorLayer({
            zIndex: 20,
            source: new VectorSource(),
            style: (feature:Feature) => {
                // Style may have been generated for this feature already
                const titleNumber = TitleBoundaryLayerSettings.getTitleNumberFromFeature(feature)

                // Check for a style generated previously for this title.
                const cachedStyle = this.styleCache.get(titleNumber)
                if (cachedStyle) {
                    return cachedStyle
                }

                // Not generated, generate and add to cache
                const title = params.getTitlesDataFn().find((item: ISearchResultOwner) => item.titleNumbers.includes(titleNumber))

                if (title) {
                    const newStyle = getOLStyleForOWStyleDefinition(title, StyleTarget.Boundary)
                    this.styleCache.set(titleNumber, newStyle)
                    return newStyle
                }

                return null
            },
        })

        this.highlightLayer.getSource().on('addfeature', (event) => {
            this.flash(event.feature)
        })
    }

    private style() {
        return {
            'icon-src': this.iconSrc,
            'icon-width': 16,
            'icon-height': 25,
            'icon-color': [
                'match',
                true,
                ['in', ['get', 'title'], ['literal', this.selectedTitles]], '#e98e45',
                '#1e73ae',
            ],
            'icon-rotate-with-view': false,
            'icon-displacement': [0, 9],
        }
    }

    // Refreshed the layer e.g. when something has changed and it needs to be redrawn.
    public refresh(): void {
        if (isNullOrEmpty(this.pointData)) {
            return
        }
        // NOTE: There's no setStyle for webGL layers due to the new renderer
        if (this.reloadStyle && this.layer) {
            this.map.removeLayer(this.layer)
            this.layer.dispose()
            this.layer = new WebGLPointsLayer<VectorSource<Point>>({
                zIndex: 15,
                source: new VectorSource(),
                style: this.style(),
            })
            enableWebGlLayerReloadWorkaround(this.layer, () => this.map)
            this.map.addLayer(this.layer)
            this.reloadStyle = false
        }

        this.layer.setOpacity(0)

        // Everything needs refreshing - add all points
        this.layer.getSource().clear()
        const features: Feature<Point>[] = this.pointData.map(x => {
            // Some titles do not have boundaries, so we don't want to consider them.
            if (x.x === null || x.y === null) {
                return undefined
            }
            const titleMetadata = this.getTitlesDataFn()
                .find((y: ISearchResultOwner) => y.titleNumbers.includes(x.t))

            if (titleMetadata) {
                const styleProperties = this.getWebGlStylePropertiesForTitle()

                const feature = new Feature({
                    geometry: new Point([x.x, x.y]),
                    title: x.result,
                    ...styleProperties,
                })
                feature.setId(x.t)
                return feature
            }
            return undefined
        }).filter(x => Boolean(x))

        this.layer.getSource().addFeatures(features)
        this.layer.changed()
        this.layer.setOpacity(1)
    }

    public updateSelectedTitles(newSelectedTitleNumbers: string[]): void {
        if (JSON.stringify(this.selectedTitles) !== JSON.stringify(newSelectedTitleNumbers)) {
            this.selectedTitles = newSelectedTitleNumbers
            this.reloadStyle = true
        }
    }

    // Resets the layer and loads all of the point data needed for the associated titles.
    public async reload(): Promise<void> {
        // Reset store of title numbers loaded.
        this.titleNumbersLoaded.clear()
        this.removeAllSelectedFeatures()

        // Get title numbers passed in.
        const titleNumbers = this.getTitlesDataFn()
            .filter((x: any) => x.show)
            .map((item: any) => item.titleNumber)

        // Get point data for title numbers.
        this.convertTitlesDataToPoints()
        this.titleNumbersLoaded = new Set(titleNumbers)

        // Redraw the layer.
        this.refresh()
    }

    /**
     * Sets the visibility of the layer.
     * @param visible
     */
    public setVisible(visible: boolean): void {
        this.layer.setVisible(visible)
        this.highlightLayer.setVisible(visible)
        this.refresh()
    }

    // Zooms to the extent of the point data.
    public zoomToExtent() {
        if (this.layer.getSource().getFeatures().length) {
            this.map.getView().fit(this.layer.getSource().getExtent(), {
                duration: 500,
                padding: [100, 100, 100, 100],
                minResolution: 0.66,
            })
        }
    }

    // Converts the title data to point data.
    public convertTitlesDataToPoints(): void {
        const newPointData: ISearchResultPoint[] = this.getTitlesDataFn()
            .map((item: ISearchResultOwner) => item.titleData
                .map(td => ({
                    t: td.titleNumber,
                    x: td.x,
                    y: td.y,
                    result: td.titleNumber,
                    visible: true,
                    selected: false,
                })))
            .flat()
        this.pointData = newPointData
    }

    public setMap(map: olMap): void {
        this.map = map

        this.map.addLayer(this.highlightLayer)
        this.map.addLayer(this.layer)

        this.initialiseMapEvents()
    }

    private initialiseMapEvents(): void {
        if (this.interactive) {
            this.map.on('singleclick', (e) => {
                if (!this.layer.getVisible()) {
                    return
                }

                this.clickedTitleNumbers = []
                const pixel = this.map.getEventPixel(e.originalEvent)
                this.map.forEachFeatureAtPixel(pixel, (feature) => {
                    const titleNumber = feature.get(this.featureTitleKey)
                    if (titleNumber) {
                        this.clickedTitleNumbers.push(titleNumber)
                    }
                }, {
                    layerFilter: currentLayer => layerEquals(this.layer, currentLayer),
                })
                this.onPointClickFn(this.clickedTitleNumbers)
                this.map.getTargetElement().classList.remove(this.overFeaturesClassName)
            })

            this.map.on('pointermove', (e) => {
                // NOTE: Don't attempt to highlight if there's too many titles on the map
                if (!this.layer.getVisible() || this.pointData.length > 1000) {
                    return
                }

                const pixel = this.map.getEventPixel(e.originalEvent)
                const isHoveringOverTitle = this.map.forEachFeatureAtPixel(pixel, this.onTitleNumberHoverHandler.bind(this), {
                    layerFilter: currentLayer => layerEquals(this.layer, currentLayer),
                })
                if (!isHoveringOverTitle) {
                    this.highlightFeaturesByTitleNumber(null)
                    this.map.getTargetElement().classList.remove(this.overFeaturesClassName)
                }
            })
        }
    }

    public selectFeaturesByTitleNumber(titleNumbers: string[]): void {
        this.removeAllSelectedFeatures()

        if (!isNullOrEmpty(titleNumbers)) {
            titleNumbers.forEach(titleNumber => {
                const feature = this.layer.getSource().getFeatureById(titleNumber)
                if (feature) {
                    feature.setProperties({ selected: true })
                }
            })
        }
    }

    private removeAllSelectedFeatures(): void {
        if (this.layer.getVisible()) {
            this.layer.getSource().getFeatures().forEach((feature) => {
                feature.setProperties({ selected: false })
            })
            this.layer.getSource().changed()
        }
    }

    // Called when needing to highlight a title e.g. on mouse over.
    public highlightFeaturesByTitleNumber(titleNumber: string): void {
        if (this.highlightedTitleNumber === titleNumber) {
            return
        }

        this.removeAllHighlightFeatures()

        this.addHighlightFeatureByTitleNumber(titleNumber)
    }

    private addHighlightFeatureByTitleNumber(titleNumber: string): void {
        if (this.highlightLayer.getVisible() &&
            !isNullOrWhitespace(titleNumber)) {
            const titleData = this.getTitlesDataFn().find((item: ISearchResultOwner) => item.titleNumbers.includes(titleNumber))
            if (titleData) {
                titleData.label = `${ titleData.text } (${ titleData.companyRegistrationNumbers[0] })\nTitle number: ${ titleNumber }`
                const highlightStyle =
                    TitleBoundaryLayerSettings.getHighlightStyle(titleData, true, -18)
                this.styleCache.set(titleNumber, highlightStyle)
                const feature = this.layer.getSource().getFeatureById(titleNumber)
                const geometry = feature?.getGeometry()
                if (geometry) {
                    this.highlightLayer
                        .getSource()
                        .addFeature(new Feature({
                            geometry,
                            text: highlightStyle.getText().getText(), // Get the text string from the text object.
                            titleNumber,
                        }))
                }
            }
            this.highlightLayer.getSource().changed()
            this.highlightedTitleNumber = titleNumber
        }
    }

    private removeAllHighlightFeatures(): void {
        if (this.highlightLayer.getVisible()) {
            this.highlightLayer.getSource().getFeatures().forEach((feature) => {
                const featureTitleNumber = feature.get('titleNumber')
                this.styleCache.delete(featureTitleNumber)
                this.highlightLayer.getSource().removeFeature(feature)
            })
            this.highlightLayer.getSource().changed()
            this.highlightedTitleNumber = null
        }
    }

    /** Returns the web gl layer style for a given title, this is different to the Style object used for the majority of other OpenLayers
     * style properties - see the OpenLayers examples for more information, this is likely to need updating if they adopt Style here too. */
    private getWebGlStylePropertiesForTitle(): any {
        const rgbArray: number[] = hexToRGBArray(Settings.DefaultPointColour)
        return {
            r: rgbArray[0],
            g: rgbArray[1],
            b: rgbArray[2],
            show: 'true',
        }
    }

    private flash(feature: Feature): void {
        const duration = 500
        const start = Date.now()
        const flashGeom = feature?.getGeometry().clone()
        if (flashGeom) {
            const animate = (event: any) => {
                const frameState = event.frameState
                const elapsed = frameState.time - start
                if (elapsed >= duration) {
                    unByKey(listenerKey)
                    return
                }
                const vectorContext = getVectorContext(event)
                const elapsedRatio = elapsed / duration
                // radius will be 5 at start and 30 at end.
                const radius = easeOut(elapsedRatio) * 25 + 5
                const opacity = easeOut(1 - elapsedRatio)

                const style = new Style({
                    image: new CircleStyle({
                        radius,
                        stroke: new Stroke({
                            color: Colors.Lights0,
                            width: 0.5 + opacity,
                        }),
                    }),
                })

                vectorContext.setStyle(style)
                vectorContext.drawGeometry(flashGeom)
                // tell OpenLayers to continue postrender animation
                this.highlightLayer.getSource().changed()
            }

            const listenerKey = this.highlightLayer.on('postrender', animate)
        }
    }

    // Handles highlighting a given feature.
    private onTitleNumberHoverHandler(feature: Feature): boolean {
        const titleNumber = feature.get(this.featureTitleKey)
        if (titleNumber) {
            this.highlightFeaturesByTitleNumber(titleNumber)
            this.map.getTargetElement().classList.add(this.overFeaturesClassName)
            return true
        }
    }
}
