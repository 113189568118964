import {
    createVuetify,
    ThemeDefinition,
    VuetifyOptions,
} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {
    aliases,
    mdi,
} from 'vuetify/iconsets/mdi'

const owIcons = {
    add: 'mdi-plus',
    alien: 'mdi-alien',
    autorenew: 'mdi-autorenew',
    back: 'mdi-chevron-left',
    buy: 'mdi-cart',
    calendar: 'mdi-calendar',
    check: 'mdi-check',
    close: 'mdi-close',
    copy: 'mdi-content-copy',
    dashboard: 'mdi-view-dashboard',
    daylist: 'mdi-clipboard-clock-outline',
    delete: 'mdi-delete',
    document: 'mdi-file-document',
    titleSheet: 'mdi-file-document-check-outline',
    documents: 'mdi-folder-open',
    download: 'mdi-download',
    drag: 'mdi-drag',
    edit: 'mdi-pencil',
    eraser: 'mdi-eraser',
    error: 'mdi-close-circle',
    export: 'mdi-download',
    filter: 'mdi-filter-variant',
    findNearby: 'mdi-map-marker-radius',
    flag: 'mdi-flag',
    history: 'mdi-history',
    home: 'mdi-home',
    info: 'mdi-information-outline',
    key: 'mdi-tune-variant',
    lock: 'mdi-lock',
    logout: 'mdi-exit-to-app',
    map: 'mdi-map-outline',
    matter: 'mdi-folder',
    matters: 'mdi-folder-multiple',
    minus: 'mdi-minus',
    notification: 'mdi-bell',
    organisation: 'mdi-domain',
    planning: 'mdi-view-list',
    point: 'mdi-brightness-1',
    refresh: 'mdi-refresh',
    remove: 'mdi-minus',
    register: 'mdi-file-document',
    report: 'mdi-file-chart-outline',
    reporting: 'mdi-file-document-edit',
    search: 'mdi-magnify',
    settings: 'mdi-cog',
    share: 'mdi-share-variant',
    sketches: 'mdi-pencil',
    slideshow: 'mdi-play-box-outline',
    snapshot: 'mdi-camera',
    sort: 'mdi-sort',
    sortAscending: 'mdi-sort-ascending',
    sortDescending: 'mdi-sort-descending',
    stop: 'mdi-stop',
    styling: 'mdi-palette',
    target: 'mdi-target',
    templates: 'mdi-book-open-variant',
    timeline: 'mdi-chart-timeline-variant',
    title: 'mdi-domain',
    users: 'mdi-face',
    visibility: 'mdi-eye',
    warning: 'mdi-alert',
    zip: 'mdi-zip-box',

    'arrow-expand': 'mdi-arrow-expand',
    'arrow-collapse': 'mdi-arrow-collapse',
    'arrow-target': 'mdi-arrow-right-circle-outline',
    'account-circle': 'mdi-account-circle',
    'account-circle-outline': 'mdi-account-circle-outline',
    'account-plus': 'mdi-account-plus',
    'account-multiple': 'mdi-account-multiple',
    'account-user': 'mdi-account',
    'account-user-lock': 'mdi-account-off',
    'account-user-premium': 'mdi-account-star',
    'add-matter': 'mdi-folder-plus',
    'add-user': 'mdi-account-plus',
    'fit-to-width': 'mdi-arrow-left-right',
    'fit-to-height': 'mdi-arrow-up-down',
    'calendar-today': 'mdi-calendar-today',
    'chevron-double-right': 'mdi-chevron-double-right',
    'chevron-double-left': 'mdi-chevron-double-left',
    'chevron-down': 'mdi-chevron-down',
    'chevron-left': 'mdi-chevron-left',
    'chevron-right': 'mdi-chevron-right',
    'chevron-up': 'mdi-chevron-up',
    'cloud-download': 'mdi-cloud-download',
    'copies-filed': 'mdi-file-multiple',
    'create-group': 'mdi-folder-plus',
    'crop-landscape': 'mdi-crop-landscape',
    'edit-columns': 'mdi-view-column',
    'exit-to-app': 'mdi-exit-to-app',
    'export-csv': 'mdi-view-module',
    'eye-off': 'mdi-eye-off',
    'failure-circle': 'mdi-close-circle',
    'file-tree': 'mdi-file-tree',
    'folder-plus': 'mdi-folder-plus',
    'fullscreen': 'mdi-arrow-expand',
    'lease-hierarchy': 'mdi-file-tree',
    'loading-layers': 'mdi-cached',
    'map-marker': 'mdi-map-marker',
    'map-marker-outline': 'mdi-map-marker-outline',
    'matter-settings': 'mdi-folder-cog',
    'matter-link-share': 'mdi-earth',
    'matter-shared': 'mdi-account-multiple',
    'matter-shared-nav': 'mdi-folder-account',
    'matter-sharing': 'mdi-account',
    'menu-down': 'mdi-menu-down',
    'menu-left': 'mdi-menu-left',
    'menu-more': 'mdi-dots-horizontal',
    'menu-right': 'mdi-menu-right',
    'menu-up': 'mdi-menu-up',
    'menu-more-vertical': 'mdi-dots-vertical',
    'menu-dots': 'mdi-dots-grid',
    'move-to-group': 'mdi-apps',
    'open-in-new': 'mdi-open-in-new',
    'phone-rotate-landscape': 'mdi-phone-rotate-landscape',
    'phone-rotate-portrait': 'mdi-phone-rotate-portrait',
    'remove-from-group': 'mdi-folder-remove',
    'restore': 'mdi-arrow-collapse',
    'success-circle': 'mdi-check-circle',
    'title-analysis-mode': 'mdi-poll',
    'uploaded-file': 'mdi-file-upload-outline',
    'uploaded-register': 'mdi-cloud-upload',
    'view-column': 'mdi-view-column',
    'view-list': 'mdi-view-list',
    'view-module': 'mdi-view-module',
    'visibility-off': 'mdi-eye-off',
    'warning-octagon': 'mdi-alert-octagon',
    'zoom-in': 'mdi-plus',
    'zoom-out': 'mdi-minus',
    'premium': 'mdi-star',
    'plus-box': 'mdi-plus-box-outline',
    'minus-box': 'mdi-minus-box-outline',
    'circle-outline': 'mdi-circle-outline',
    'circle': 'mdi-checkbox-blank-circle',
    'circle-arrow-right': 'mdi-arrow-right-drop-circle',
    'circle-arrow-down': 'mdi-arrow-down-drop-circle',
    'alert': 'mdi-alert-octagon',
    'code-tags': 'mdi-code-tags',
}

const customLightTheme: ThemeDefinition = {
    dark: false,
    colors: {
        primary: '#1D73AD',
        secondary: '#8A8A8A',
        accent: '#F0A549',
        error: '#F44336',
        info: '#333333',
        success: '#008456',
        warning: '#F0A549',
        'dark-grey': '#333333',
    },
}

export const options: VuetifyOptions = {
    theme: {
        defaultTheme: 'light',
        themes: {
            light: customLightTheme,
        },
    },
    icons: {
        defaultSet: 'mdi',
        aliases: {
            ...aliases,
            ...owIcons,
        },
        sets: {
            mdi,
        },
    },
    components,
    directives,
    defaults: {
        global: {
            density: 'comfortable',
        },
        VTextField: {
            variant: 'underlined',
            density: 'compact',
            'hide-details': true,
            color: 'primary',
        },
        VSelect: {
            variant: 'underlined',
            density: 'compact',
            color: 'primary',
        },
        VCombobox: {
            variant: 'underlined',
        },
        VSwitch: {
            density: 'compact',
        },
        VCheckboxBtn: {
            density: 'compact',
        },
        VBtn: {
            ripple: false,
        },
    },
}

const vuetify = createVuetify(options)
export default vuetify
